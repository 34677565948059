import React from 'react';
import { Divider, FormControl, InputLabel, Select, WithStyles, withStyles, useTheme, Grid, Typography, MenuItem, Avatar, Card, CardHeader, CardContent, Button, TextField, DialogContent, Dialog, DialogActions, Paper, CardMedia, CardActionArea } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { styles } from './ConfigurarAgendaStyles';
import Volver from '../../../../components/buttons/Volver/Volver';
import { AddBoxOutlined, CalendarTodayOutlined, MenuOutlined, SettingsOutlined } from '@material-ui/icons';
import InteractiveCalendar from '../../../../components/InteractiveCalendar/InteractiveCalendar';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CardHorarioEspecialidad from '../../../../components/CardHorarioEspecialidad/CardHorarioEspecialidad';
import { IHorarioInicioFin } from '../../../../Interfaces/IHorarioInicioFin';
import { ICargarSchedule } from '../../../../Interfaces/ICargarSchedule';
import { IDiaHoras } from '../../../../Interfaces/IDiaHoras';
import { cargarSchedule, getSchedule } from '../../../../apis/citaProgramadaAPI';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import useQuery from '../../../../hooks/useQuery';
import { RootState } from '../../../../store/rootReducer';
import { convertDatestringToDate, convertDateToDatestring, convertDiaHoraToDate } from '../../../../utils/dateTimeHelper';
import { useSelector } from 'react-redux';

const ConfigurarAgenda: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props

    const query = useQuery();
    const agendaFutura = query.get("agenda") === "futura";
    const email = query.get('medico')!;
    const history = useHistory()
    const theme = useTheme();

    const { especialidades } = useSelector(
        (state: RootState) => state.administrarConsultoriosMedicos
    )

    const [duracionTurno, setDuracionTurno] = React.useState("");

    const handleChangeDuracionTurno = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDuracionTurno(event.target.value as string);
    };

    // const [duracionConsulta, setDuracionConsulta] = React.useState("");

    // const handleChangeDuracionConsulta = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setDuracionConsulta(event.target.value as string);
    // };

    const [loading, setLoading] = React.useState(false);
    const [configuracionCompleta, setConfiguracionCompleta] = React.useState(false);

    const diasPosibles = [0, 1, 2, 3, 4, 5, 6]
    const [diasHabilitados, setDiasHabilitados] = React.useState(() => [1, 2, 3, 4, 5]);

    //la key del record es la fecha que se necesite buscar, en datestring
    const [excepciones, setExcepciones] = React.useState<Record<string, Array<IHorarioInicioFin>>>({});
    const [fechasExcepciones, setFechasExcepciones] = React.useState<Array<string>>([]);
    const mesTexts = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',];
    const mesTextsLC = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre',];

    const diaTexts = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
    const diaTextsLC = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];

    const [minFuturaDate, setMinFuturaDate] = React.useState<Date>(new Date());
    React.useEffect(
        () => {
            setLoading(true);
            console.log(`GETTING SCHEDULE: ${email}`);
            getSchedule(email)
                .then(
                    (arrayRecibido) => {
                        const dataRecibida = arrayRecibido.find(x => x.agenda === (agendaFutura ? "futura" : "actual"))!;
                        //setEspecialidadesPosibles(dataRecibida);
                        if (dataRecibida === undefined) {
                            setLoading(false);
                            return;
                        }
                        if (agendaFutura) {
                            const minFuturaDateDato = arrayRecibido.find(x => x.agenda === "actual")!.vigenciaAgenda.fin;
                            const minFuturaDate = convertDatestringToDate(minFuturaDateDato);
                            setMinFuturaDate(minFuturaDate);
                        }
                        const horasAntelacion = Number.parseInt(dataRecibida.antelacionParaObtenerTurno);

                        let minFechaRango = convertDatestringToDate(dataRecibida.vigenciaAgenda.inicio);
                        let maxFechaRango = convertDatestringToDate(dataRecibida.vigenciaAgenda.fin);

                        if (minFechaRango < minFuturaDate) {
                            minFechaRango = minFuturaDate;
                        }
                        setMinFechaRango(minFechaRango);
                        setMaxFechaRango(maxFechaRango);

                        setDuracionTurno(dataRecibida.duracionTurnoProgramado);
                        // setDuracionConsulta(dataRecibida.duracionConsultaPresencial);
                        setHorasAntelacion(horasAntelacion);
                        setDiasVisualizacion(dataRecibida.fechaMaximaDeVisualizacion);

                        let diasHabilitadosDataRecibida: Array<number> = [];
                        let horariosHabilitadosDataRecibida: Array<IDiaHoras> = [];

                        diaTextsLC.forEach(
                            (diaLC, diaEnNumero) => {
                                var coincidencia = false;
                                dataRecibida.disponibilidad.forEach(
                                    (elementoDisponibilidad) => {
                                        if (elementoDisponibilidad.dia === diaLC) {
                                            diasHabilitadosDataRecibida.push(diaEnNumero);
                                            horariosHabilitadosDataRecibida.push({ dia: elementoDisponibilidad.dia, horas: elementoDisponibilidad.horas });
                                            coincidencia = true;
                                        }
                                        //console.log(elementoDisponibilidad.dia.toLowerCase());
                                        //console.log(diaEnNumero);
                                    }
                                )

                                if (!coincidencia) {
                                    horariosHabilitadosDataRecibida.push({ dia: diaLC, horas: [] })
                                }
                            }
                        )
                        setDiasHabilitados(diasHabilitadosDataRecibida);
                        setHorariosHabilitados(horariosHabilitadosDataRecibida);

                        let fechasExcepcionesDataRecibida: Array<string> = [];
                        let excepcionesDataRecibida: Record<string, Array<IHorarioInicioFin>> = {};
                        dataRecibida.excepciones.forEach(
                            (elementoExcepcion) => {
                                fechasExcepcionesDataRecibida.push(elementoExcepcion.dia);
                                excepcionesDataRecibida[elementoExcepcion.dia] = elementoExcepcion.horas;
                            }
                        )
                        setFechasExcepciones(fechasExcepcionesDataRecibida);
                        setExcepciones(excepcionesDataRecibida);

                        setLoading(false);
                    }
                )
                .catch(
                    e => { console.log(e) }
                )
        },
        []
    )

    let especialidadesPosibles: Array<string> = [];
    especialidades?.forEach((especialidad) => {
        if (especialidad.consultaProgramada) {
            especialidadesPosibles.push(especialidad.descripcion);
        }
    });

    const [horariosHabilitados, setHorariosHabilitados] = React.useState<Array<IDiaHoras>>(
        [
            {
                dia: "domingo",
                horas: []
            },
            {
                dia: "lunes",
                horas: []
            },
            {
                dia: "martes",
                horas: []
            },
            {
                dia: "miercoles",
                horas: []
            },
            {
                dia: "jueves",
                horas: []
            },
            {
                dia: "viernes",
                horas: []
            },
            {
                dia: "sabado",
                horas: []
            }
        ]
    );

    const handleDiasHabilitados = (event: React.MouseEvent<HTMLElement>, newDiasHabilitados: number[]) => {
        newDiasHabilitados = newDiasHabilitados.sort((a, b) => { return (a - b) });
        setDiasHabilitados(newDiasHabilitados);
    };

    const [horasAntelacion, setHorasAntelacion] = React.useState(24);

    const handleChangeHorasAntelacion = (event: { target: { value: React.ReactText; }; }) => {
        let value = +event.target.value;
        value = Math.min(value, 72);
        value = Math.max(value, 0);
        setHorasAntelacion(Math.ceil(value));
    }

    const [diasVisualizacion, setDiasVisualizacion] = React.useState(60);

    const handleChangeDiasVisualizacion = (event: { target: { value: React.ReactText; }; }) => {
        let value = +event.target.value;
        // value = Math.min(value, 60);
        value = Math.max(value, horasAntelacion / 24);
        setDiasVisualizacion(Math.ceil(value));
    }



    const handleAceptar = () => {
        let disponibilidadFormateada: Array<IDiaHoras> = [];
        let excepcionesFormateadas: Array<IDiaHoras> = [];

        diasHabilitados.forEach((diaHabilitado) => {
            disponibilidadFormateada.push(
                {
                    dia: diaTexts[diaHabilitado].toLowerCase(),
                    horas: horariosHabilitados[diaHabilitado].horas
                }
            )
        })

        fechasExcepciones.forEach((fechaExcepcion) => {
            if (excepciones[fechaExcepcion].length > 0) {
                excepcionesFormateadas.push({
                    dia: fechaExcepcion,
                    horas: excepciones[fechaExcepcion]
                })
            }
        })

        // let objetoEnviar: ICargarSchedule = {
        //     disponibilidad: disponibilidadFormateada,
        //     excepciones: excepcionesFormateadas,
        //     id: email,
        //     antelacionParaObtenerTurno: `${horasAntelacion}h`,
        //     fechaMaximaDeVisualizacion: diasVisualizacion,
        //     duracionTurnoProgramado: duracionTurno,
        //     duracionConsultaPresencial: duracionConsulta,
        //     agenda: agendaFutura ? "futura" : "actual",
        //     vigenciaAgenda: {
        //         inicio: agendaFutura ? convertDateToDatestring(minFechaRango) : convertDateToDatestring(new Date()),
        //         fin: convertDateToDatestring(maxFechaRango)
        //     },

        // }
        let objetoEnviar: ICargarSchedule = {
            disponibilidad: disponibilidadFormateada,
            excepciones: excepcionesFormateadas,
            id: email,
            antelacionParaObtenerTurno: `${horasAntelacion}h`,
            fechaMaximaDeVisualizacion: diasVisualizacion,
            duracionTurnoProgramado: duracionTurno,
            agenda: agendaFutura ? "futura" : "actual",
            vigenciaAgenda: {
                inicio: agendaFutura ? convertDateToDatestring(minFechaRango) : convertDateToDatestring(new Date()),
                fin: convertDateToDatestring(maxFechaRango)
            },

        }
        setLoading(true);
        
        cargarSchedule(objetoEnviar)
            .then(
                ({ status, data }) => {
                    if (status === 200) {
                        setLoading(false);
                        setConfiguracionCompleta(true);
                        //mostrar dialogo cargado con exito
                    } else {
                        setLoading(false);
                        console.log(data);
                    }
                }
            )
            .catch((e) => {
                console.log(e);
            })
    }

    const [fechaEspecificaEditando, setFechaEspecificaEditando] = React.useState<Date | undefined>(undefined);

    const handleChangeFechaEspecificaEditando = (newValue: Date | Date[]) => {
        if (fechaEspecificaEditando && convertDateToDatestring(newValue as Date) === convertDateToDatestring(fechaEspecificaEditando!)) {
            return;
        }
        if (fechaEspecificaEditando !== undefined) {
            const oldFechaKey = convertDateToDatestring(fechaEspecificaEditando!);
            if (excepciones[oldFechaKey].length === 0) {
                let tempExcepciones = { ...excepciones };
                delete tempExcepciones[oldFechaKey];
                setExcepciones(tempExcepciones);

                let tempFechasExcepciones = [...fechasExcepciones];
                const index = tempFechasExcepciones.indexOf(oldFechaKey);
                tempFechasExcepciones.splice(index, 1);
                setFechasExcepciones(tempFechasExcepciones);
            }
        }

        const newFecha = (newValue as Date);
        const fechaKey = convertDateToDatestring(newFecha);
        if (excepciones[fechaKey] === undefined) {
            let tempExcepciones = { ...excepciones };
            tempExcepciones[fechaKey] = [];
            setExcepciones(tempExcepciones);

            let tempFechasExcepciones = [...fechasExcepciones];
            tempFechasExcepciones.push(fechaKey);
            setFechasExcepciones(tempFechasExcepciones);
        }
        setFechaEspecificaEditando(newFecha);
    }

    const [minFechaRango, setMinFechaRango] = React.useState<Date>(new Date());
    const [maxFechaRango, setMaxFechaRango] = React.useState<Date>(new Date());

    // let maxDateAbsoluto = new Date();
    // maxDateAbsoluto.setDate(maxDateAbsoluto.getDate() + 60);

    const parametrosBasicosContent = (
        <>
            <Grid container spacing={2} style={{ padding: 30 }}>
                <Grid item container xs={12} md={6}>

                    <FormControl style={{ minWidth: 320 }} >
                        <InputLabel >Duración del turno para citas programadas</InputLabel>
                        <Select
                            value={duracionTurno}
                            onChange={handleChangeDuracionTurno}
                        >
                            <MenuItem value={"10m"}>10 minutos</MenuItem>
                            <MenuItem value={"15m"}>15 minutos</MenuItem>
                            <MenuItem value={"20m"}>20 minutos</MenuItem>
                            <MenuItem value={"30m"}>30 minutos</MenuItem>
                            <MenuItem value={"40m"}>40 minutos</MenuItem>
                            <MenuItem value={"1h"}>1 hora</MenuItem>
                            <MenuItem value={"1h30m"}>1 hora 30 minutos</MenuItem>
                        </Select>
                    </FormControl>
                    {/*
                    <FormControl style={{ minWidth: 320 }} margin="normal" >
                        
                        <InputLabel >Duración de la consulta Presencial</InputLabel>
                        <Select
                            value={duracionConsulta}
                            onChange={handleChangeDuracionConsulta}
                        >
                            <MenuItem value={"10m"}>10 minutos</MenuItem>
                            <MenuItem value={"15m"}>15 minutos</MenuItem>
                            <MenuItem value={"20m"}>20 minutos</MenuItem>
                            <MenuItem value={"30m"}>30 minutos</MenuItem>
                            <MenuItem value={"40m"}>40 minutos</MenuItem>
                            <MenuItem value={"1h"}>1 hora</MenuItem>
                            <MenuItem value={"1h30m"}>1 hora 30 minutos</MenuItem>
                        </Select>
                        
                        
                    </FormControl>
                    */}
                    <TextField
                        style={{ minWidth: 320 }}
                        label="Antelación mínima para sacar turnos"
                        type="number"
                        value={horasAntelacion}
                        margin="normal"
                        onChange={handleChangeHorasAntelacion}
                        helperText={"(Horas)"}
                    />
                    <TextField
                        style={{ minWidth: 320 }}
                        label="Máxima visualización de agenda"
                        type="number"
                        value={diasVisualizacion}
                        margin="normal"
                        onChange={handleChangeDiasVisualizacion}
                        helperText={"(Dias)"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} >
                    <Typography variant="h6" >
                        Dias habilitados para consulta
                    </Typography>
                    <ToggleButtonGroup size="small" value={diasHabilitados} onChange={handleDiasHabilitados} >
                        {
                            diasPosibles.map(
                                (dia, indexDia) => {
                                    const diaText = diaTexts[dia];
                                    return (
                                        <ToggleButton key={indexDia} value={dia}>
                                            <Avatar style={{ backgroundColor: "white", color: theme.paletaColores.naranja.tercero, height: 25, width: 25 }} >
                                                {diaText[0]}
                                            </Avatar>
                                        </ToggleButton>
                                    )
                                }
                            )
                        }
                    </ToggleButtonGroup>

                    <Typography variant="h6" style={{ marginTop: 30 }}>
                        Período de validez de la agenda
                    </Typography>
                    <InteractiveCalendar
                        value={[agendaFutura ? minFechaRango : new Date(), maxFechaRango]}
                        onClickDay={agendaFutura ? () => null : (newDate) => { setMaxFechaRango(newDate) }}
                        onChange={agendaFutura ? (newDates) => {
                            const dates = newDates as Array<Date>;
                            setMinFechaRango(dates[0]);
                            setMaxFechaRango(dates[1]);
                        } : () => null}
                        minDate={agendaFutura ? minFuturaDate : new Date()}
                        // maxDate={maxDateAbsoluto}
                        selectRange={agendaFutura}
                        allowPartialRange={agendaFutura}
                    />
                </Grid>
            </Grid>
        </>
    )

    const configurarAgendaContent = (
        <>
            <Grid container>
                {
                    diasHabilitados.map(
                        (dia, indexDia) => {
                            var diaText = diaTexts[dia];
                            const handleAddHorario = () => {
                                let tempHorarios = [...horariosHabilitados];
                                tempHorarios[dia].horas.push({ inicio: "00:00", fin: "00:00", especialidades: [], tipo: "virtual" });
                                setHorariosHabilitados(tempHorarios);
                            }

                            return (
                                <Grid item xs={12} >
                                    <Card key={dia} style={{ margin: "5px 5px 20px 5px", backgroundColor: theme.paletaColores.fondo.segundo }}>
                                        <CardHeader
                                            title={diaText}
                                            style={{ color: theme.paletaColores.naranja.tercero }}
                                        />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={1}>
                                                {horariosHabilitados[dia].horas.map(
                                                    (horario, indexHorario) => {

                                                        const canDelete = horariosHabilitados[dia].horas.length > 1;
                                                        const handleDelete = () => {
                                                            let tempHorarios = [...horariosHabilitados];
                                                            tempHorarios[dia].horas.splice(indexHorario, 1);
                                                            setHorariosHabilitados(tempHorarios);
                                                        }

                                                        const handleChangeInicio = (date: MaterialUiPickersDate) => {
                                                            let tempHorarios = [...horariosHabilitados];
                                                            let hours: string = date!.getHours().toString();
                                                            let minutes: string = date!.getMinutes().toString();
                                                            if (hours.length === 1) {
                                                                hours = "0" + hours;
                                                            }
                                                            if (minutes.length === 1) {
                                                                minutes = "0" + minutes;
                                                            }
                                                            tempHorarios[dia].horas[indexHorario].inicio = `${hours}:${minutes}`;
                                                            setHorariosHabilitados(tempHorarios);
                                                        }
                                                        const handleChangeFin = (date: MaterialUiPickersDate) => {
                                                            let tempHorarios = [...horariosHabilitados];
                                                            let hours: string = date!.getHours().toString();
                                                            let minutes: string = date!.getMinutes().toString();
                                                            if (hours.length === 1) {
                                                                hours = "0" + hours;
                                                            }
                                                            if (minutes.length === 1) {
                                                                minutes = "0" + minutes;
                                                            }
                                                            tempHorarios[dia].horas[indexHorario].fin = `${hours}:${minutes}`;
                                                            setHorariosHabilitados(tempHorarios);
                                                        }

                                                        let today = new Date();

                                                        let horaInicio = Number.parseInt(horario.inicio!.split(":")[0]);
                                                        let minutoInicio = Number.parseInt(horario.inicio!.split(":")[1]);
                                                        const inicio = new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaInicio, minutoInicio);

                                                        let horaFin = Number.parseInt(horario.fin!.split(":")[0]);
                                                        let minutoFin = Number.parseInt(horario.fin!.split(":")[1]);
                                                        const fin = new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaFin, minutoFin);

                                                        const handleChangeEspecialidad = (event: any) => {
                                                            let tempHorarios = [...horariosHabilitados];
                                                            tempHorarios[dia].horas[indexHorario].especialidades = event.target.value as string[];
                                                            setHorariosHabilitados(tempHorarios);
                                                        }

                                                        const handleChangeTipo = (event: any) => {
                                                            let tempHorarios = [...horariosHabilitados];
                                                            tempHorarios[dia].horas[indexHorario].tipo = event.target.value as "virtual" | "presencial";
                                                            setHorariosHabilitados(tempHorarios);
                                                        }

                                                        return (
                                                            <Grid item xs={12} md={6} >
                                                                <CardHorarioEspecialidad
                                                                    key={indexHorario}
                                                                    inicio={inicio}
                                                                    fin={fin}
                                                                    handleChangeInicio={handleChangeInicio}
                                                                    handleChangeFin={handleChangeFin}
                                                                    especialidadesSeleccionadas={horario.especialidades}
                                                                    handleChangeEspecialidad={handleChangeEspecialidad}
                                                                    especialidadesPosibles={especialidadesPosibles}
                                                                    canDelete={canDelete}
                                                                    handleDelete={handleDelete}
                                                                    tipo={horario.tipo}
                                                                    handleChangeTipo={handleChangeTipo}
                                                                />
                                                            </Grid>
                                                        )
                                                    }
                                                )}
                                            </Grid>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                startIcon={<AddBoxOutlined />}
                                                onClick={handleAddHorario}
                                                style={{ backgroundColor: "white" }}
                                            >
                                                Agregar Horario
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }
                    )
                }
            </Grid>
        </>
    )

    const fechasEspecificasContent = (
        <>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                    <Paper variant="outlined">
                        <div style={{ margin: 10 }}>
                            <Typography variant="h6">
                                Próximas excepciones activas
                            </Typography>
                        </div>

                        <Grid container style={{ padding: 10 }} spacing={2}>
                            {fechasExcepciones.map((excep, iExcep) => {
                                if (iExcep > 3) return null;

                                const date = convertDatestringToDate(excep);
                                return (
                                    <Grid item xs={6} md={3}>
                                        <Card>
                                            <CardActionArea onClick={() => { handleChangeFechaEspecificaEditando(date) }}>
                                                <CardHeader avatar={<Avatar style={{ backgroundColor: theme.paletaColores.naranja.segundo }}>{date.getDate()}</Avatar>} title={mesTexts[date.getMonth()]} />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>

                                )
                            })}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <InteractiveCalendar
                        minDate={agendaFutura ? minFechaRango : new Date()}
                        maxDate={maxFechaRango}
                        value={fechaEspecificaEditando}
                        onChange={handleChangeFechaEspecificaEditando}
                        dateHighlighted={(fecha: Date) => {
                            const dateString = convertDateToDatestring(fecha);
                            if (fechasExcepciones.find(x => x === dateString)) {
                                if (excepciones[dateString].length > 0) {
                                    return true;
                                }
                            }
                            return false;
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={7} >
                    {
                        fechaEspecificaEditando ?
                            (
                                <Card style={{ margin: 5, backgroundColor: theme.paletaColores.fondo.segundo }}>

                                    <CardHeader
                                        title={`${diaTexts[fechaEspecificaEditando!.getDay()]}, ${fechaEspecificaEditando!.toLocaleDateString()}`}
                                        style={{ color: theme.paletaColores.naranja.tercero }}
                                    />
                                    <Divider />
                                    <CardContent>
                                        {excepciones[convertDateToDatestring(fechaEspecificaEditando)].map(
                                            (horario, indexHorario) => {
                                                const fecha = convertDateToDatestring(fechaEspecificaEditando);
                                                const canDelete = true;
                                                const handleDelete = () => {
                                                    let tempExcepciones = { ...excepciones };
                                                    tempExcepciones[fecha].splice(indexHorario, 1);
                                                    setExcepciones(tempExcepciones);
                                                }

                                                const handleChangeInicio = (date: MaterialUiPickersDate) => {
                                                    let tempExcepciones = { ...excepciones };
                                                    let hours: string = date!.getHours().toString();
                                                    let minutes: string = date!.getMinutes().toString();
                                                    if (hours.length === 1) {
                                                        hours = "0" + hours;
                                                    }
                                                    if (minutes.length === 1) {
                                                        minutes = "0" + minutes;
                                                    }
                                                    tempExcepciones[fecha][indexHorario].inicio = `${hours}:${minutes}`;
                                                    setExcepciones(tempExcepciones);
                                                }
                                                const handleChangeFin = (date: MaterialUiPickersDate) => {
                                                    let tempExcepciones = { ...excepciones };
                                                    let hours: string = date!.getHours().toString();
                                                    let minutes: string = date!.getMinutes().toString();
                                                    if (hours.length === 1) {
                                                        hours = "0" + hours;
                                                    }
                                                    if (minutes.length === 1) {
                                                        minutes = "0" + minutes;
                                                    }
                                                    tempExcepciones[fecha][indexHorario].fin = `${hours}:${minutes}`;
                                                    setExcepciones(tempExcepciones);
                                                }

                                                const handleToggleSinAtencion = () => {
                                                    let tempExcepciones = { ...excepciones };
                                                    const obj = tempExcepciones[fecha][indexHorario];
                                                    if (obj.inicio === undefined || obj.fin === undefined) {
                                                        obj.inicio = "09:00";
                                                        obj.fin = "13:00";
                                                    } else {
                                                        obj.inicio = undefined;
                                                        obj.fin = undefined;
                                                    }
                                                    setExcepciones(tempExcepciones);
                                                }

                                                let today = new Date();

                                                let inicio = new Date();
                                                let fin = new Date();

                                                let sinAtencion = false;

                                                if (horario.inicio !== undefined && horario.fin !== undefined) {
                                                    let horaInicio = Number.parseInt(horario.inicio!.split(":")[0]);
                                                    let minutoInicio = Number.parseInt(horario.inicio!.split(":")[1]);
                                                    inicio = new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaInicio, minutoInicio);

                                                    let horaFin = Number.parseInt(horario.fin!.split(":")[0]);
                                                    let minutoFin = Number.parseInt(horario.fin!.split(":")[1]);
                                                    fin = new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaFin, minutoFin);
                                                } else {
                                                    sinAtencion = true;
                                                }

                                                const handleChangeEspecialidad = (event: any) => {
                                                    let tempExcepciones = { ...excepciones };
                                                    tempExcepciones[fecha][indexHorario].especialidades = event.target.value as string[];
                                                    setExcepciones(tempExcepciones);
                                                }

                                                const handleChangeTipo = (event: any) => {
                                                    let tempExcepciones = { ...excepciones };
                                                    tempExcepciones[fecha][indexHorario].tipo = event.target.value as "virtual" | "presencial";
                                                    setExcepciones(tempExcepciones);
                                                }

                                                return (
                                                    <CardHorarioEspecialidad
                                                        key={indexHorario}
                                                        inicio={inicio}
                                                        fin={fin}
                                                        sinAtencion={sinAtencion}
                                                        posibleSinAtencion={true}
                                                        handleToggleSinAtencion={handleToggleSinAtencion}
                                                        handleChangeInicio={handleChangeInicio}
                                                        handleChangeFin={handleChangeFin}
                                                        especialidadesSeleccionadas={horario.especialidades}
                                                        handleChangeEspecialidad={handleChangeEspecialidad}
                                                        especialidadesPosibles={especialidadesPosibles}
                                                        canDelete={canDelete}
                                                        handleDelete={handleDelete}
                                                        tipo={horario.tipo}
                                                        handleChangeTipo={handleChangeTipo}
                                                    />
                                                )
                                            }
                                        )}
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            startIcon={<AddBoxOutlined />}
                                            onClick={() => {
                                                let tempExcepciones = { ...excepciones };
                                                tempExcepciones[convertDateToDatestring(fechaEspecificaEditando)].push({
                                                    inicio: "9:00",
                                                    fin: "12:00",
                                                    especialidades: [],
                                                    tipo: "virtual"
                                                });
                                                setExcepciones(tempExcepciones);
                                            }}
                                            style={{ backgroundColor: "white" }}
                                        >
                                            Agregar Horario
                                        </Button>
                                    </CardContent>
                                </Card>
                            ) :
                            (
                                null
                            )
                    }
                </Grid>
            </Grid>
        </>
    )


    const secciones = [
        {
            ref: React.useRef<null | HTMLElement>(null),
            tag: "Parámetros Básicos",
            icon: <SettingsOutlined />,
            content: parametrosBasicosContent
        },
        {
            ref: React.useRef<null | HTMLElement>(null),
            tag: "Configurar Agenda",
            icon: <MenuOutlined />,
            content: configurarAgendaContent
        },
        {
            ref: React.useRef<null | HTMLElement>(null),
            tag: "Agregar/Quitar turnos en fechas específicas",
            icon: <CalendarTodayOutlined />,
            content: fechasEspecificasContent
        },
    ]

    if (loading) {
        return (
            <div style={{ marginTop: "40vh" }}>
                <LoadingBubbles />
            </div>
        )
    }

    if (configuracionCompleta) {
        return (
            <Dialog open={true}>
                <DialogContent>
                    <Typography>
                        Configuración modificada con éxito.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { history.push("/dashboard") }}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    let puedeAceptar = () => {
        let ret = true;
        diasHabilitados.forEach(
            (dia) => {
                if (horariosHabilitados[dia].horas.length === 0) {
                    ret = false;
                }
                horariosHabilitados[dia].horas.forEach(
                    (horario) => {
                        if (horario.especialidades.length === 0) {
                            ret = false;
                        }
                        if (convertDiaHoraToDate({ día: "2020/1/1", horas: horario.inicio! }).getTime() >= convertDiaHoraToDate({ día: "2020/1/1", horas: horario.fin! }).getTime()) {
                            ret = false;
                        }
                    }
                )
            }
        )
        fechasExcepciones.forEach(
            (fecha) => {
                const diaExcepciones = excepciones[fecha];
                diaExcepciones.forEach(
                    (horario) => {
                        if (horario.especialidades.length === 0) {
                            ret = false;
                        }
                        if (horario.inicio && horario.fin) {
                            if (convertDiaHoraToDate({ día: "2020/1/1", horas: horario.inicio! }).getTime() >= convertDiaHoraToDate({ día: "2020/1/1", horas: horario.fin! }).getTime()) {
                                ret = false;
                            }
                        }
                    }
                )
            }
        )
        if(duracionTurno === "" ){
            // || duracionConsulta === ""
            ret = false;
        }
        //agregar otras verificaciones
        return ret;
    }

    return (
        <>
            {/* <Hidden mdDown>
                <AppBar elevation={0} style={{ marginTop: 50, backgroundColor: theme.paletaColores.fondo.primero }}>
                    <Toolbar className={classes.toolbar} >
                        {
                            secciones.map(


                                (seccion, index) => {
                                    function scrollToTargetAdjusted() {
                                        var element = seccion.ref!.current!;
                                        var headerOffset = 125;
                                        var elementPosition = element.getBoundingClientRect().top;
                                        var offsetPosition = elementPosition - headerOffset;

                                        window.scrollTo({
                                            top: offsetPosition,
                                            behavior: "smooth"
                                        });
                                    }

                                    return (
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            key={index}
                                            startIcon={seccion.icon}
                                            onClick={scrollToTargetAdjusted}
                                        >
                                            {seccion.tag}
                                        </Button>
                                    )
                                }
                            )
                        }
                        <span style={{ flexGrow: 1 }} />
                    </Toolbar>
                </AppBar>
            </Hidden> */}
            <div style={{ height: 20 }} />
            {secciones.map(
                (seccion, index) => {
                    return (
                        <Card ref={seccion.ref} key={index} style={{ flexGrow: 1, marginBottom: 20 }}>
                            <CardHeader
                                avatar={<Avatar>{seccion.icon}</Avatar>}
                                title={<Typography variant="h6">{seccion.tag}</Typography>}
                            />
                            <Divider />
                            <CardContent>
                                {seccion.content}
                            </CardContent>
                        </Card>
                    )
                }
            )}
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={() => { history.goBack() }} />
                <Button
                    disabled={!puedeAceptar()}
                    style={{ backgroundColor: puedeAceptar() ? theme.paletaColores.naranja.tercero : theme.paletaColores.naranja.cuarto }}
                    onClick={handleAceptar}
                >
                    {puedeAceptar() ?
                        (
                            <>
                                Aceptar
                            </>
                        )
                        :
                        (
                            <span style={{ color: theme.paletaColores.negro.primero }}>
                                Verifique la configuracion ingresada
                            </span>
                        )
                    }
                </Button>
            </div>
        </>
    )
}

export default withStyles(styles)(ConfigurarAgenda)
