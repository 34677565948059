import React, { useEffect, useState } from 'react';
import {
    Box, Divider, WithStyles, withStyles, Grid, Typography, Button,
    Dialog, DialogActions, DialogContent, DialogContentText, useTheme, DialogTitle, Toolbar
} from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { styles } from './SalaEsperaFinochiettoStyles';
import { setPaso } from '../../../../../store/paciente/ver-medico-online/verMedicoOnlineSlice';
import { RootState } from '../../../../../store/rootReducer';
import { cancelarCita } from '../../../../../apis/citaInstantaneaAPI';
import { CancelOutlined, MicOutlined, RecordVoiceOverOutlined, VideocamOutlined } from '@material-ui/icons';
import { getFirestore } from '../../../../../db';
import LoadingBubbles from '../../../../../components/LoadingBubbles/LoadingBubbles';
import Lottie from 'react-lottie';
import animationData from '../../../../../assets/animations/clockAnim.json';

import Webcam from 'react-webcam';
import { ReactMic } from 'react-mic';
import { getCurrentTime, useTime } from '../../../../../hooks/useTime';
import { convertSecondsToLocaleHMS } from '../../../../../utils/dateTimeHelper';

const SalaEsperaFinochietto: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const now = useTime(500);
    const [horaInicioEspera, setHoraInicioEspera] = useState(new Date());

    const [loading, setLoading] = useState(false);

    const handleCancelarSi = async () => {
        // setLoading(true);
        // await cancelarCita({ usuario: cuilPacienteSeleccionado.toString(), sesion: idSalaVideollamada });
        // setLoading(false);
        // history.push(`/dashboard`);
    }

    const [citaCancelada, setCitaCancelada] = useState(false);

    const [dialogCancelarOpen, setDialogCancelarOpen] = useState(false);

    // let minutesEstimado = Math.ceil(tiempoEsperaEstimado / 60);
    // minutesEstimado += minutesEstimado > 10 ? 10 : minutesEstimado;
    const onLlamadoConsultorio = () => {
        dispatch(setPaso(6))
        history.push(`/dashboard/ver-medico-online/llamado`)
    }
    const onCancelada = () => {
        setCitaCancelada(true);
    }

    const db = getFirestore();
    // useEffect(() => {
    //     //CitasInstantaneas/Clínica Médica Adultos/sesiones/f9a9e367-e3a0-4a13-82f9-fcc85bbffafc
    //     const unsubscribe = db.collection("CitasInstantaneas").doc(especialidad).collection("sesiones").doc(idSalaVideollamada).onSnapshot(
    //         (datosSesion) => {
    //             if (!datosSesion.exists) {
    //                 console.log("La sesion no existe");
    //                 return;
    //             }
    //             if (datosSesion!.data()!.estado === "R") {
    //                 onLlamadoConsultorio();
    //             } else if (datosSesion!.data()!.estado === "C") {
    //                 onCancelada();
    //             } else {
    //                 console.log(`El estado es ${datosSesion!.data()!.estado}`);
    //             }
    //         }
    //     );

    //     const socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
    //         reconnectionDelayMax: 10000,
    //         secure: true,
    //         query: {
    //             "tipo": "paciente",
    //             "usuario": cuilPacienteSeleccionado.toString(),
    //             "sesion": idSalaVideollamada,
    //             "nombre": pacientesByCuil[cuilPacienteSeleccionado].NOMBRE,
    //             "apellido": pacientesByCuil[cuilPacienteSeleccionado].APELLIDO,
    //             "especialidad": especialidad,
    //             "credencial": pacientesByCuil[cuilPacienteSeleccionado].NRO_CREDENCIAL,
    //             "estado": `Sala de Espera`,
    //             "ambiente": `${process.env.REACT_APP_URL_PACIENTES}`,
    //             // "horaConexion": getCurrentTime().getTime().toString()
    //         }
    //     });

    //     return () => {
    //         unsubscribe();
    //         socket.disconnect();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const theme = useTheme();

    const [troubleshootOpen, setTroubleshootOpen] = React.useState(false);

    const troubleshootDialog = (open: boolean) => (
        <Dialog
            open={open}
            onClose={() => { setTroubleshootOpen(false) }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Posibles causas de mal funcionamiento
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ol>
                        <li>
                            La cámara web no está conectada. Verifique el cable de conexión de la misma.
                        </li>
                        <li>
                            El cable de conexión de la cámara web está en mal estado. Verifique que el mismo no esté doblado ni dañado.
                        </li>
                        <li>
                            La cámara web está deshabilitada en el Administrador de Dispositivos del sistema operativo. Por favor habilite la misma.
                        </li>
                        <li>
                            La cámara web está siendo utilizada por otra aplicación. Por favor cierre todas las otras aplicaciones que puedan hacer uso de la misma.
                        </li>
                        <li>
                            El navegador está prohibiendo el uso de la cámara web para este sitio. Otorgue los permisos desde la configuración del navegador.
                        </li>
                        <li>
                            La cámara web está rota.
                        </li>
                    </ol>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" disableElevation onClick={() => { setTroubleshootOpen(false) }}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )

    const [testOpen, setTestOpen] = React.useState(false);
    const [recordingAudio, setRecordingAudio] = useState(false);
    const [listeningAudio, setListeningAudio] = useState(false);
    const audioRecordBtnDisabled = recordingAudio || listeningAudio;

    const testAudioVideoDialog = (open: boolean) => (
        <Dialog
            open={open}
            onClose={() => { setTestOpen(false) }}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent>
                <Webcam style={{ flexGrow: 1, width: "100%" }} />
                <Toolbar>
                    <ReactMic
                        className={classes.mic}
                        record={recordingAudio}
                        onStop={(recordedBlob) => {
                            console.log(recordedBlob);
                            const url = URL.createObjectURL(recordedBlob.blob);
                            const audio = new Audio(url);
                            audio.play();
                        }}
                        strokeColor={theme.paletaColores.negro.finochietto}
                    />
                    <Button
                        onClick={() => {
                            setRecordingAudio(true);
                            setTimeout(() => {
                                setRecordingAudio(false);
                                setListeningAudio(true);
                                setTimeout(() => {
                                    setListeningAudio(false);
                                }, 3000);
                            }, 3000);
                        }}
                        disabled={audioRecordBtnDisabled}
                        variant="contained"
                        disableElevation
                        startIcon={audioRecordBtnDisabled ? (recordingAudio ? (<RecordVoiceOverOutlined />) : (<LoadingBubbles size={"25px"} />)) : (<MicOutlined />)}
                        color="primary"
                        fullWidth
                    >
                        {
                            recordingAudio ?
                                ("Hablá")
                                :
                                (
                                    <>
                                        {
                                            listeningAudio ?
                                                ("Escuchá")
                                                :
                                                ("Grabar")
                                        }
                                    </>
                                )
                        }
                    </Button>
                </Toolbar>
                <DialogContentText>
                    Si podés ver tu imagen de la cámara, podés grabar tu voz y después podés escuchar la grabación, todo está funcionando bien.
                </DialogContentText>
                <DialogContentText>
                    <NavLink
                        onClick={() => {
                            setTestOpen(false);
                            setTroubleshootOpen(true);
                        }}
                        to="#"
                    >
                        Clic aca
                    </NavLink>
                    {" si algo no esta funcionando correctamente."}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation color="primary" onClick={() => { setTestOpen(false) }}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
    if (citaCancelada) {
        return (
            <Dialog open={citaCancelada}>
                <DialogContent>
                    <DialogContentText>
                        Su cita ha sido cancelada.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        history.push("/dashboard");
                    }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    if (loading) {
        return (
            <LoadingBubbles />
        )
    }

    return (
        <>
            <Divider />
            <Box my={3}>
                <Grid justify="center" container spacing={3}>
                    <Lottie style={{ marginBottom: 60 }} options={{ loop: true, autoplay: true, animationData: animationData, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }} isStopped={false} isPaused={false} width={200} height={200} />
                    {/* <img src={imgClock} alt="imagen reloj" width="10%" /> */}
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <h3>Bienvenido a la sala de espera</h3>
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <Typography paragraph>Aguardá por favor</Typography>
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <Typography paragraph>Llevas en espera: {convertSecondsToLocaleHMS((now.getTime() - horaInicioEspera.getTime()) / 1000)}</Typography>
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <Typography paragraph>Tiempo de espera estimado: {convertSecondsToLocaleHMS(300)}</Typography>
                </Grid>
            </Box>
            <Divider />
            <div className={classes.buttonsRoot}>
                {/* <Button
                    variant="contained"
                    disableElevation
                    endIcon={<CancelOutlined />}
                    onClick={() => { setDialogCancelarOpen(true) }}
                >
                    CANCELAR CITA
                </Button> */}
                <Button
                    variant="contained"
                    disableElevation
                    endIcon={<VideocamOutlined />}
                    onClick={() => { setTestOpen(true) }}
                    color="primary"
                >
                    PROBAR CAMARA Y SONIDO
                </Button>
            </div>
            <Dialog open={dialogCancelarOpen} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText>
                        <Typography paragraph>¿Está seguro que desea cancelar la consulta?</Typography>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disableElevation onClick={handleCancelarSi} style={theme.buttons.cancelButton}>
                        Si
                    </Button>
                    <Button variant="contained" disableElevation onClick={() => { setDialogCancelarOpen(false) }} style={theme.buttons.okButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {testAudioVideoDialog(testOpen)}
            {troubleshootDialog(troubleshootOpen)}
        </>
    )
}

export default withStyles(styles)(SalaEsperaFinochietto)
