import React, { useEffect, useState } from 'react';
import { Divider, WithStyles, withStyles, useTheme, Typography, Avatar, Card, CardHeader, CardContent, Button, TextField, FormControl, Select, MenuItem, CardActions, InputLabel, Grid, CardActionArea, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, DialogContentText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { styles } from './ConfiguracionHorariosStyles';
import Volver from '../../../../components/buttons/Volver/Volver';
import { ScheduleOutlined } from '@material-ui/icons';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { getHorarioAtencion, setHorarioAtencion } from '../../../../apis/citaInstantaneaAPI';
import { convertTimestringToDate } from '../../../../utils/dateTimeHelper';

const ConfiguracionHorarios: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const theme = useTheme();

    const [loading, setLoading] = useState(false);

    const [inicio, setInicio] = useState<Date>(new Date());
    const [fin, setFin] = useState<Date>(new Date());

    const [dialogAlertaOpen, setDialogAlertaOpen] = useState(false);
    const [alertaText, setAlertaText] = useState("");


    useEffect(() => {
        setLoading(true);
        getHorarioAtencion()
            .then(
                (datos) => {
                    const inicioRecibido = convertTimestringToDate(datos.horaInicio);
                    const finRecibido = convertTimestringToDate(datos.horaFin);

                    setInicio(inicioRecibido);
                    setFin(finRecibido);
                    setLoading(false);
                }
            )
            .catch(e => { console.log(e) })
    }, [])

    const onConfirmarMod = () => {
        setLoading(true);
        setHorarioAtencion(inicio, fin)
            .then(
                (datos) => {
                    setLoading(false);
                    setDialogAlertaOpen(true);
                    setAlertaText(datos);
                }
            )
            .catch(e => { console.log(e) })

    }


    let puedeConfirmar = true;
    if (inicio.getTime() >= fin.getTime()) {
        puedeConfirmar = false;
    }


    if (loading) {
        return (
            <div style={{ marginTop: '40vh' }}>
                <LoadingBubbles />
            </div>
        )
    }
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                    <CardHeader
                        avatar={<Avatar><ScheduleOutlined /></Avatar>}
                        title={<Typography variant="h6">Administración de horarios de atención de la guardia virtual</Typography>}
                    />
                    <Divider />
                    <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                        <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                HORARIOS GUARDIA MÉDICA
                                            </Typography>
                                        </ListItemText>
                                        <ListItemText>

                                            <TimePicker
                                                orientation="landscape"
                                                variant="inline"
                                                label="Desde"
                                                value={inicio}
                                                onChange={(newDate) => {
                                                    setInicio(newDate as Date);
                                                }}
                                                style={{ margin: "0px 5px 0px 5px", maxWidth: "100px" }}
                                            />

                                            <TimePicker
                                                orientation="landscape"
                                                variant="inline"
                                                label="Hasta"
                                                value={fin}
                                                onChange={(newDate) => {
                                                    setFin(newDate as Date);
                                                }}
                                                style={{ margin: "0px 5px 0px 5px", maxWidth: "100px" }}
                                            />
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                color="primary"
                                                disabled={!puedeConfirmar}
                                                onClick={onConfirmarMod}
                                            >
                                                CONFIRMAR
                                        </Button>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
                <div className={classes.buttonsRoot}>
                    <Volver onClickHandler={() => { history.goBack() }} />
                </div>
            </MuiPickersUtilsProvider>
            <Dialog open={dialogAlertaOpen}>
                <DialogContent>
                    <DialogContentText>
                        {alertaText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => { history.push("/dashboard") }}
                        variant="contained"
                        disableElevation
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withStyles(styles)(ConfiguracionHorarios)

