import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { IPaciente } from '../../../Interfaces/IPaciente';
import { IEspecialidad } from '../../../Interfaces/IEspecialidad';

interface IgestionarTurnosState {
    // especialidades: Array<IEspecialidad>;
    // paso: number;
    error: string | null;
}

let initialState: IgestionarTurnosState = {
    // especialidades: [],
    // paso: 0,
    error: null,
}

const gestionarTurnosSlice = createSlice({
    name: "gestionarTurnos",
    initialState,
    reducers: {
        // setPaso(state, action: PayloadAction<number>) {
        //     state.paso = action.payload
        // },
        // setEspecialidades(state, action: PayloadAction<Array<IEspecialidad> | undefined>) {
        //     if (action.payload) {
        //         state.especialidades = action.payload;
        //     } else {
        //         state.especialidades = [];
        //     }
        // }
    }
});


export const {
    // setPaso,
    // setEspecialidades
} = gestionarTurnosSlice.actions;

export default gestionarTurnosSlice.reducer;