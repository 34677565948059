import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { IPaciente } from '../../../Interfaces/IPaciente';
import { IEspecialidad } from '../../../Interfaces/IEspecialidad';

interface IadministrarHistorialOfflineState {
    paso: number;
    error: string | null;
}

let initialState: IadministrarHistorialOfflineState = {
    paso: 0,
    error: null,
}

const administrarHistorialOfflineSlice = createSlice({
    name: "administrarHistorialOffline",
    initialState,
    reducers: {
        setPaso(state, action: PayloadAction<number>) {
            state.paso = action.payload
        },
    }
});


export const {
    setPaso,
} = administrarHistorialOfflineSlice.actions;

export default administrarHistorialOfflineSlice.reducer;