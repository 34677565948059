import { Card, CardHeader, Typography, Tabs, Tab, Tooltip, Chip, Avatar, useTheme, WithStyles, withStyles, Box, IconButton, Dialog, DialogContent, Button, DialogActions, Divider, DialogContentText } from '@material-ui/core';
import { Done, Star, StarBorder, StarOutlined, Favorite, FavoriteBorder } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import React from 'react'
import { styles } from './MedicoTurnosDisponiblesStyles';
import { IProfesionalTurnos } from '../../Interfaces/IProfesionalHorario';
import usuario from '../../assets/images/usuario.png'
import StarIcon from '@material-ui/icons/Star';
import LoadingBubbles from '../LoadingBubbles/LoadingBubbles';
import { agregarMedicoAFavoritos, esMedicoFavorito, quitarMedicoDeFavoritos } from '../../apis/medicosFavoritosAPI';
import { RootState } from '../../store/rootReducer';
import { useSelector } from 'react-redux';
import { Rating } from '@material-ui/lab';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{ width: "100%", flex: 1 }}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

interface OwnProps {
    profesionalTurno: IProfesionalTurnos;
    handleChangeTab: (newTab: number) => void;
    tabSeleccionada: number;
    turnosHorarios: {
        nombre: string;
        desde: number;
        hasta: number;
    }[];
    selectedTurno: string;
    isProfesionalTurnosSelected: boolean;
    selectedSobreturno: boolean;
    setSelectedProfesionalTurno: (newSelected: IProfesionalTurnos) => void;
    setSelectedTurno: (newSelected: string) => void;
    setSelectedSobreturno: (newSelected: boolean) => void;
    mostrar: "favoritos" | "no-favoritos" | "todos" | "ninguno";
}

type PublicProps = OwnProps
type Props = PublicProps & WithStyles<typeof styles>

const MedicoTurnosDisponibles: React.FC<OwnProps> = (props) => {
    const theme = useTheme();
    const {
        profesionalTurno,
        handleChangeTab,
        tabSeleccionada,
        turnosHorarios,
        selectedTurno,
        isProfesionalTurnosSelected,
        selectedSobreturno,
        setSelectedProfesionalTurno,
        setSelectedTurno,
        setSelectedSobreturno,
        mostrar,
    } = props;

    function a11yProps(index: any) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const { cuilPacienteSeleccionado } = useSelector(
        (state: RootState) => state.user
    )

    const [favorito, setFavorito] = React.useState(false);
    const [loadingFav, setLoadingFav] = React.useState(false);
    const [favConfirmOpen, setFavConfirmOpen] = React.useState(false);

    const openFavConfirmation = () => {
        setFavConfirmOpen(true);
    }
    const closeFavConfirmation = () => {
        setFavConfirmOpen(false);
    }
    const handleToggleFav = () => {
        closeFavConfirmation();
        setLoadingFav(true);
        const toggle = favorito ? quitarMedicoDeFavoritos : agregarMedicoAFavoritos;
        toggle(cuilPacienteSeleccionado.toString(), profesionalTurno.profesional)
            .then((res) => {
                esMedicoFavorito(cuilPacienteSeleccionado.toString(), profesionalTurno.profesional)
                    .then((res) => {
                        setFavorito(res);
                        setLoadingFav(false);
                    })
                    .catch(e => { console.log(e); })
            })
            .catch(e => { console.log(e); })
    }

    React.useEffect(() => {
        setLoadingFav(true);
        esMedicoFavorito(cuilPacienteSeleccionado.toString(), profesionalTurno.profesional)
            .then((res) => {
                setFavorito(res);
                setLoadingFav(false);
            })
            .catch(e => { console.log(e); })
    }, [])

    if (mostrar === "ninguno" || (mostrar === "favoritos" && !favorito) || (mostrar === "no-favoritos" && favorito)) {
        return null;
    }

    return (
        <div>
            <Dialog open={favConfirmOpen}>
                <DialogContent>
                    <DialogContentText>
                        {
                            favorito ?
                                `Desea quitar al medico ${profesionalTurno.nombre} de favoritos?`
                                :
                                `Desea agregar al medico ${profesionalTurno.nombre} a favoritos?`
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disableElevation size="small" onClick={closeFavConfirmation}>
                        No
                    </Button>
                    <Button variant="contained" disableElevation size="small" onClick={handleToggleFav} color="primary">
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flexGrow: 1 }}>
                    <Card elevation={0} style={{ backgroundColor: "transparent" }}>
                        <CardHeader
                            title={
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography variant="h6" style={{ color: theme.paletaColores.naranja.tercero }}>
                                        {profesionalTurno.nombre}
                                    </Typography>
                                    <IconButton onClick={openFavConfirmation} disabled={loadingFav} >
                                        {
                                            loadingFav ?
                                                <LoadingBubbles useCircle size={24} />
                                                :
                                                <>
                                                    {
                                                        favorito ?
                                                            <Favorite style={{ color: theme.paletaColores.naranja.tercero }} />
                                                            :
                                                            <FavoriteBorder style={{ color: theme.paletaColores.naranja.tercero }} />
                                                    }
                                                </>
                                        }
                                    </IconButton>
                                </div>
                            }
                            subheader={
                                <Typography paragraph >Matricula: {profesionalTurno.matricula}</Typography>
                            }
                            avatar={
                                <div style={{ width: 70, height: 70, overflow: "clip", borderRadius: 10 }}><img src={profesionalTurno.photoURL ? profesionalTurno.photoURL : usuario} alt="imagen medico" height="100%" /></div>
                            }
                        />
                    </Card>
                </div>
                <div>
                    {
                        profesionalTurno.promedio ?
                            <div style={{ marginTop: 25 }}>
                                <Typography variant="body2">Calificación:</Typography>
                                <Rating value={profesionalTurno.promedio} readOnly size="small" precision={0.01} color="primary" />
                            </div>
                            :
                            null
                    }
                </div>
            </div>

            <Tabs

                style={{ flexGrow: 1, width: "100%" }}
                value={tabSeleccionada}
                onChange={(event, tab) => {
                    handleChangeTab(tab as number);
                }}
            >
                {
                    turnosHorarios.map((turnoHorario, iTurnoHorario) => {
                        let disabled = false;
                        if (profesionalTurno.turnos.filter(x => {
                            const horaDelTurno = Number.parseInt(x.split(":")[0]);
                            return horaDelTurno >= turnoHorario.desde && horaDelTurno < turnoHorario.hasta
                        }).length === 0) {
                            disabled = true;
                            return null;
                        }
                        return (
                            <Tab disabled={disabled} key={iTurnoHorario} value={iTurnoHorario} style={{ fontSize: 11, minWidth: "30px" }} {...a11yProps(0)} label={turnoHorario.nombre} />
                        )
                    })
                }
            </Tabs>
            <SwipeableViews
                index={tabSeleccionada}
                onChangeIndex={handleChangeTab}
                fullWidth
                style={{ width: "100%" }}
            >
                {
                    turnosHorarios.map((turnoHorario, iTurnoHorario) => {
                        return (
                            <TabPanel key={iTurnoHorario} value={tabSeleccionada} index={iTurnoHorario} dir={theme.direction}>
                                {
                                    profesionalTurno.turnos.filter(x => {
                                        const horaDelTurno = Number.parseInt(x.split(":")[0]);
                                        return horaDelTurno >= turnoHorario.desde && horaDelTurno < turnoHorario.hasta
                                    }).map(
                                        (turno, indexTurno) => {
                                            const isSobreturno = turno.endsWith("s");
                                            const horaDelTurno = turno.replace("s", "");
                                            const isTurnoSelected = (selectedTurno === horaDelTurno && isProfesionalTurnosSelected && isSobreturno === selectedSobreturno);
                                            let texto = "";
                                            if (isSobreturno) {
                                                texto += "SOBRETURNO ";
                                            }
                                            if (horaDelTurno.length < 5) {
                                                texto += "0";
                                            }
                                            texto += horaDelTurno;
                                            return (
                                                <>
                                                    <Tooltip arrow title={<Typography>{`Profesional: ${profesionalTurno.nombre}`}</Typography>} key={indexTurno}>
                                                        <Chip
                                                            onClick={() => {
                                                                setSelectedProfesionalTurno(profesionalTurno);
                                                                setSelectedTurno(horaDelTurno);
                                                                setSelectedSobreturno(isSobreturno);
                                                            }}
                                                            style={{ margin: 3, backgroundColor: isTurnoSelected ? theme.paletaColores.naranja.tercero : "white", color: isTurnoSelected ? "white" : "black" }}
                                                            label={texto}
                                                            variant={isTurnoSelected ? "default" : "outlined"}
                                                            avatar={isTurnoSelected ? (<Avatar style={{ backgroundColor: "white" }}><Done fontSize="small" /></Avatar>) : <Avatar > </Avatar>}
                                                        />
                                                    </Tooltip>
                                                </>
                                            )
                                        }
                                    )
                                }
                            </TabPanel>
                        )
                    })
                }
            </SwipeableViews>
            <Divider />
        </div >
    )
}

export default MedicoTurnosDisponibles
