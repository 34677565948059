import { createStyles } from "@material-ui/core";

export const styles = createStyles({
    paper: {
        padding: 16,
        maxWidth: 500
    },
    buttonsRoot: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            textTransform: 'none',
            width: 250,
            margin: 10
        },
        '& button:nth-child(2)': {
            color: 'white',
            backgroundColor: 'grey'
        }
    },
    // card:{
    //     padding:15
    // },
    // estrella:{
    //     padding:0
    // },
    // icono:{
    //     width:70,
    //     height:70,
    // },
    // gridIcon:{
    //     display:'flex',
    //     justifyContent:'center',
    //     alignContent:'center',
    //     flexDirection:'column'
    // }
})
