import React, { useState, useEffect } from 'react'
import { List, ListItem, Divider, WithStyles, withStyles, useTheme, Typography, Avatar, Card, CardHeader, CardContent, ListItemText, Button, TextField, InputAdornment, Grid, Chip, ListItemSecondaryAction, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Select, MenuItem, FormControl, InputLabel, FormLabel } from '@material-ui/core';
import { styles } from './EstadisticasGuardiaStyles';
import { ArrowDownwardOutlined, SearchOutlined, SettingsOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router';
import Volver from '../../../../components/buttons/Volver/Volver';
import { obtenerResumenCitasAtendidas } from '../../../../apis/citaInstantaneaAPI';
import { IEstadisticasMedicoGuardia } from '../../../../Interfaces/IEstadisticasMedicoGuardia';
import { convertSecondsToLocaleHMS } from '../../../../utils/dateTimeHelper';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';

const EstadisticasGuardia: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const [loading, setLoading] = useState(false);
    const [updateState, setUpdateState] = useState(false);
    const history = useHistory();
    const theme = useTheme();
    const [profesionales, setProfesionales] = useState<Array<IEstadisticasMedicoGuardia>>([]);
    const [profesionalesOrdenados, setProfesionalesOrdenados] = useState<Array<IEstadisticasMedicoGuardia>>([]);
    const [ordenMedicos, setOrdenMedicos] = useState<"alfabetico" | "pacientes" | "tiempo">("alfabetico");
    const [profesionalesFiltrados, setProfesionalesFiltrados] = useState<Array<IEstadisticasMedicoGuardia>>([]);
    const [filtroMedicos, setFiltroMedicos] = useState("");

    useEffect(() => {
        setLoading(true);
        setFiltroMedicos("");
        setOrdenMedicos("alfabetico");
        obtenerResumenCitasAtendidas(new Date)
            .then(datos => {
                datos = datos.sort((a, b) => (a.nombre.localeCompare(b.nombre)));
                setProfesionales(datos);
                setProfesionalesOrdenados(datos);
                setProfesionalesFiltrados(datos);
                setLoading(false);
            })
            .catch(e => { console.log(e) })
    }, [updateState])

    return (
        <>
            <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                <CardHeader
                    avatar={<Avatar><SettingsOutlined /></Avatar>}
                    title={<Typography variant="h6">Estadísticas de la Guardia</Typography>}
                />
                <Divider />
                <CardContent>
                    <Toolbar variant="dense" style={{ backgroundColor: "white" }}>
                        <FormControl
                            size="small"
                            style={{ margin: 5, minWidth: 250 }}
                            variant="outlined"
                        >
                            <InputLabel>
                                Ordenar Profesionales
                            </InputLabel>
                            <Select
                                name="ordenar-medicos"
                                label="Ordenar Profesionales"
                                disabled={loading}
                                value={ordenMedicos}
                                onChange={(event) => {
                                    const orden: "alfabetico" | "pacientes" | "tiempo" = event.target.value as "alfabetico" | "pacientes" | "tiempo";
                                    setOrdenMedicos(orden);

                                    let listadoTemp: Array<IEstadisticasMedicoGuardia> = [];
                                    if (orden === "alfabetico") {
                                        listadoTemp = profesionales.sort((a, b) => (a.nombre.localeCompare(b.nombre)));
                                    } else if (orden === "pacientes") {
                                        listadoTemp = profesionales.sort((a, b) => {
                                            const pacientesA = a.especialidadesInstantaneas.map(x => x.pacientesAtendidosHoy).reduce((sum, current) => sum + current, 0);
                                            const pacientesB = b.especialidadesInstantaneas.map(x => x.pacientesAtendidosHoy).reduce((sum, current) => sum + current, 0);
                                            return pacientesB - pacientesA;
                                        })
                                    } else if (orden === "tiempo") {
                                        listadoTemp = profesionales.sort((a, b) => {
                                            if (a.especialidadesInstantaneas.length === 0 && b.especialidadesInstantaneas.length > 0) {
                                                return 1;
                                            } else if (b.especialidadesInstantaneas.length === 0 && a.especialidadesInstantaneas.length > 0) {
                                                return -1;
                                            } else {
                                                return (a.tiempoPromedioGeneral - b.tiempoPromedioGeneral)
                                            }
                                        });
                                    }
                                    setProfesionalesOrdenados(listadoTemp);
                                    setFiltroMedicos("");
                                    setProfesionalesFiltrados(listadoTemp);
                                }}
                            >
                                <MenuItem value="alfabetico">
                                    Alfabéticamente
                                </MenuItem>
                                <MenuItem value="pacientes">
                                    Pacientes Atendidos
                                </MenuItem>
                                <MenuItem value="tiempo">
                                    Tiempo de Atención
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            aria-label="buscar-medicos"
                            name="buscar-medicos"
                            variant="outlined"
                            label="Buscar Profesional"
                            size="small"
                            disabled={loading}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ margin: 5, minWidth: 250 }}
                            value={filtroMedicos}
                            onChange={(event) => {
                                let listadoTemp: Array<IEstadisticasMedicoGuardia> = [];
                                const filtro: string = event.target.value;
                                setFiltroMedicos(filtro);
                                if (filtro === '') {
                                    listadoTemp = [...profesionalesOrdenados];
                                } else {
                                    profesionalesOrdenados.forEach(
                                        (medico, indexMedico) => {
                                            if (medico.nombre.toLowerCase().includes(filtro.toLowerCase())) {
                                                listadoTemp.push(medico);
                                            }
                                        }
                                    )
                                }
                                setProfesionalesFiltrados(listadoTemp);
                            }}
                        />
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                            onClick={() => { setUpdateState(!updateState) }}
                            disabled={loading}
                        >
                            Actualizar
                        </Button>

                    </Toolbar>
                </CardContent>
                <Divider />
                <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                    {
                        loading ?
                            <LoadingBubbles />
                            :
                            <>
                                {
                                    profesionalesFiltrados.map((profesional, iProfesional) => {
                                        const totalPacientesAtendidos = profesional.especialidadesInstantaneas.map(x => x.pacientesAtendidosHoy).reduce((sum, current) => sum + current, 0);
                                        return (
                                            <div key={iProfesional} className={classes.profesional} style={{ backgroundColor: iProfesional % 2 === 0 ? "white" : theme.paletaColores.fondo.segundo }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography className={classes.profesionalNombre}>
                                                            {profesional.nombre}
                                                        </Typography>
                                                        <Typography>
                                                            Total pacientes atendidos hoy: <span style={{ fontWeight: "bold" }}>{totalPacientesAtendidos}</span>
                                                        </Typography>
                                                        {
                                                            totalPacientesAtendidos !== 0 &&
                                                            (
                                                                <Typography>
                                                                    Tiempo promedio de atención total: <span style={{ fontWeight: "bold" }}>{convertSecondsToLocaleHMS(profesional.tiempoPromedioGeneral)}</span>
                                                                </Typography>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <div>
                                                            {
                                                                profesional.especialidadesInstantaneas.map((especialidad, iEspecialidad) => {
                                                                    return (
                                                                        <Paper key={iEspecialidad} variant="outlined" style={{ padding: 10 }}>
                                                                            <Typography style={{ fontWeight: "bold" }}>
                                                                                {especialidad.descripcion}
                                                                            </Typography>
                                                                            <Typography>
                                                                                {`Pacientes atendidos hoy: `}<span style={{ fontWeight: "bold" }}>{especialidad.pacientesAtendidosHoy}</span>
                                                                            </Typography>
                                                                            <Typography>
                                                                                {`Tiempo promedio de atención: `}<span style={{ fontWeight: "bold" }}>{convertSecondsToLocaleHMS(especialidad.tiempoPromedioAtencion)}</span>
                                                                            </Typography>
                                                                        </Paper>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    })
                                }
                            </>
                    }
                </CardContent>
            </Card>
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={() => { history.goBack() }} />
            </div>
        </>
    )
}

export default withStyles(styles)(EstadisticasGuardia)
