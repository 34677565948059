import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import ConfigurarAgenda from '../../../pages/Administrador/AdministrarConsultoriosMedicos/ConfigurarAgenda/ConfigurarAgenda';
import ListadoMedicos from '../../../pages/Administrador/AdministrarConsultoriosMedicos/ListadoMedicos/ListadoMedicos';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import createTheme, { createThemeFinochietto } from '../../../theme/AppTheme';
import VerAgendaSobreturnos from '../../../pages/Administrador/AdministrarConsultoriosMedicos/VerAgendaSobreturnos/VerAgendaSobreturnos';

const themeMedife = createTheme();
const themeFinochietto = createThemeFinochietto();

const AdministrarConsultoriosMedicos = () => {
    const { path, url } = useRouteMatch();
    const { rol } = useSelector((state: RootState) => state.user)
    const finochietto = rol!.toLowerCase() === "operador" || rol!.toLowerCase() === "administrador finochietto";
    return (
        <ThemeProvider theme={finochietto ? themeFinochietto : themeMedife}>
            <Switch>
                <Redirect from={path} to={`${url}/listado-medicos`} exact />
                <PrivateRoute component={ListadoMedicos} path={`${path}/listado-medicos`} />
                <PrivateRoute component={ConfigurarAgenda} path={`${path}/configurar-agenda`} />
                <PrivateRoute component={VerAgendaSobreturnos} path={`${path}/ver-agenda`} />
            </Switch>
        </ThemeProvider>
    )
}

export default AdministrarConsultoriosMedicos