import React from 'react';
import {
    Grid, Typography, Card, CardContent, CardHeader, Collapse, IconButton,
    Dialog, DialogContent, DialogTitle, Avatar, CardActionArea
} from '@material-ui/core';
import { ArrowForwardIos, Close, ExpandLess, ExpandMore, Videocam } from '@material-ui/icons';

import { IElementoHistorial } from '../../Interfaces/IElementoHistorial';
import ElementoHistoriaClinicaContent from '../ElementoHistoriaClinicaContent/ElementoHistoriaClinicaContent';
import { GeolocationData } from '../../hooks/useGeolocation';

interface OwnProps {
    elementoHistorial: IElementoHistorial;
    setElementoHistorial?: (newElemento: IElementoHistorial) => void;
    elevation?: number;
    popup?: boolean;
    showAvatar?: boolean;
    mostrarNotasPrivadas: boolean;
    puedePedirFarmacia?: boolean;
    areaSupportsPharmacy?: boolean;
    locationData?: GeolocationData;
    mostrarAgregarFavorito?: boolean;
}

type PublicProps = OwnProps
type Props = PublicProps

const CardHistoriaClinica: React.FC<Props> = (props) => {
    const { elementoHistorial, setElementoHistorial, elevation, popup, showAvatar, mostrarNotasPrivadas, puedePedirFarmacia, areaSupportsPharmacy, locationData, mostrarAgregarFavorito } = props

    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(expanded => !expanded);
    };

    const unixTime = elementoHistorial.fecha._seconds;
    const date = new Date(unixTime * 1000);
    const dateLocale = (date.toLocaleDateString("sp-AR"));
    const timeLocale = (date.toLocaleTimeString("sp-AR"));

    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const closeDialog = () => { setDialogOpen(false); };
    const openDialog = () => { setDialogOpen(true); };

    return (
        <>
            <Card elevation={elevation} style={{ marginBottom: 15, marginLeft: 15, marginRight: 15 }} >
                <CardActionArea onClick={popup ? openDialog : handleExpandClick} >
                    <CardHeader
                        avatar={showAvatar ? (<Avatar><Videocam /></Avatar>) : null}
                        title={`Consulta de ${elementoHistorial.especialidad}`}
                        subheader={`${dateLocale} ${timeLocale}`}
                        action={
                            popup ? (<ArrowForwardIos />) : (expanded ? <ExpandLess /> : <ExpandMore />)
                        }
                    />
                </CardActionArea>
                {
                    popup
                        ? null
                        : (
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <ElementoHistoriaClinicaContent
                                        mostrarAgregarFavorito={mostrarAgregarFavorito}
                                        puedePedirFarmacia={puedePedirFarmacia}
                                        mostrarNotasPrivadas={mostrarNotasPrivadas}
                                        elementoHistorial={elementoHistorial}
                                        setElementoHistorial={setElementoHistorial}
                                        areaSupportsPharmacy={areaSupportsPharmacy}
                                        locationData={locationData}
                                    />
                                </CardContent>
                            </Collapse>
                        )
                }
            </Card>
            {
                popup
                    ? (
                        <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth >
                            <DialogTitle disableTypography>
                                <Grid container alignItems="center" direction="row-reverse">
                                    <IconButton onClick={closeDialog} ><Close /></IconButton>
                                    {/* <Button variant="contained" size="small" disableElevation >Descargar en pdf</Button> */}
                                    <Typography variant="h6" style={{ flexGrow: 1, flexShrink: 0 }} >
                                        {`Consulta de ${elementoHistorial.especialidad}`}
                                    </Typography>
                                </Grid>
                                <Typography variant="body1">{`Fecha y hora: ${dateLocale} ${timeLocale}`}</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <ElementoHistoriaClinicaContent
                                    mostrarAgregarFavorito={mostrarAgregarFavorito}
                                    puedePedirFarmacia={puedePedirFarmacia}
                                    mostrarNotasPrivadas={mostrarNotasPrivadas}
                                    elementoHistorial={elementoHistorial}
                                    setElementoHistorial={setElementoHistorial}
                                    areaSupportsPharmacy={areaSupportsPharmacy}
                                    locationData={locationData}
                                />
                            </DialogContent>
                        </Dialog>
                    )
                    : null
            }
        </>
    )
}

export default CardHistoriaClinica as React.ComponentType<PublicProps>
