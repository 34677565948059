import React from 'react'
import { Button, makeStyles, useTheme } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface PublicProps {
    onClickHandler: () => void,
    disabled?: boolean
}

const useStyles = makeStyles({
    button: {
        textTransform: 'none',
        width: 250,
    }
})

const Siguiente: React.FC<PublicProps> = (props) => {
    const { onClickHandler, disabled } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Button
            variant="contained"
            disableElevation
            color="primary"
            endIcon={<ArrowForwardIosIcon fontSize="small" />}
            onClick={onClickHandler}
            disabled={disabled}
            >
            Siguiente
        </Button>
    )
}

export default Siguiente as React.ComponentType<PublicProps>