import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { MenuItem, Box, Divider, FormControl, InputLabel, Select, WithStyles, withStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { styles } from './SeleccionarEspecialidadStyles';
import { setPaso, setEspecialidad } from '../../../../store/paciente/ver-medico-online/verMedicoOnlineSlice';
import { RootState } from '../../../../store/rootReducer';
import Volver from '../../../../components/buttons/Volver/Volver';
import Siguiente from '../../../../components/buttons/Siguiente/Siguiente';
import PerfilPaciente from '../../../../components/PerfilPaciente/PerfilPaciente';
import { IEspecialidad } from '../../../../Interfaces/IEspecialidad';
import { IHorariosEspecialidades } from '../../../../Interfaces/IHorariosEspecialidades';
import { getEspecialidades } from '../../../../apis/especialidadAPI'
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { useTime } from '../../../../hooks/useTime';
import { getFirestore } from '../../../../db';

const SeleccionarEspecialidad: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const now = useTime();
    const db = getFirestore();

    const [listaEspecialidadesApi, setListaEspecialidadesApi] = useState<Array<IEspecialidad>>([]);
    const [especialidadesPrincipales, setListaEspecialidadesPrincipales] = useState<Array<IEspecialidad>>([]);
    const [horarios, setHorarios] = useState<Array<IHorariosEspecialidades>>([]);

    useEffect(() => {
        obtenerDatos();
    }, []);
    
    const { cuilPacienteSeleccionado, pacientesByCuil } = useSelector(
        (state: RootState) => state.user
    )

    const obtenerDatos = async () => {
        setLoading(true);
        const paciente = pacientesByCuil[cuilPacienteSeleccionado];
        const data = (await getEspecialidades(paciente.CODIGO_SEXO, paciente.EDAD, "espontanea"));
        let vals = await db.collection('HorarioAtencion').doc('espontanea').collection('especialidades').get()
        if (!vals.empty) {
            for (let val of vals.docs) {
                let id = val.id
                let data : IHorariosEspecialidades = val.data() as any
                data.id = id
                let horario = [...horarios]
                horario.push(data)
                setHorarios(horario)
            }
        }
        setLoading(false);
        setListaEspecialidadesApi(data as any);

        //solo la primera es principal
        const especialidadesPrincipales = data.slice(0, 1);
        setListaEspecialidadesPrincipales(especialidadesPrincipales);
    };

    const [especialidad, guardarEspecialidad] = useState("")
    const [loading, setLoading] = useState(false);

    const onEspecialidadClicked = (nombreEspecialidad: string) => {
        dispatch(setPaso(3))
        dispatch(setEspecialidad(nombreEspecialidad))
        history.push(`/dashboard/ver-medico-online/sintomas`)
    }

    const onEspecialidadChanged = (event: any) => {
        guardarEspecialidad(event.target.value);
    };

    const onSiguienteClicked = () => {
        if (especialidad === "") {
            return;
        }
        dispatch(setPaso(3))
        dispatch(setEspecialidad(especialidad))
        history.push(`/dashboard/ver-medico-online/sintomas`)
    }

    if ((horarios.filter(val => val!.id!.toLowerCase() === especialidad!.toLowerCase()).length)) {
        let horario = horarios.filter(val => val!.id!.toLowerCase() === especialidad!.toLowerCase())[0]
        /*let horaInicio = horario.horaInicio.split(':')[0]
        let minutoInicio = horario.horaInicio.split(':')[1]
        let horaFin = horario.horaFin.split(':')[0]
        let minutoFin = horario.horaFin.split(':')[1]
        if (
            (now.getHours() <= parseInt(horaInicio) && now.getMinutes() <= parseInt(minutoInicio))
            || (now.getHours() >= parseInt(horaFin) && now.getMinutes() >= parseInt(minutoFin))
            || !horario.days.includes(now.getDay())
        )
        */
        if (!horario.days.map(val => val.day).includes(now.getDay())) {
            return (
                <Dialog open={true} fullWidth maxWidth="xs">
                    <DialogContent>
                        <DialogContentText>
                        {horario.publicText}
                        </DialogContentText>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    guardarEspecialidad("");
                                }}
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )
        } else {
            let dayIndex = horario.days.map(val => val.day).findIndex(x => x === now.getDay())
            let day = horario.days[dayIndex]
            if (
                (now.getHours() <= parseInt(day.inicio.split(':')[0]) && now.getMinutes() <= parseInt(day.inicio.split(':')[1]))
                || (now.getHours() >= parseInt(day.fin.split(':')[0]) && now.getMinutes() >= parseInt(day.fin.split(':')[1]))
            ) {
                return (
                    <Dialog open={true} fullWidth maxWidth="xs">
                        <DialogContent>
                            <DialogContentText>
                            {horario.publicText}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={() => {
                                        guardarEspecialidad("");
                                    }}
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                )
            }
        }
    }

    return (
        <>
            <Divider />
            <Box my={3}>
                <PerfilPaciente
                    paciente={pacientesByCuil[cuilPacienteSeleccionado]} />
            </Box>
            <Divider />
            <h2>
                Seleccione la especialidad
            </h2>

            {loading ?
                (
                    <LoadingBubbles />
                )
                :
                (
                    <>
                        <List>
                            {
                                especialidadesPrincipales.map((especialidad, index) => (
                                    <ListItem button onClick={() => { onEspecialidadClicked(especialidad.descripcion) }} key={index}>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={especialidad.descripcion} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => { onEspecialidadClicked(especialidad.descripcion) }} >
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                        <p>O podés elegir una especialidad de la lista</p>
                        <FormControl fullWidth style={{marginBottom:50}}>
                            <InputLabel htmlFor="especialidad">Selecioná una especialidad</InputLabel>
                            <Select
                                value={especialidad}
                                onChange={onEspecialidadChanged}
                                label="Selecioná una especialidad"
                                inputProps={{
                                    name: 'especialidad'
                                }}
                            >
                                {listaEspecialidadesApi.map((especialidad, index) => {
                                    return (
                                        <MenuItem key={index} value={especialidad.descripcion}>
                                            {especialidad.descripcion}
                                        </MenuItem>
                                    )
                                }
                                )}
                                {/*<option value={"Pediatría"}>Pediatría</option>
                    <option value={"Odontología"}>Odontología</option>*/}
                            </Select>
                        </FormControl>

                        <div className={classes.buttonsRoot}>
                            <Volver onClickHandler={() => history.goBack()} />
                            <Siguiente disabled={especialidad === ""} onClickHandler={onSiguienteClicked} />
                        </div>

                        

                    </>
                )}
        </>
    )
}

export default withStyles(styles)(SeleccionarEspecialidad)
