import React, { useEffect, useState } from 'react';
import useQuery from '../../../hooks/useQuery';
import LoadingBubbles from '../../../components/LoadingBubbles/LoadingBubbles';
import { obtenerDocumentos } from '../../../apis/historiaClinicaAPI';
import { IArchivoSubido } from '../../../Interfaces/IArchivoSubido';
import { AppBar, Box, Grid, Toolbar, Typography } from '@material-ui/core';
import CardArchivoSubido from '../../../components/CardArchivoSubido/CardArchivoSubido';
import { ReactComponent as LogoMedife } from '../../../assets/logos/logo-medife.svg';
import LogoFinochietto from '../../../assets/logos/logo-telemedicina-finochietto.png';

const DocumentosMail: React.FC<any> = () => {

    const query = useQuery();
    const codigo = query.get("codigo");

    const [loading, setLoading] = useState(false);
    const [finochietto, setFinochietto] = useState<boolean>(false);
    const [archivos, setArchivos] = useState<Array<IArchivoSubido>>([]);

    useEffect(() => {
        setLoading(true);
        obtenerDocumentos(codigo!)
            .then(
                (datosDevueltos) => {
                    setLoading(false);
                    setFinochietto(datosDevueltos.esFinochietto);
                    setArchivos(datosDevueltos.documentos);
                }
            )
            .catch(e => { console.log(e) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return (
            <div style={{ marginTop: "45vh" }}>
                <LoadingBubbles />
            </div>
        )
    }

    return (
        <>
            <AppBar style={{ backgroundColor: "white" }}>
                <Toolbar style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: 1130,
                    width: '100%',
                    margin: '0 auto',
                    backgroundColor: "white"
                }} >
                    <Box display="flex" alignItems="center">
                        {
                            finochietto ?
                                <img src={LogoFinochietto} style={{ width: "150px" }} />
                                :
                                <LogoMedife />
                        }
                    </Box>
                    <span style={{ flexGrow: 1 }} />
                </Toolbar>
            </AppBar>
            <Grid container>
                <Grid item xs />
                <Grid container item xs={12} md={10} lg={8} xl={7} justify="center" >
                    <Typography style={{ marginTop: "10vh" }} variant="h5" paragraph>Descargue aquí sus archivos</Typography>
                    <Grid container item xs={12}>
                        {
                            archivos.map(
                                (archivo, indexArchivo) => {

                                    let mostrarLink = true;
                                    if (archivo.linkArchivo === "-Archivo no encontrado-" || archivo.linkArchivo === null || archivo.linkArchivo === undefined) mostrarLink = false;

                                    return (
                                        <Grid item xs={12} md={6} key={indexArchivo}>
                                            <CardArchivoSubido fileName={archivo.file} description={archivo.descripcion} downloadLink={mostrarLink ? archivo.linkArchivo : undefined} />
                                        </Grid>
                                    );
                                }
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs />
            </Grid>
        </>
    )
}



export default DocumentosMail as React.ComponentType;
