import React, { useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { useJitsi } from "react-jutsu";
import ChatIcon from '@material-ui/icons/Chat';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import CallEndIcon from '@material-ui/icons/CallEnd';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { IconButton } from '@material-ui/core';
import jwt from "jsonwebtoken";

import { styles } from './JitsiStyles';

interface OwnProps {
  roomName: string | null;
  displayName: string | null;
  onParticipantJoined?: (obj: {
    id: string, // the id of the participant
    displayName: string // the display name of the participant
  }) => void;
  onParticipantLeft?: (obj: {
    id: string // the id of the participant
  }) => void;
  onFinalizarBtn?: () => void;
  showHangup?: boolean;
  showFullscreen: boolean;
  showChat: boolean;
  finochietto?: boolean;
}

type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>

const PARENT_NODE = 'jitsi-container'

const Jitsi: React.FC<Props> = (props) => {
  const { classes, roomName, displayName, onParticipantJoined, onParticipantLeft, showHangup, showFullscreen, showChat, finochietto, onFinalizarBtn } = props;

  const T = jwt.sign({
    "aud": "jitsi",
    "iss": "98DE2",
    "sub": "meet.jitsi",
    "room": `${roomName}`
  }, '98DE2C1642C5BDCC23675B5B2BF57729')

  const [isMicrofonoEncendido, toggleMicrofono] = useState(true);
  const [isCamaraEncendida, toggleCamara] = useState(true);
  const [isFullScreen, toggleScreen] = useState(false);
  const [isLlamadaFinalizada, setLlamadaFinalizada] = useState(false);
  const [token, setToken] = useState(T);
  //const isSmallDevice = useMediaQuery('(max-width:600px)');--> para detectar dispositivo pequeños
  const { loading, error, jitsi } = useJitsi({
    roomName,
    displayName,
    subject: "Consulta médica",
    parentNode: PARENT_NODE,
    domain: 'call.medife.com.ar',//'jitsi.zentricx.com',//'meet.aeros.cloud',
    configOverwrite: {
      disableDeepLinking: true
    },
    jwt: token
  });

  React.useEffect(() => {

    const onIncomingMessage = (obj: {
      from: string, // The id of the user that sent the message
      nick: string, // the nickname of the user that sent the message
      message: string // the text of the message
    }) => {
      console.log('onIncomingMessage//////////////', obj)
    }
    const onOutgoingMessage = (obj: {
      message: string // the text of the message
    }) => {
      console.log('onOutgoingMessage//////////////', obj)
    }
    if (jitsi) {
      jitsi.addListener('incomingMessage', onIncomingMessage);
      jitsi.addListener('outgoingMessage', onOutgoingMessage);
      if (onParticipantJoined) {
        jitsi.addListener('participantJoined', onParticipantJoined);
      }
      if (onParticipantLeft) {
        jitsi.addListener('participantLeft', onParticipantLeft);
      }
      return () => {
        jitsi.removeListener('incomingMessage', onIncomingMessage);
        jitsi.removeListener('outgoingMessage', onOutgoingMessage);
        if (onParticipantJoined) {
          jitsi.removeListener('participantJoined', onParticipantJoined);
        }
        if (onParticipantLeft) {
          jitsi.removeListener('participantLeft', onParticipantLeft);
        }
      }
    }
  }, [jitsi])

  React.useEffect(() => {
    console.log('yuyu')
    if (jitsi) {
      setInterval(() => {
        jitsi.isAudioMuted().then((muted: boolean) => {
          if (muted) {
            toggleMicrofono(false);
          } else {
            toggleMicrofono(true);
          }
        });
        jitsi.isVideoMuted().then((muted: boolean) => {
          if (muted) {
            toggleCamara(false);
          } else {
            toggleCamara(true);
          }
        });
      }, 4000);
    }
  }, [jitsi]);

  if (error) {
    return <p>Error en videollamada.</p>
  }

  const hangup = () => {
    jitsi.executeCommand('hangup');
    jitsi.dispose();
    setLlamadaFinalizada(true);
    if (onFinalizarBtn) {
      onFinalizarBtn()
    }
  };

  const shareScreen = () => jitsi.executeCommand('toggleShareScreen');

  const toggleChat = () => jitsi.executeCommand('toggleChat');

  const toggleVideo = () => {
    toggleCamara(isEncendida => !isEncendida);
    jitsi.executeCommand('toggleVideo');
  }

  const toggleAudio = () => {
    toggleMicrofono(isEncendido => !isEncendido);
    jitsi.executeCommand('toggleAudio');
  }

  const toggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.getElementById('root-principal')?.requestFullscreen();
    }
    toggleScreen(fullscreen => !fullscreen);
  }

  return (
    <div id="root-principal" className={classes.root}>
      {
        isLlamadaFinalizada
          ? <div className={classes.llamadaFinalizada}><h2>Llamada Finalizada!</h2></div>
          : (
            <>
              <div
                id={PARENT_NODE}
                style={{ display: loading ? 'none' : 'block', width: '100%', height: 'calc(100% - 50px)' }}
              />
              <div className={finochietto ? classes.buttonsFinochietto : classes.buttons}>
                <div >
                  {
                    showChat &&
                    <IconButton aria-label="chat" onClick={toggleChat}>
                      <ChatIcon />
                    </IconButton>
                  }
                  {/* <IconButton aria-label="compartir pantalla" onClick={shareScreen}>
                    <DesktopWindowsIcon />
                  </IconButton> */}
                </div>
                <div >
                  <IconButton aria-label="micrófono" onClick={toggleAudio}>
                    {isMicrofonoEncendido ? <MicIcon /> : <MicOffIcon />}
                  </IconButton>
                  {
                    showHangup &&
                    (
                      <IconButton aria-label="finalizar llamada" onClick={hangup}>
                        <CallEndIcon />
                      </IconButton>
                    )
                  }
                  <IconButton aria-label="cámara" onClick={toggleVideo}>
                    {isCamaraEncendida ? <VideocamIcon /> : <VideocamOffIcon />}
                  </IconButton>
                </div>
                <div>
                  {
                    showFullscreen &&
                    <IconButton aria-label="pantalla completa" onClick={toggleFullScreen}>
                      {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                  }
                </div>
              </div>
            </>
          )
      }
    </div >
  );
};

export default withStyles(styles)(Jitsi) as React.ComponentType<PublicProps>;
