import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { fetchPacientes } from '../../../apis/pacientesAPI'
import { IPaciente } from '../../../Interfaces/IPaciente';
import { fetchEventosCalendario } from '../../../apis/calendarioAPI';
import { IResultadoListaEventosCalendario } from '../../../Interfaces/IResultadoListaEventosCalendario';
import { ISintomaPreguntasRespuestas } from '../../../Interfaces/ISintomaPreguntasRespuestas';

interface IconsultasProgramadasState {
    especialidad: string;
    idSalaVideollamada: string;
    passSalaVideollamada: string;
    paso: number;
    error: string | null;
    sintomaGuardado: ISintomaPreguntasRespuestas | null;
    horarioTurno: string;
    fechaTurno: string;
    idProfesional: string;
    motivo: string;
    nombreMedico: string;
    matriculaMedico: number;
    imagenMedicoURL?: string;
}

let initialState: IconsultasProgramadasState = {
    especialidad: "",
    idSalaVideollamada: "",
    passSalaVideollamada: "",
    paso: 0,
    error: null,
    sintomaGuardado: null,
    horarioTurno: "",
    fechaTurno: "",
    idProfesional: "",
    motivo: "",
    nombreMedico: "",
    matriculaMedico: -1
}

const consultasProgramadasSlice = createSlice({
    name: "consultasProgramadas",
    initialState,
    reducers: {
        setPaso(state, action: PayloadAction<number>) {
            state.paso = action.payload
        },
        
        getEventosCalendarioSuccess(state, action: PayloadAction<IResultadoListaEventosCalendario>) {
            action.payload.items.forEach(evento => {
                /*
                state.pacientesByCuil[evento.CUIL] = evento;
                state.cuilsDePacientes.push(evento.CUIL);
                */
            })

            state.error = null
        },
        getEventosCalendarioFailed(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        
        setEspecialidad(state, action: PayloadAction<string>) {
            state.especialidad = action.payload
        },
        setIdSalaVideollamada(state, action: PayloadAction<string>) {
            state.idSalaVideollamada = action.payload
        },
        setPassSalaVideollamada(state, action: PayloadAction<string>) {
            state.passSalaVideollamada = action.payload
        },
        setSintomaGuardado(state, action: PayloadAction<ISintomaPreguntasRespuestas>) {
            state.sintomaGuardado = action.payload
        },
        setHorarioTurno(state, action: PayloadAction<string>) {
            state.horarioTurno = action.payload
        },
        setFechaTurno(state, action: PayloadAction<string>) {
            state.fechaTurno = action.payload
        },
        setIdProfesional(state, action: PayloadAction<string>) {
            state.idProfesional = action.payload
        },
        setMotivo(state, action: PayloadAction<string>) {
            state.motivo = action.payload
        },
        setNombreMedico(state, action: PayloadAction<string>) {
            state.nombreMedico = action.payload
        },
        setImagenMedicoURL(state, action: PayloadAction<string | undefined>) {
            state.imagenMedicoURL = action.payload
        },
        setMatriculaMedico(state, action: PayloadAction<number>) {
            state.matriculaMedico = action.payload
        },

    }
});


export const {
    setPaso,
    setEspecialidad,
    setIdSalaVideollamada,
    setPassSalaVideollamada,
    getEventosCalendarioSuccess,
    getEventosCalendarioFailed,
    setSintomaGuardado,
    setHorarioTurno,
    setFechaTurno,
    setIdProfesional,
    setMotivo,
    setNombreMedico,
    setMatriculaMedico,
    setImagenMedicoURL,
} = consultasProgramadasSlice.actions;

export default consultasProgramadasSlice.reducer;

export function getEventosCalendario(mailProfesional: string): AppThunk {
    return async (dispatch) => {
        try {
            const eventosCalendario = await fetchEventosCalendario(mailProfesional);
            return eventosCalendario;
        } catch (err) {
            return null;
        }
    };
}