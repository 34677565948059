import { Checkbox, Chip, FormControl, Grid, IconButton, Input, InputLabel, ListItemText, MenuItem, Paper, Select, Typography, useTheme } from "@material-ui/core";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, DeleteOutlined } from "@material-ui/icons";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from '@date-io/date-fns';

import React from "react";

interface OwnProps {
    inicio: Date;
    fin: Date;
    handleChangeInicio: (date: MaterialUiPickersDate) => void;
    handleChangeFin: (date: MaterialUiPickersDate) => void;
    handleChangeEspecialidad: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void;
    especialidadesSeleccionadas: Array<string>;
    especialidadesPosibles: Array<string>;
    canDelete: boolean;
    handleDelete: () => void;
    tipo: "virtual" | "presencial";
    handleChangeTipo: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void;
    sinAtencion?: boolean;
    posibleSinAtencion?: boolean;
    handleToggleSinAtencion?: () => void;
}

type PublicProps = OwnProps
type Props = PublicProps


const CardHorarioEspecialidad: React.FC<Props> = (props) => {
    const {
        inicio,
        fin,
        handleChangeInicio,
        handleChangeFin,
        especialidadesPosibles,
        handleChangeEspecialidad,
        especialidadesSeleccionadas,
        canDelete,
        handleDelete,
        tipo,
        handleChangeTipo,
        sinAtencion,
        posibleSinAtencion,
        handleToggleSinAtencion,
    } = props

    const theme = useTheme();

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Paper
                    style={{ marginBottom: 10, padding: 10 }}
                >
                    <Grid container>
                        <Grid item xs={10}>
                            {posibleSinAtencion &&
                                <Typography>
                                    <IconButton
                                        onClick={handleToggleSinAtencion}
                                    >
                                        {sinAtencion ?
                                            (<CheckBoxOutlined />)
                                            :
                                            (<CheckBoxOutlineBlankOutlined />)
                                        }
                                    </IconButton>
                                    Sin atencion
                                </Typography>
                            }
                            {
                                sinAtencion ?
                                    (
                                        null
                                    )
                                    :
                                    (
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TimePicker
                                                        minutesStep={5}
                                                        fullWidth
                                                        orientation="landscape"
                                                        variant="inline"
                                                        label="Desde"
                                                        value={inicio}
                                                        onChange={handleChangeInicio}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TimePicker
                                                        minutesStep={5}
                                                        fullWidth
                                                        orientation="landscape"
                                                        variant="inline"
                                                        label="Hasta"
                                                        value={fin}
                                                        onChange={handleChangeFin}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {inicio.getTime() >= fin.getTime() && <Typography style={{ color: theme.paletaColores.error }}>La hora de inicio debe ser anterior a la hora de fin.</Typography>}
                                        </>
                                    )
                            }
                            <FormControl fullWidth style={{ marginBottom: 10 }} margin="normal" >
                                <InputLabel >Especialidades</InputLabel>
                                <Select
                                    value={especialidadesSeleccionadas}
                                    onChange={handleChangeEspecialidad}
                                    multiple
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={(selected) => (
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {(selected as string[]).map((value, indexSelected) => (
                                                <Chip key={indexSelected} style={{ margin: 1 }} label={value} size="small" />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {especialidadesPosibles.map(
                                        (especialidad, indexEspecialidad) => {
                                            return (
                                                <MenuItem key={indexEspecialidad} value={especialidad}>
                                                    <Checkbox checked={especialidadesSeleccionadas.indexOf(especialidad) > -1} />
                                                    <ListItemText primary={especialidad} />
                                                </MenuItem>
                                            )
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            {especialidadesSeleccionadas.length === 0 && <Typography style={{ color: theme.paletaColores.error }}>Debe seleccionar al menos una especialidad.</Typography>}
                            <FormControl fullWidth style={{ marginBottom: 10 }} margin="normal" >
                                <InputLabel >Tipo</InputLabel>
                                <Select
                                    value={tipo}
                                    onChange={handleChangeTipo}
                                >
                                    <MenuItem value={"virtual"}>Virtual</MenuItem>
                                    <MenuItem value={"presencial"}>Presencial</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <div>
                                <IconButton
                                    disabled={!canDelete}
                                    onClick={handleDelete}
                                    style={{ color: canDelete ? theme.paletaColores.error : theme.paletaColores.fondo.segundo }}
                                >
                                    <DeleteOutlined />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                </Paper >
            </MuiPickersUtilsProvider >
        </>
    )
}

export default CardHorarioEspecialidad as React.ComponentType<PublicProps>