import React, { useEffect, useState } from 'react'
import useQuery from '../../../hooks/useQuery'

import Jitsi from '../../../components/jitsi/Jitsi';
import LoadingBubbles from '../../../components/LoadingBubbles/LoadingBubbles';
import { AppBar, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button, IconButton, Toolbar, Typography, useTheme, ThemeProvider } from '@material-ui/core';
import { ReactComponent as LogoFinochietto } from '../../../assets/logos/logos_svg/logo_tm_finochietto_g.svg';
import LogoGoogleCloud from '../../../assets/logos/google_cloud.png';
import User from '../../../components/core/NavBar/user/User';
import SalaEsperaFinochietto from './Pasos/SalaEsperaFinochietto/SalaEsperaFinochietto';
import LlamadoConsultorioFinochietto from './Pasos/LlamadoConsultorioFinochietto/LlamadoConsultorioFinochietto';
import ConsultorioPacienteFinochietto from './Pasos/ConsultorioPacienteFinochietto/ConsultorioPacienteFinochietto';
import { aceptarTerminosyCondiciones, obtenerTurnoFinochietto } from '../../../apis/finochiettoAPI';
import { convertDatestringToDate, convertDateToDatestring, _calculateAge } from '../../../utils/dateTimeHelper';
import { setEnEspera } from '../../../apis/finochiettoAPI';
import { getFirestore } from '../../../db';
import EncuestaPacienteFinochietto from './Pasos/EncuestaPacienteFinochietto/EncuestaPacienteFinochietto';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { createThemeFinochietto } from '../../../theme/AppTheme';
import TerminosCondicionesFinochietto from './Pasos/TerminosCondicionesFinochietto/TerminosCondicionesFinochietto';
import { registrarEgresoPaciente } from '../../../apis/citaInstantaneaAPI';

const themeFinochietto = createThemeFinochietto();

const PacientesFinochietto: React.FC<any> = () => {
    const query = useQuery();
    const theme = useTheme();

    // 0: TERMINOS_CONDICIONES 
    // 1: SALA_ESPERA 
    // 2: LLAMADO_CONSULTORIO 
    // 3: CONSULTORIO_VIDEOLLAMADA 
    // 4: CITA_TERMINADA 
    // 5: CITA_CANCELADA
    const [estado, setEstado] = useState(0);
    const [loading, setLoading] = useState(false);
    const [aceptoTYC, setAceptoTYC] = useState(false);
    const [terminos, setTerminos] = useState("");

    const db = getFirestore();

    const changeFechaNacimiento = (fecha: Date) => {
        setEdad(_calculateAge(fecha));
        setFechaNacimiento(fecha);
    }
    const cs = query.get("cs");
    useEffect(() => {
        let unsubscribe: () => void;
        setLoading(true);
        obtenerTurnoFinochietto(cs!)
            .then(
                (result) => {
                    setNombre(result.paciente.nombre);
                    setApellido(result.paciente.apellido);
                    setFinanciador(result.financiador);
                    setCredencial(result.numeroCredencial);
                    setCuil(result.paciente.cuil!);
                    setSesion(result.sesion);
                    changeFechaNacimiento(convertDatestringToDate(result.paciente.fechaNacimiento));
                    setTerminos(result.tyc);

                    unsubscribe = db.collection("pacientes").doc(result.paciente.cuil).collection("Consultas").doc(result.sesion).onSnapshot(
                        (datosSesion) => {
                            if (!datosSesion.exists) {
                                console.log("La sesion no existe");
                                return;
                            }
                            const estadoCita = datosSesion!.data()!.estado.toLowerCase();
                            console.log("0. la sesion existe");
                            if (estadoCita === "atendido" || estadoCita === "atendida") {
                                if (datosSesion!.data()!.finBtn){
                                    setEstado(pasos.findIndex(x => x.id === "CITA_TERMINADA"));
                                }
                                else if (!llamadoAConsultorio) {
                                    setLlamadoAConsultorio(true);
                                    setEstado(pasos.findIndex(x => x.id === "LLAMADO_CONSULTORIO"));
                                }
                            } else if (estadoCita === "activo" || estadoCita === "activa") {
                                setEstado(pasos.findIndex(x => x.id === (aceptoTYC ? "SALA_ESPERA" : "TERMINOS_CONDICIONES")));
                            } else if (estadoCita === "terminado" || estadoCita === "terminada") {
                                setEstado(pasos.findIndex(x => x.id === "CITA_TERMINADA"));
                            } else if (estadoCita === "cancelado" || estadoCita === "cancelada") {
                                setEstado(pasos.findIndex(x => x.id === "CITA_CANCELADA"));
                            } else if (estadoCita === "pacientefinalizo") {
                                setEstado(pasos.findIndex(x => x.id === "CITA_TERMINADA"));
                            } else {
                                console.log(`El estado es ${datosSesion!.data()!.estado}`);
                            }
                        }
                    );

                    setLoading(false);
                }
            )
            .catch(e => console.log(e))
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, [aceptoTYC])
    
    //datos del paciente, obtener de endpoint usando codigo seguro
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [financiador, setFinanciador] = useState("");
    const [edad, setEdad] = useState(29);
    const [fechaNacimiento, setFechaNacimiento] = useState(new Date());
    const [credencial, setCredencial] = useState("");
    const [cuil, setCuil] = useState("");
    const [sesion, setSesion] = useState("");
    const [llamadoAConsultorio, setLlamadoAConsultorio] = useState(false);

    const pasos = [
        {
            id: "TERMINOS_CONDICIONES",
            content: <TerminosCondicionesFinochietto
                nombre={nombre}
                setNombre={setNombre}
                apellido={apellido}
                setApellido={setApellido}
                fechaNacimiento={fechaNacimiento}
                setFechaNacimiento={changeFechaNacimiento}
                credencial={credencial}
                setCredencial={setCredencial}
                terminos={terminos}
                financiador={financiador}
                onAceptar={() => {
                    if (pasos[estado].id === "TERMINOS_CONDICIONES") {
                        setLoading(true);
                        aceptarTerminosyCondiciones({
                            credencial: credencial,
                            nombre: nombre,
                            apellido: apellido,
                            fechaNacimiento: convertDateToDatestring(fechaNacimiento),
                            aceptaTyC: true,
                            codigoSeguro: cs!,
                        })
                            .then((recibido) => {
                                setEnEspera({ usuario: cuil, sesion: sesion })
                                    .then(
                                        (resultado) => {
                                            setLoading(false);
                                            setAceptoTYC(true);
                                            setEstado(pasos.findIndex(x => x.id === "SALA_ESPERA"));
                                        }
                                    )
                                    .catch(e => console.log(e))
                            })
                            .catch(e => { console.log(e) })
                    }
                }}
            />
        },
        {
            id: "SALA_ESPERA",
            content: <SalaEsperaFinochietto />
        },
        {
            id: "LLAMADO_CONSULTORIO",
            content: <LlamadoConsultorioFinochietto
                nombre={nombre}
                apellido={apellido}
                financiador={financiador}
                edad={edad}
                credencial={credencial}
                onEntrarConsultorio={() => {
                    if (pasos[estado].id === "LLAMADO_CONSULTORIO") {
                        setEstado(pasos.findIndex(x => x.id === "CONSULTORIO_VIDEOLLAMADA"));
                    }
                }}
            />
        },
        {
            id: "CONSULTORIO_VIDEOLLAMADA",
            content: <ConsultorioPacienteFinochietto
                nombre={nombre}
                apellido={nombre}
                cuil={cuil}
                sesion={sesion}
                onFinalizar={() => {
                    setLoading(true);
                    registrarEgresoPaciente({ usuario: cuil, sesion: sesion })
                        .then((respuesta) => {
                            setLoading(false);
                            setEstado(pasos.findIndex(x => x.id === "CITA_TERMINADA"));
                        })
                        .catch(e => { console.log(e); })
                }}
            />
        },
        {
            id: "CITA_TERMINADA",
            content: <EncuestaPacienteFinochietto
                nombre={nombre}
                apellido={nombre}
                cuil={cuil}
                sesion={sesion}
            />
        },
        {
            id: "CITA_CANCELADA",
            content:
                <Dialog open={true}>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                Su cita ha sido cancelada
                            </Typography>
                            <Typography>
                                Cierre la pestaña para salir
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
        },
    ]
    const body = loading ? <div style={{ marginTop: "40vh" }}> <LoadingBubbles /></div> : pasos[estado].content;

    const error400 = useLocalStorage("Error400", 100);
    return (
        <ThemeProvider theme={themeFinochietto}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <AppBar style={{ backgroundColor: theme.paletaColores.fondo.primero, color: theme.paletaColores.negro.finochietto }}>
                    <Toolbar>
                        <LogoFinochietto style={{ width: "150px" }} />
                        <span style={{ flexGrow: 1 }} />
                        <User
                            plan={`${financiador} - ${credencial}`}
                            NOMBRE={nombre}
                            APELLIDO={apellido}
                            onClickHandler={() => { }}
                            showData={true} />
                    </Toolbar>
                </AppBar>
                <div style={{ marginTop: "80px", width: "calc(100% - 20px)", maxWidth: "1000px", height: "calc(100% - 100px)" }}>
                    {body}
                </div>
                <div style={{ height: "80px" }} />
                <Dialog open={error400 !== undefined && error400.length > 0}>
                    <DialogTitle>
                        Error
                </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {error400}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                localStorage.removeItem("Error400")
                            }}
                            variant="contained"
                            disableElevation
                            color="primary"
                        >
                            OK
                    </Button>
                    </DialogActions>
                </Dialog>
                <AppBar position="fixed" style={{ backgroundColor: "white", bottom: 0, top: "auto" }}>
                    <div style={{ color: "black", alignContent: "center", textAlign: "center", justifyContent: "center", width: "100%", flexGrow: 1 }}>
                        <img src={LogoGoogleCloud} style={{ margin: 4 }} />
                    </div>
                </AppBar>
            </div>
        </ThemeProvider>
    )
}

export default PacientesFinochietto as React.ComponentType;
