import axios from "axios";
import firebase from "firebase";
import { IProfesionalLogin } from "../Interfaces/IProfesionalLogin";

const profesionalAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    "Authorization": '',
  },
});

profesionalAPI.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem('token');
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers['Authorization'] = 'bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);
profesionalAPI.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.setItem("LoggedIn", "false");
  }
  return error;
});

export async function InfoLoginGoogle(email: string, photoURL: string): Promise<IProfesionalLogin> {
  const { data } = await profesionalAPI.post<IProfesionalLogin>(`/InfoLoginGoogle`, { email: email, photoURL: photoURL })
  return data;
}