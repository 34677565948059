import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

interface OwnProps {
    backgroundColor: string;
    textoRectangulo?: string | undefined;
    titulo: string;
    subtitulo: string;
    componenteRectangulo?: React.ReactElement;
}

type PublicProps = OwnProps
type Props = PublicProps

const CardGuardiaMedico: React.FC<Props> = (props) => {
    const { backgroundColor, textoRectangulo = "", titulo, subtitulo, componenteRectangulo = undefined } = props

    const elementoDelRectangulo = componenteRectangulo
        ? componenteRectangulo
        : (<Typography variant="h2" style={{ color: "white" }}>{textoRectangulo}</Typography>);

    return (
        <Grid container item xs>
            <Grid container item xs={4} sm={3}>
                <Paper square elevation={0} style={{ backgroundColor: backgroundColor, width: "88%", margin: "6%", height: "100px" }} >
                    <Grid container item xs={12} justify="center" alignItems="center" style={{ height: "100%" }}>
                        {elementoDelRectangulo}
                    </Grid>
                </Paper>
            </Grid>
            <Grid container item xs={8} sm={9}>
                <Paper elevation={0} square style={{ backgroundColor: "#EEEEEE", width: "96%", margin: "2%", height: "100px" }} >
                    <Grid container item xs={12} justify="center" style={{ height: "100%" }} direction="column">
                        <Typography variant="h6">{titulo}</Typography>
                        <Typography variant="body1" >{subtitulo}</Typography>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default CardGuardiaMedico as React.ComponentType<PublicProps>
