import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import SeleccionarEspecialidad from '../../../pages/Paciente/VerMedicoOnline/SeleccionarEspecialidad/SeleccionarEspecialidad';
import SeleccionarPaciente from '../../../pages/Paciente/VerMedicoOnline/SeleccionarPaciente/SeleccionarPaciente';
import SeleccionarSintomas from '../../../pages/Paciente/VerMedicoOnline/SeleccionarSintomas/SeleccionarSintomas';
import Confirmar from '../../../pages/Paciente/VerMedicoOnline/Confirmar/Confirmar';
import SalaEspera from '../../../pages/Paciente/VerMedicoOnline/SalaEspera/SalaEspera';
import LlamadoConsultorio from '../../../pages/Paciente/VerMedicoOnline/LlamadoConsultorio/LlamadoConsultorio';
import Consultorio from '../../../pages/Paciente/VerMedicoOnline/ConsultorioPacienteGuardia/ConsultorioPacienteGuardia';

const VerMedicoOnline = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Redirect from={path} to={`${url}/paciente`} exact />
            <PrivateRoute component={SeleccionarPaciente} path={`${path}/paciente`} />
            <PrivateRoute component={SeleccionarEspecialidad} path={`${path}/especialidad`} />
            <PrivateRoute component={SeleccionarSintomas} path={`${path}/sintomas`} />
            <PrivateRoute component={Confirmar} path={`${path}/confirmar`} />
            <PrivateRoute component={SalaEspera} path={`${path}/espera`} />
            <PrivateRoute component={LlamadoConsultorio} path={`${path}/llamado`} />
            <PrivateRoute component={Consultorio} path={`${path}/consultorio`} />
        </Switch>
    )
}

export default VerMedicoOnline