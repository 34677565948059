import React from 'react'
import { useHistory } from 'react-router-dom';
//MATERIAL-UI

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ThemeProvider } from '@material-ui/core';

import { IServicio } from '../../../Interfaces/IServicio';
import operadorCC1 from '../../../../src/assets/images/operadorCC1.jpg'
import { BorderAllRounded, PhoneOutlined } from '@material-ui/icons';
import { createThemeFinochietto } from '../../../theme/AppTheme';

const themeFinochietto = createThemeFinochietto();

const servicios: ReadonlyArray<IServicio> = [
    {
        id: 'GESTIONAR_TURNOS',
        primaryText: "Gestionar turnos",
        secondaryText: "Gestión telefónica de turnos para citas programadas",
        icon: PhoneOutlined,
        to: "/dashboard/operador/gestionar-turnos"
    },
    // {
    //     id: 'GESTIONAR_ESPONTANEAS',
    //     primaryText: "Gestionar videoconsultas de guardia",
    //     secondaryText: "Manejar la atención del consultorio de demanda espontánea",
    //     icon: VideocamOutlinedIcon,
    //     to: "/dashboard/admin/administrar-guardia"
    // },
    // {
    //     id: 'GESTIONAR_SINTOMAS',
    //     primaryText: "Gestionar sintomas",
    //     secondaryText: "Agregar, quitar y modificar sintomas y preguntas del triaje",
    //     icon: WorkOutlineOutlined,
    //     to: "/dashboard/admin/administrar-sintomas"
    // },
    // {
    //     id: 'GESTIONAR_HORARIO_ATENCION',
    //     primaryText: "Gestionar horario de atención de guardia",
    //     secondaryText: "Establecer, modificar o quitar horarios de atención",
    //     icon: ScheduleOutlined,
    //     to: "/dashboard/admin/administrar-horarios"
    // },
]

const WelcomeOperador = () => {
    const history = useHistory()

    const onItemClicked = (servicio: IServicio) => {
        history.push(servicio.to)
    }

    const serviciosList = servicios.map((servicio, index) => (
        <Box key={index} borderBottom="1px solid #efefef" >
            <ListItem button style={{ paddingTop: 20, paddingBottom: 20 }} onClick={() => onItemClicked(servicio)} >
                <ListItemIcon>
                    <servicio.icon fontSize="large" />
                </ListItemIcon>
                <ListItemText
                    primary={servicio.primaryText}
                    secondary={servicio.secondaryText}
                />
                <ListItemSecondaryAction onClick={() => onItemClicked(servicio)} >
                    <IconButton edge="end" >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </Box>
    ))

    return (
        <ThemeProvider theme={themeFinochietto}>
            <Grid container justify="center" spacing={3}>
                <Grid item xs={12} md={7} lg={7}>
                    <List>
                        {
                            serviciosList
                        }
                    </List>
                </Grid>
                <Grid item xs={10} md={5} lg={5}>
                    <img style={{ borderRadius: "30px 0px 30px 0px" }} src={operadorCC1} alt="imagen operador" width="100%" />
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default WelcomeOperador
