import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { IPaciente } from '../../../Interfaces/IPaciente';
import { IEspecialidad } from '../../../Interfaces/IEspecialidad';

interface IadministrarSolicitudesFarmaciaState {
    paso: number;
    error: string | null;
}

let initialState: IadministrarSolicitudesFarmaciaState = {
    paso: 0,
    error: null,
}

const administrarSolicitudesFarmaciaSlice = createSlice({
    name: "administrarSolicitudesFarmacia",
    initialState,
    reducers: {
        setPaso(state, action: PayloadAction<number>) {
            state.paso = action.payload
        },
    }
});


export const {
    setPaso,
} = administrarSolicitudesFarmaciaSlice.actions;

export default administrarSolicitudesFarmaciaSlice.reducer;