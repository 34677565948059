import { FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core'
import React from 'react'
import LoadingBubbles from '../../../../../components/LoadingBubbles/LoadingBubbles';
import { IFinanciador } from '../../../../../Interfaces/IFinanciador';

interface OwnProps {
    financiadores: Array<IFinanciador>
    onChangeFinanciador: (financiador: IFinanciador) => void
    financiador: IFinanciador | undefined;

    onHabilitarSiguiente: (habilitado: boolean) => void
    siguienteHabilitado: boolean;
    loading: boolean;
}

function SeleccionFinanciador(props: OwnProps) {
    const { financiador, financiadores, siguienteHabilitado, onChangeFinanciador, onHabilitarSiguiente, loading } = props;

    if (loading) {
        return (
            <div>
                <LoadingBubbles />
            </div>
        )
    }

    return (
        <Grid container spacing={2} >
            <Grid item xs />
            <Grid item xs={6}>

                <FormControl fullWidth>
                    <InputLabel>Financiador</InputLabel>
                    <Select
                        label="Financiador"
                        value={financiador?.id}
                        onChange={(event) => {
                            const seleccionado = financiadores.find(x => x.id === event.target.value as number);
                            onChangeFinanciador(seleccionado!);
                            if (event.target.value) {
                                onHabilitarSiguiente(true);
                            }
                        }}
                    >
                        {
                            financiadores.map((fin, iFin) => {
                                return (
                                    <MenuItem value={fin.id!} key={iFin}>
                                        {fin.descripcion}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs />
        </Grid>
    )
}

export default SeleccionFinanciador
