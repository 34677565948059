import axios from "axios";
import { IArchivoSubido } from "../Interfaces/IArchivoSubido";
import { ICheckCitaAsignadaResponse } from "../Interfaces/ICheckCitaAsignadaResponse";
import { IConsultaEnEspera } from "../Interfaces/IConsultaEnEspera";
import { IPostCitaInstantanea } from "../Interfaces/IPostCitaInstantanea";
import { IPostRegistrarIngresoPaciente } from "../Interfaces/IPostRegistrarIngresoPaciente";
import { IResultadoCargarArchivo } from "../Interfaces/IResultadoCargarArchivo";
import { IResultadoObtenerCitaSiguiente } from "../Interfaces/IResultadoObtenerCitaSiguiente";
import { IUsuarioSesion } from "../Interfaces/IUsuarioSesion";
import { IUsuarioSesionEmail } from "../Interfaces/IUsuarioSesionEmail";
import firebase from "firebase";
import { convertDateToDatestring, convertDateToDiaHora } from "../utils/dateTimeHelper";
import { IHoraInicioFin } from "../Interfaces/IHoraInicioFin";
import { IEstadisticasMedicoGuardia } from "../Interfaces/IEstadisticasMedicoGuardia";

const citaInstantaneaApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    "Authorization": '',
  },
});

citaInstantaneaApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem('token');
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers['Authorization'] = 'bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);
citaInstantaneaApi.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.setItem("LoggedIn", "false");
  }
  return error;
});

export async function obtenerCitaSiguiente(usuario: string, sesion: string): Promise<IResultadoObtenerCitaSiguiente> {
  const { data } = await citaInstantaneaApi.get(`/ObtenerCita?usuario=${usuario}&sesion=${sesion}`);
  return data;
}

export async function asignarCitaInstantanea(usuario: string, sesion: string, emailProfesional: string): Promise<string> {
  let body: IUsuarioSesionEmail = {
    usuario: usuario,
    sesion: sesion,
    profesional: emailProfesional
  };

  const { data } = await citaInstantaneaApi.post<string>(`/AsignarCitaInstantanea`, body);
  return data;
}
export async function desasignarCitaInstantanea(usuario: string, sesion: string, emailProfesional: string): Promise<string> {
  let body: IUsuarioSesionEmail = {
    usuario: usuario,
    sesion: sesion,
    profesional: emailProfesional
  };

  const { data } = await citaInstantaneaApi.post<string>(`/DesasignarCitaInstantanea`, body);
  return data;
}

export async function postCitaInstantanea(postCitaInstantanea: IPostCitaInstantanea): Promise<{ estado: string, tiempoEsperaPromedio: number }> {
  const { data } = await citaInstantaneaApi.post(`/crearCitaInstantanea`, postCitaInstantanea);
  return data;
}

export async function postArchivo(archivo: File, idSalaVideollamada: string, cuilPacienteSeleccionado: string, descripcion: string, origen: "profesional" | "paciente" = "paciente" ): Promise<IResultadoCargarArchivo> {
  let formData = new FormData();
  formData.append("file", archivo);
  formData.append("idSalaVideollamada", idSalaVideollamada);
  formData.append("cuilPacienteSeleccionado", cuilPacienteSeleccionado);
  formData.append("descripcion", descripcion);
  formData.append("origen", origen); //origen "profesional" o "paciente"
  const { data } = await citaInstantaneaApi.post<IResultadoCargarArchivo>("/cargarArchivo", formData);
  return data;
}
export async function borrarArchivo(sesion: string, usuario: string, link: string, origen: "paciente" | "profesional"): Promise<string> {
  const requestBody = {
    usuario: usuario,
    sesion: sesion,
    link: link,
    origen: origen
  }
  const { data } = await citaInstantaneaApi.delete("/borrarArchivo", { data: requestBody });
  return data;
}
export async function getArchivosSubidos(
  usuario: string,
  sesion: string
): Promise<Array<IArchivoSubido>> {
  const { data } = await citaInstantaneaApi.get<Array<IArchivoSubido>>(
    `/obtenerArchivosSesion?usuario=${usuario}&sesion=${sesion}`
  );
  return data;
}
export async function checkCitaRecibida(
  usuarioSesion: IUsuarioSesion
): Promise<ICheckCitaAsignadaResponse> {
  const { data } = await citaInstantaneaApi.post<ICheckCitaAsignadaResponse>(
    `/CheckCitaRecibida`,
    usuarioSesion
  );
  return data;
}
export async function recibirCita(
  usuarioSesion: IUsuarioSesion
): Promise<string> {
  const { data } = await citaInstantaneaApi.post<string>(
    `/RecibirCita`,
    usuarioSesion
  );
  return data;
}
export async function cancelarCita(
  usuarioSesion: IUsuarioSesion
): Promise<string> {
  const { data } = await citaInstantaneaApi.post<string>(
    `/cancelarCitaEspontanea`,
    usuarioSesion
  );
  return data;
}
export async function devolverSalaEspera(
  usuarioSesion: IUsuarioSesion
): Promise<string> {
  const { data } = await citaInstantaneaApi.post<string>(
    `/DevolverAEspera`,
    usuarioSesion
  );
  return data;
}
export async function verDatosCita(
  usuarioSesion: IUsuarioSesion
): Promise<IResultadoObtenerCitaSiguiente> {
  const { data } = await citaInstantaneaApi.get<IResultadoObtenerCitaSiguiente>(
    `/VerDatosCita?usuario=${usuarioSesion.usuario}&sesion=${usuarioSesion.sesion}`
  );
  return data;
}
export async function registrarEgresoPaciente(
  usuarioSesion: IUsuarioSesion
): Promise<string> {
  const { data } = await citaInstantaneaApi.post<string>(
    `/UltimoEgresoPaciente`, usuarioSesion
  );
  return data;
}
export async function registrarIngresoPaciente(
  registroIngreso: IPostRegistrarIngresoPaciente
): Promise<string> {
  const { data } = await citaInstantaneaApi.post<string>(
    `/UltimoIngresoPaciente`, registroIngreso
  );
  return data;
}

export async function estaLibreParaInstantaneas(medico: string) {
  const { data } = await citaInstantaneaApi.get<boolean>(`libreInstantanea?profesional=${medico}`);
  return data;
}

export async function setHorarioAtencion(inicio: Date, fin: Date) {
  console.log('inicio: ', inicio,'  fin: ', fin)
  const horaInicio = convertDateToDiaHora(inicio).horas;
  const horaFin = convertDateToDiaHora(fin).horas;
  const { data } = await citaInstantaneaApi.put<string>(`setHorarioAtencion?horaInicio=${horaInicio}&horaFin=${horaFin}`);
  return data;
}

export async function getHorarioAtencion() {
  const { data } = await citaInstantaneaApi.get<IHoraInicioFin>(`getHorarioAtencion`);
  return data;
}

export async function obtenerCitasEspontaneasEnEspera() {
  const { data } = await citaInstantaneaApi.get<Array<IConsultaEnEspera>>(`obtenerCitasEspontaneasEnEspera`);
  return data;
}

export async function cargarEncuestaPaciente(usuarioSesion: IUsuarioSesion, califMedico: number, califPlatf: number, comentMedico: string, comentPlatf: string) {
  const obj = {
    usuario: usuarioSesion.usuario,
    sesion: usuarioSesion.sesion,
    preguntas: [
      {
        pregunta: "Calificá el profesional",
        nota: comentMedico,
        calificacion: califMedico
      },
      {
        pregunta: "Por favor, calificá la plataforma",
        nota: comentPlatf,
        calificacion: califPlatf
      }
    ]
  }
  const { data } = await citaInstantaneaApi.post(`cargarEncuesta`, obj);
  return data;
}
export async function cargarEncuestaMedico(emailMedico: string, sesion: string, califPaciente: number, califPlatf: number, comentPaciente: string, comentPlatf: string) {
  const obj = {
    usuario: emailMedico,
    sesion: sesion,
    preguntas: [
      {
        pregunta: "Califique al paciente",
        nota: comentPaciente,
        calificacion: califPaciente
      },
      {
        pregunta: "Por favor, calificá la plataforma",
        nota: comentPlatf,
        calificacion: califPlatf
      }
    ]
  }
  const { data } = await citaInstantaneaApi.post(`cargarEncuesta`, obj);
  return data;
}
export async function obtenerCCitasAtendidasDia(emailMedico: string, fecha: Date) {
  const { data } = await citaInstantaneaApi.get(`obtenerCCitasAtendidasDia?profesional=${emailMedico}&fecha=${convertDateToDatestring(fecha)}`);
  return data;
}
export async function obtenerResumenCitasAtendidas(fecha: Date) {
  const { data } = await citaInstantaneaApi.get<Array<IEstadisticasMedicoGuardia>>(`ObtenerResumenCitasAtendidas?fecha=${convertDateToDatestring(fecha)}`);
  return data;
}

export async function setFinalizarBtn(cita: Object): Promise<boolean> {
  const body = {
    tipo: 'Instantanea',
    cita: cita
  }
  const { data } = await citaInstantaneaApi.post(`/setFinalizarBtn`, body);
  return data
 
}

//export default pacientesApi;
