import React from 'react'

import { Box, Grid, useTheme } from '@material-ui/core';

import usuario from '../../../src/assets/images/usuario.png';
import { IPaciente } from '../../Interfaces/IPaciente';

interface PublicProps {
    paciente: IPaciente;
}

const PerfilPaciente: React.FC<PublicProps> = (props) => {
    const { paciente } = props
    const theme = useTheme();

    return (
        <Grid container alignItems="center" >
            <Grid item >
                <Box mx={3}>
                    <img src={usuario} alt="paciente" width="130px" />
                </Box>
            </Grid>
            <Grid item >
                <p style={{ color: theme.paletaColores.naranja.tercero }}><b>{paciente.NOMBRE + " " + paciente.APELLIDO}</b></p>
                <p>{paciente.EDAD}</p>
                <p>CREDENCIAL {paciente.NRO_CREDENCIAL}</p>
            </Grid>
        </Grid>
    )
}

export default PerfilPaciente as React.ComponentType<PublicProps>