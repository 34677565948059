import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import ConfirmarTurno from '../../../pages/Paciente/ConsultasProgramadas/ConfirmarTurno/ConfirmarTurno';
import SeleccionarEspecialidad from '../../../pages/Paciente/ConsultasProgramadas/SeleccionarEspecialidad/SeleccionarEspecialidad';
import SeleccionarPaciente from '../../../pages/Paciente/ConsultasProgramadas/SeleccionarPaciente/SeleccionarPaciente';
import SeleccionarTurno from '../../../pages/Paciente/ConsultasProgramadas/SeleccionarTurno/SeleccionarTurno';
import SalaEspera from '../../../pages/Paciente/ConsultasProgramadas/SalaEspera/SalaEspera';
import LlamadoConsultorio from '../../../pages/Paciente/ConsultasProgramadas/LlamadoConsultorio/LlamadoConsultorio';
import Consultorio from '../../../pages/Paciente/ConsultasProgramadas/ConsultorioPacienteProgramadas/ConsultorioPacienteProgramadas';

const ConsultasProgramadas = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Redirect from={path} to={`${url}/seleccionar-paciente`} exact />
            <PrivateRoute component={SeleccionarPaciente} path={`${path}/seleccionar-paciente`} />
            <PrivateRoute component={SeleccionarEspecialidad} path={`${path}/seleccionar-especialidad`} />
            <PrivateRoute component={SeleccionarTurno} path={`${path}/seleccionar-turno`} />
            <PrivateRoute component={ConfirmarTurno} path={`${path}/confirmar-turno`} />
            <PrivateRoute component={SalaEspera} path={`${path}/sala-espera`} />
            <PrivateRoute component={LlamadoConsultorio} path={`${path}/llamado`} />
            <PrivateRoute component={Consultorio} path={`${path}/consultorio`} />
        </Switch>
    )
}

export default ConsultasProgramadas