import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { fetchPacientes } from '../../../apis/pacientesAPI'
import { IPaciente } from '../../../Interfaces/IPaciente';
import { fetchEventosCalendario } from '../../../apis/calendarioAPI';
import { IResultadoListaEventosCalendario } from '../../../Interfaces/IResultadoListaEventosCalendario';
import { ISintomaPreguntasRespuestas } from '../../../Interfaces/ISintomaPreguntasRespuestas';

interface IverMedicoOnlineState {
    especialidad: string;
    idSalaVideollamada: string;
    passSalaVideollamada: string;
    paso: number;
    error: string | null;
    sintomaGuardado: ISintomaPreguntasRespuestas | undefined;
    tiempoEsperaPromedio: number;
}

let initialState: IverMedicoOnlineState = {
    especialidad: "",
    idSalaVideollamada: "",
    passSalaVideollamada: "",
    paso: 0,
    error: null,
    sintomaGuardado: undefined,
    tiempoEsperaPromedio:0
}

const verMedicoOnlineSlice = createSlice({
    name: "verMedicoOnline",
    initialState,
    reducers: {
        setPaso(state, action: PayloadAction<number>) {
            state.paso = action.payload
        },
        getEventosCalendarioSuccess(state, action: PayloadAction<IResultadoListaEventosCalendario>) {

            action.payload.items.forEach(evento => {
                /*
                state.pacientesByCuil[evento.CUIL] = evento;
                state.cuilsDePacientes.push(evento.CUIL);
                */
            })

            state.error = null
        },
        getEventosCalendarioFailed(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        setEspecialidad(state, action: PayloadAction<string>) {
            state.especialidad = action.payload
        },
        setIdSalaVideollamada(state, action: PayloadAction<string>) {
            state.idSalaVideollamada = action.payload
        },
        setPassSalaVideollamada(state, action: PayloadAction<string>) {
            state.passSalaVideollamada = action.payload
        },
        setSintomaGuardado(state, action: PayloadAction<ISintomaPreguntasRespuestas | undefined>) {
            state.sintomaGuardado = action.payload
        },
        setTiempoEsperaPromedio(state, action: PayloadAction<number>) {
            state.tiempoEsperaPromedio = action.payload
        },
    }
});


export const {
    setPaso,
    setEspecialidad,
    setIdSalaVideollamada,
    setPassSalaVideollamada,
    getEventosCalendarioSuccess,
    getEventosCalendarioFailed,
    setSintomaGuardado,
    setTiempoEsperaPromedio,
} = verMedicoOnlineSlice.actions;

export default verMedicoOnlineSlice.reducer;

export function getEventosCalendario(mailProfesional: string): AppThunk {
    return async (dispatch) => {
        try {
            const eventosCalendario = await fetchEventosCalendario(mailProfesional);
            return eventosCalendario;
        } catch (err) {
            return null;
        }
    };
}