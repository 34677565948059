import React, { useEffect, useState } from 'react'
import { Card, CardHeader, Avatar, Typography, CardContent, Button, useTheme, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid } from '@material-ui/core';
import { CalendarTodayOutlined, EventBusyOutlined } from '@material-ui/icons';
import { ITurnoAgendado } from '../../Interfaces/ITurnoAgendado';
import { convertDateToLocaleLong, convertDiaHoraToDate } from '../../utils/dateTimeHelper';
import { useHistory } from 'react-router-dom';
import { useTime } from '../../hooks/useTime';
import { useDispatch, useSelector } from 'react-redux';
import { setCuilPacienteSeleccionado } from '../../store/user/userSlice';
import { setIdSalaVideollamada } from '../../store/paciente/consultas-programadas/consultasProgramadasSlice';
import { RootState } from '../../store/rootReducer';
import { cancelarCita } from '../../apis/citaProgramadaAPI';
import LoadingBubbles from '../LoadingBubbles/LoadingBubbles';
import CardArchivoSubido from '../CardArchivoSubido/CardArchivoSubido';
import { borrarArchivo } from '../../apis/citaInstantaneaAPI';
import FileUploadModule from '../FileUploadModule/FileUploadModule';
import { IArchivoSubido } from '../../Interfaces/IArchivoSubido';
import usuario from '../../assets/images/usuario.png';

interface OwnProps {
    turno: ITurnoAgendado;
}

type PublicProps = OwnProps
type Props = PublicProps

const CardTurnoAgendado: React.FC<Props> = (props) => {
    const { turno } = props
    const dateTime = convertDiaHoraToDate(turno.turno);
    const theme = useTheme();
    const now = useTime(60000);
    const history = useHistory();
    const dispatch = useDispatch();
    const [archivosSubidos, setArchivosSubidos] = useState<Array<IArchivoSubido> | undefined>(turno.archivos);

    const [cancelarDialogOpen, setCancelarDialogOpen] = useState(false);
    const [turnoVencido, setTurnoVencido] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cancelada, setCancelada] = useState(false);

    const { cuilPacienteSeleccionado } = useSelector(
        (state: RootState) => state.user
    )
    const onIrSalaEspera = () => {
        dispatch(setIdSalaVideollamada(turno.sesion));
        dispatch(setCuilPacienteSeleccionado(cuilPacienteSeleccionado));

        history.push("/dashboard/consultas-programadas/sala-espera")
    }
    const onCancelarTurno = () => {
        dispatch(setIdSalaVideollamada(turno.sesion));
        dispatch(setCuilPacienteSeleccionado(cuilPacienteSeleccionado));

        history.push("/dashboard/consultas-programadas/sala-espera")
    }
    const turnoDate = convertDiaHoraToDate(turno.turno);
    const difference = (turnoDate.getTime() - now.getTime()) / (1000 * 60);
    const puedeIrSalaEspera = Math.abs(difference) <= 20;
    useEffect(() => {
        if (turno.estado === 'Vencida') {
            setTurnoVencido(true);
        }
    }, [turno])

    if (loading) {
        return (
            <LoadingBubbles />
        )
    }
    if (cancelada) {
        return (
            <Card style={{ margin: "5px 5px 0px 5px" }} >
                <CardHeader
                    avatar={<Avatar><EventBusyOutlined /></Avatar>}
                    title={`Turno de ${turno.especialidad} cancelado`}
                    subheader={convertDateToLocaleLong(dateTime)}
                />
            </Card>
        )
    }
    return (
        <>
            <Card elevation={3} style={{ margin: "5px 5px 0px 5px" }} >
                <CardHeader
                    avatar={<Avatar><CalendarTodayOutlined /></Avatar>}
                    title={`Turno de ${turno.especialidad}`}
                    subheader={convertDateToLocaleLong(dateTime)}
                    action={
                        <>
                            {
                                puedeIrSalaEspera &&
                                <Button
                                    onClick={onIrSalaEspera}
                                    variant="contained"
                                    disableElevation
                                    style={{ ...theme.buttons.okButton, margin: 3 }}
                                >
                                    Ir a sala de espera
                                </Button>

                            }
                            {
                                !turnoVencido ?
                                    <Button
                                        onClick={() => { setCancelarDialogOpen(true) }}
                                        variant="contained"
                                        disableElevation
                                        style={{ ...theme.buttons.cancelButton, margin: 3 }}>
                                        Cancelar turno
                                </Button>
                                    :
                                    <Button
                                        onClick={() => { }}
                                        variant="contained"
                                        disableElevation
                                        disabled
                                        style={{ ...theme.buttons.cancelButton, margin: 3 }}>
                                        Turno Vencido
                                </Button>
                            }

                        </>
                    }
                />
                <CardContent>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                        <div style={{ width: 90, height: 80 }} >
                            <div style={{ width: 70, height: 70, margin: 5, overflow: "clip", borderRadius: 10 }}><img src={turno.photoURL? turno.photoURL : usuario} alt="imagen medico" height="100%" /></div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <Typography variant="h6" style={{ color: theme.paletaColores.naranja.tercero }}>
                                {`Profesional: ${turno.nombreProfesional}`}
                            </Typography>
                            <Typography>
                                {`Matricula: ${turno.matriculaProfesional}`}
                            </Typography>
                            <Typography>
                                {`Motivo: ${turno.motivo !== "" ? turno.motivo : "(Sin especificar)"}`}
                            </Typography>
                        </div>
                    </div>
                    <>
                        {
                            !turnoVencido ?
                                <Card variant="outlined" style={{ marginTop: 10 }}>
                                    <CardContent>
                                        <Typography variant="body1" style={{ color: theme.paletaColores.naranja.tercero }}>
                                            {"Archivos adjuntos al turno"}
                                        </Typography>
                                        <Grid container>
                                            {
                                                archivosSubidos && archivosSubidos!.length > 0 &&
                                                (
                                                    <>
                                                        {
                                                            archivosSubidos!.map((archivo, iArchivo) => {
                                                                return (
                                                                    <Grid item xs={12} md={6} key={iArchivo}>
                                                                        <CardArchivoSubido
                                                                            fileName={archivo.file}
                                                                            description={archivo.descripcion}
                                                                            downloadLink={archivo.linkArchivo}
                                                                            canDelete={true}
                                                                            onDelete={() => {
                                                                                setLoading(true);
                                                                                borrarArchivo(turno.sesion, cuilPacienteSeleccionado.toString(), archivo.linkArchivo, "paciente")
                                                                                    .then((datosRecibidos) => {
                                                                                        //aca sacar el archivo de la lista
                                                                                        var temp = [...archivosSubidos];
                                                                                        temp = temp.splice(iArchivo, 1);
                                                                                        setArchivosSubidos(temp);
                                                                                        setLoading(false);
                                                                                    })
                                                                                    .catch(e => { console.log(e) })
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                            <Grid item xs={12} md={6} >
                                                <div style={{ margin: "5px 5px 0px 5px" }}>
                                                    <FileUploadModule
                                                        usuario={cuilPacienteSeleccionado.toString()}
                                                        sesion={turno.sesion}
                                                        onCompletedUploading={(archivoNuevo) => {
                                                            var temp: Array<IArchivoSubido> = archivosSubidos ? [...archivosSubidos!] : [];
                                                            temp.push(archivoNuevo);
                                                            setArchivosSubidos(temp);
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                : null
                        }
                    </>

                </CardContent>
            </Card>
            <Dialog open={cancelarDialogOpen}>
                <DialogContent>
                    <DialogContentText>
                        <Typography>
                            ¿Desea cancelar la cita?
                    </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={() => {
                            setLoading(true);
                            cancelarCita({ usuario: cuilPacienteSeleccionado.toString(), sesion: turno.sesion })
                                .then(
                                    () => {
                                        setLoading(false);
                                        setCancelarDialogOpen(false);
                                        setCancelada(true);
                                    }
                                )
                                .catch(e => { console.log(e) })
                        }}
                    >
                        Si
                    </Button>
                    <Button color="primary" variant="contained" disableElevation onClick={() => { setCancelarDialogOpen(false) }}>
                        No
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default CardTurnoAgendado as React.ComponentType<PublicProps>
