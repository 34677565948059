import React, { useState } from 'react'
import { List, ListItem, Divider, WithStyles, withStyles, useTheme, Typography, Avatar, Card, CardHeader, CardContent, ListItemText, Button, TextField, InputAdornment, Grid, Chip, ListItemSecondaryAction, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CardActionArea, CardMedia } from '@material-ui/core';
import { styles } from './MenuAdministrarGuardiaStyles';
import { SettingsOutlined, VideocamOutlined, PieChartOutlined } from '@material-ui/icons';
import Volver from '../../../../components/buttons/Volver/Volver';
import { useHistory } from 'react-router';
import { IServicio } from '../../../../Interfaces/IServicio';


const servicios: Array<IServicio> = [
    {
        id: 'ESTADO',
        primaryText: "Estado de la guardia",
        secondaryText: "Ver en tiempo real estado de la guardia, pacientes en espera, profesionales atendiendo, etc...",
        icon: VideocamOutlined,
        to: "/dashboard/admin/administrar-guardia/estado"
    },
    {
        id: 'ESTADISTICAS',
        primaryText: "Estadisticas de la guardia",
        secondaryText: "Consultar estadísticas de atención de los profesionales que atienden en la guardia online.",
        icon: PieChartOutlined,
        to: "/dashboard/admin/administrar-guardia/estadisticas"
    },
]

const MenuAdministrarGuardia: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const history = useHistory();

    const onItemClicked = (servicio: IServicio) => {
        history.push(servicio.to)
    }

    return (
        <>
            <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                <CardHeader
                    avatar={<Avatar><SettingsOutlined /></Avatar>}
                    title={<Typography variant="h6">Administración de Guardias Médicas</Typography>}
                />
                <Divider />
                <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                    <Grid container spacing={2}>
                        {
                            servicios.map((servicio, iServicio) => {
                                return (
                                    <Grid item xs={12} lg={6} key={iServicio}>
                                        <Card variant="outlined">
                                            <CardActionArea onClick={() => { onItemClicked(servicio) }}>
                                                <CardHeader
                                                    title={
                                                        <Typography style={{ fontWeight: "bold", color: theme.paletaColores.naranja.tercero }}>
                                                            {servicio.primaryText}
                                                        </Typography>
                                                    }
                                                    avatar={
                                                        <Avatar>
                                                            <servicio.icon />
                                                        </Avatar>
                                                    }
                                                    subheader={servicio.secondaryText}
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </CardContent>
            </Card>
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={() => { history.goBack() }} />
            </div>
        </>
    )
}

export default withStyles(styles)(MenuAdministrarGuardia)
