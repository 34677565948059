import React, { useState } from 'react';
import { Divider, WithStyles, withStyles, Toolbar, useTheme, Typography, Avatar, Card, CardHeader, CardContent, Button, Collapse, TextField, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { styles } from './ContainerAdministrarCalificacionProfStyles';
import Volver from '../../../../components/buttons/Volver/Volver';
import { InsertChartOutlinedRounded, ReplayOutlined, KeyboardArrowUpOutlined, KeyboardArrowDownOutlined, SearchOutlined, ArrowForwardIosOutlined, CloseOutlined } from '@material-ui/icons';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { obtenerValoracionProfProm20 } from '../../../../apis/valoracionAPI';
import { convertDatestringToDate, convertDatestringToLocaleShortDateOnly, _getDateAsIs } from '../../../../utils/dateTimeHelper';
import { IProfesionalPromedio } from '../../../../Interfaces/IProfesionalPromedio';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import ValoracionesPorPaciente from '../ValoracionesPorPaciente/ValoracionesPorPaciente';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { parse } from 'uuid';



const ContainerAdministrarCalificacionProf: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const theme = useTheme();

    const [medicos, setMedicos] = React.useState<Array<IProfesionalPromedio>>([]);
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(-1);

    const closeDetails = () => {
        setDetailsOpen(false);
        setSelectedId(-1);
    }

    const selectedMedico = selectedId >= 0 ? medicos[selectedId] : undefined;

    const obtenerTodos = () => {
        setFechaBusqueda(null);
        setApellidoBusqueda("");
        setMinBusqueda(undefined);
        setMaxBusqueda(undefined);

        setLoading(true);
        obtenerValoracionProfProm20()
            .then((response) => {
                let temp: Array<Row> = [];
                response.forEach((prof, iProf) => {
                    let row: Row = {
                        id: iProf,
                        valoracion: prof.promedio,
                        profesional: prof.profesional,
                        ult_calif: _getDateAsIs(prof.ultimaValoracion),
                    }
                    temp.push(row);
                })
                setRows(temp);
                setMedicos(response);
                setLoading(false);
            })
            .catch(e => { console.log(e); })
    }

    const obtenerFiltrados = () => {
        setLoading(true);
        obtenerValoracionProfProm20(apellidoBusqueda, minBusqueda, maxBusqueda)
            .then((response) => {
                let temp: Array<Row> = [];
                response.forEach((prof, iProf) => {
                    let row: Row = {
                        id: iProf,
                        valoracion: prof.promedio,
                        profesional: prof.profesional,
                        ult_calif: _getDateAsIs(prof.ultimaValoracion),
                    }
                    temp.push(row);
                })
                setRows(temp);
                setMedicos(response);
                setLoading(false);
            })
            .catch(e => { console.log(e); })
    }

    React.useEffect(() => {
        obtenerTodos();
    }, [])

    const renderDetailsButton = (params: GridRenderCellParams) => {
        const open = () => {
            const id = params.getValue(params.id, 'id') as number;
            const medico = medicos[id];

            setDetailsOpen(true);
            setSelectedId(id);
        }
        return (
            <Button onClick={open} fullWidth endIcon={<ArrowForwardIosOutlined />}>
                {`Ver`}
            </Button>
            // <strong>
            //     <Link to='/dashboard/admin/administrar-valoraciones-paciente/administrar-valoraciones-paciente'>
            //     </Link>
            // </strong>
        )
    }

    const [filtros, setFiltros] = useState(false)

    const columns = [
        { field: 'id', headerName: 'ID', width: 50, minWidth: 50 },
        {
            field: 'profesional',
            headerName: 'Profesional',
            width: 250,
            flex: 200,
        },
        {
            field: 'valoracion',
            headerName: 'Valoracion',
            width: 150,
            minWidth: 150,
        },
        {
            field: 'ult_calif',
            headerName: 'Ultima calificación',
            minWidth: 200,
            width: 200,
        },
        {
            field: 'calificaciones',
            headerName: 'Calificaciones',
            renderCell: renderDetailsButton,
            minWidth: 200,
            width: 200,
        },
    ];

    interface Row {
        id: number;
        profesional: string;
        valoracion: number;
        ult_calif: string;
    }

    const [rows, setRows] = React.useState<Array<Row>>([]);

    //filtros
    const [fechaBusqueda, setFechaBusqueda] = React.useState<Date | null>(null);
    const [apellidoBusqueda, setApellidoBusqueda] = React.useState<string>("");
    const [minBusqueda, setMinBusqueda] = React.useState<number | undefined>(undefined);
    const [maxBusqueda, setMaxBusqueda] = React.useState<number | undefined>(undefined);

    // const rows = [
    //     { id: 1, profesional: 'Snow', valoracion: 4.5, ult_calif: 30, calificaciones: '' },
    //     { id: 2, profesional: 'Lannister', valoracion: 5, ult_calif: 20, calificaciones: '' },
    // ];

    return (
        <>
            <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                <CardHeader
                    avatar={<Avatar><InsertChartOutlinedRounded /></Avatar>}
                    title={<Typography variant="h6">Valoracion de profesionales</Typography>}
                    action={<>

                        <Button disabled={loading} onClick={obtenerTodos} aria-label="settings" variant="contained" disableElevation startIcon={<ReplayOutlined />}>
                            Actualizar
                        </Button>
                        <Button
                            style={{ margin: 5 }}
                            variant="contained"
                            disableElevation
                            onClick={() => { setFiltros(!filtros) }}
                            endIcon={filtros ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                        >
                            Filtros
                        </Button>
                    </>
                    }
                />
                <Divider />
                <Collapse in={filtros} timeout="auto" unmountOnExit>
                    <Toolbar >
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                size="small"
                                format="dd/MM/yyyy"
                                label="Fecha solicitud"
                                value={fechaBusqueda}
                                onChange={(event) => {
                                    setFechaBusqueda(event as Date)
                                }}
                                inputVariant="outlined"
                                style={{ margin: 5, width: 170 }}
                                defaultValue={null}
                                disableFuture
                                invalidDateMessage="Fecha inválida"
                                maxDateMessage="La fecha debe ser anterior a hoy"
                                InputAdornmentProps={{ style: { visibility: "hidden" } }}
                            />
                        </MuiPickersUtilsProvider> */}
                        <TextField
                            // value={especialidad}
                            label="Apellido Profesional"
                            // onChange={(event) => {
                            //     setEspecialidad(event.target.value as string);
                            // }}
                            variant="outlined"
                            style={{ margin: 5, maxWidth: 300 }}
                            size="small"
                            value={apellidoBusqueda}
                            onChange={(event) => {
                                setApellidoBusqueda(event.target.value as string);
                            }}
                        />
                        <TextField
                            // value={operador}
                            label="Valoración Min."
                            // onChange={(event) => {
                            //     setOperador(event.target.value as string);
                            // }}
                            variant="outlined"
                            style={{ margin: 5, maxWidth: 150 }}
                            size="small"
                            type="number"
                            required={maxBusqueda !== undefined}
                            value={minBusqueda? minBusqueda : ""}
                            error={maxBusqueda !== undefined && minBusqueda === undefined}
                            onChange={(event) => {
                                if (event.target.value === "") {
                                    setMinBusqueda(undefined);
                                    return;
                                }
                                var newValue = Number.parseFloat(event.target.value);
                                if (newValue > 5) return;
                                if (newValue < 0) return;
                                if (maxBusqueda !== undefined && newValue > maxBusqueda) return;
                                setMinBusqueda(newValue);
                            }}
                        />
                        <TextField
                            // value={operador}
                            label="Valoración Max."
                            // onChange={(event) => {
                            //     setOperador(event.target.value as string);
                            // }}
                            variant="outlined"
                            style={{ margin: 5, maxWidth: 150 }}
                            size="small"
                            type="number"
                            required={minBusqueda !== undefined}
                            value={maxBusqueda? maxBusqueda : ""}
                            error={minBusqueda !== undefined && maxBusqueda === undefined}
                            onChange={(event) => {
                                if (event.target.value === "") {
                                    setMaxBusqueda(undefined);
                                    return;
                                }
                                var newValue = Number.parseFloat(event.target.value);
                                if (newValue > 5) return;
                                if (newValue < 0) return;
                                if (minBusqueda !== undefined && newValue < minBusqueda) return;
                                setMaxBusqueda(newValue);
                            }}
                        />
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            // disabled={loading}
                            // onClick={() => { onBuscar(true) }}
                            variant="contained"
                            color="primary"
                            disableElevation
                            endIcon={<SearchOutlined />}
                            disabled={loading}
                            onClick={obtenerFiltrados}
                        >
                            {`BUSCAR`}
                        </Button>
                    </Toolbar>
                </Collapse>
                <Divider />
                <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                    <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                        <CardContent>
                            {
                                loading ?
                                    <div style={{ marginTop: 100, marginBottom: 100 }}>
                                        <LoadingBubbles />
                                    </div>
                                    :
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={5}
                                            onRowClick={(params) => {
                                                const id = params.getValue(params.id, 'id') as number;
                                                // const medico = medicos[id];

                                                setDetailsOpen(true);
                                                setSelectedId(id);
                                            }}
                                        />
                                    </div>
                            }
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={() => { history.goBack() }} />
            </div>
            <Dialog fullWidth maxWidth="lg" open={detailsOpen} onClose={closeDetails}>
                <DialogTitle>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                            <div style={{ color: theme.paletaColores.naranja.segundo }}>
                                {selectedMedico?.profesional}
                            </div>
                            <Typography>
                                {`Valoración: ${selectedMedico?.promedio}`}
                            </Typography>
                        </div>
                        <div style={{ flexGrow: 1 }} />
                        <IconButton onClick={closeDetails}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <ValoracionesPorPaciente medico={selectedMedico!} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default withStyles(styles)(ContainerAdministrarCalificacionProf)
