import { IDiaHora } from "../Interfaces/IDiaHora"
import { IHorarioInicioFin } from "../Interfaces/IHorarioInicioFin";

export const convertDiaHoraToDate = (diaHora: IDiaHora) => {
    const fechaDatos = diaHora.día.split("/");
    const [year, month, dayOfMonth] = fechaDatos;

    const horaDatos = diaHora.horas.split(":");
    const [hour, minute] = horaDatos;

    return new Date(
        Number.parseInt(year),
        Number.parseInt(month) - 1,
        Number.parseInt(dayOfMonth),
        Number.parseInt(hour),
        Number.parseInt(minute)
    );
}

export const convertDatestringToDate = (datestring: string) => {
    const diaHora: IDiaHora = {
        día: datestring,
        horas: "00:00"
    }
    return convertDiaHoraToDate(diaHora);
}
export const convertDateToDatestring = (date: Date) => {
    const diaHora = convertDateToDiaHora(date);
    return diaHora.día;
}

export const convertTimestringToDate = (timestring: string) => {
    const diaHora: IDiaHora = {
        día: convertDateToDatestring(new Date()),
        horas: timestring
    }
    return convertDiaHoraToDate(diaHora);
}
export const convertDateToTimestring = (date: Date) => {
    //console.log("date",date)
    const diaHora = convertDateToDiaHora(date);
    return diaHora.horas;
}

export const convertDateToDiaHora = (date: Date) => {
    console.log("date",date)
    let year = date.getFullYear().toString();

    let month = (date.getMonth() + 1).toString();
    let dayOfMonth = (date.getDate()).toString();

    let hour = (date.getHours()).toString();
    let minute = (date.getMinutes()).toString();

    month = month.length === 2 ? month : "0" + month;
    dayOfMonth = dayOfMonth.length === 2 ? dayOfMonth : "0" + dayOfMonth;
    hour = hour.length === 2 ? hour : "0" + hour;
    minute = minute.length === 2 ? minute : "0" + minute;

    const diaHora: IDiaHora = {
        día: `${year}/${month}/${dayOfMonth}`,
        horas: `${hour}:${minute}`
    }

    return diaHora;
}

export const convertDatestringToLocaleShortDateOnly = (datestring: string) => {
    console.log(datestring);
    let date = convertDatestringToDate(datestring);
    console.log(date);
    let ret = convertDateToLocaleShortDateOnly(date);
    console.log(ret);
    return ret;
}

export const convertDateToLocaleLong = (date: Date) => {
    return date.toLocaleString("es-AR", { day: "numeric", month: "long", year: "numeric", weekday: "long", hour: "numeric", minute: "numeric" });
}
export const convertDateToLocaleShort = (date: Date) => {
    console.log(date);
    return date.toLocaleString("es-AR", { day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric" });
}
export const convertDateToLocaleLongDateOnly = (date: Date) => {
    return date.toLocaleString("es-AR", { day: "numeric", month: "long", year: "numeric", weekday: "long" });
}
export const convertDateToLocaleShortDateOnly = (date: Date) => {
    return date.toLocaleString("es-AR", { day: "numeric", month: "numeric", year: "numeric" });
}
export const convertDiaHoraToLocaleLong = (diaHora: IDiaHora) => {
    return convertDateToLocaleLong(convertDiaHoraToDate(diaHora));
}

export const convertSecondsToLocaleHMS = (seconds: number) => {
    seconds = Math.ceil(seconds);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    let legible = `${seconds}s`;
    if (minutes > 0) {
        legible = `${minutes}m ${legible}`;
    }
    if (hours > 0) {
        legible = `${hours}h ${legible}`;
    }
    return legible;
}

export const checkHorariosIntersectan = (a: IHorarioInicioFin, b: IHorarioInicioFin) => {
    const inicioA = convertDiaHoraToDate({ horas: a.inicio!, día: convertDateToDatestring(new Date()) });
    const finA = convertDiaHoraToDate({ horas: a.fin!, día: convertDateToDatestring(new Date()) });
    const inicioB = convertDiaHoraToDate({ horas: b.inicio!, día: convertDateToDatestring(new Date()) });
    const finB = convertDiaHoraToDate({ horas: b.fin!, día: convertDateToDatestring(new Date()) });

    const primeroEsA = inicioA.getTime() < inicioB.getTime();

    if (primeroEsA) {
        if (inicioB.getTime() < finA.getTime()) {
            return true;
        }
    } else {
        if (inicioA.getTime() < finB.getTime()) {
            return true;
        }
    }
    return false;
}

export const checkListHorariosIntersectan = (horarios: Array<IHorarioInicioFin>) => {
    for (let i = 0; i < horarios.length; i++) {
        const horarioA = horarios[i];
        for (let j = i + 1; j < horarios.length; j++) {
            const horarioB = horarios[j];
            if (checkHorariosIntersectan(horarioA, horarioB)) {
                return true;
            }
        }
    }
    return false;
}

export function _calculateAge(dateOfBirth: Date) { // birthday is a date
    var ageDifMs = Date.now() - dateOfBirth.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
}

export function _getDateAsIs(date: string) {
    date = date.slice(0, 10);
    let dateParts = date.split("-");
    dateParts = dateParts.reverse();
    return `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`;
}

export function _calculateAgeDetailed(dob: Date) {
    var now = new Date();

    var yearNow = now.getFullYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var yearDob = dob.getFullYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";


    let yearAge = yearNow - yearDob;

    if (monthNow >= monthDob)
        var monthAge = monthNow - monthDob;
    else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
        var dateAge = dateNow - dateDob;
    else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    let age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
    };

    if (age.years > 1) yearString = " años";
    else yearString = " año";
    if (age.months > 1) monthString = " meses";
    else monthString = " mes";
    if (age.days > 1) dayString = " días";
    else dayString = " día";


    if ((age.years > 0) && (age.months > 0) && (age.days > 0))
        ageString = age.years + yearString + ", " + age.months + monthString + ", y " + age.days + dayString;
    else if ((age.years === 0) && (age.months === 0) && (age.days > 0))
        ageString = age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days === 0))
        ageString = age.years + yearString;
    else if ((age.years > 0) && (age.months > 0) && (age.days === 0))
        ageString = age.years + yearString + " y " + age.months + monthString;
    else if ((age.years === 0) && (age.months > 0) && (age.days > 0))
        ageString = age.months + monthString + " y " + age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days > 0))
        ageString = age.years + yearString + " y " + age.days + dayString;
    else if ((age.years === 0) && (age.months > 0) && (age.days === 0))
        ageString = age.months + monthString;
    else ageString = "error al calcular la edad";

    return ageString;
}