import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEspecialidad } from '../../Interfaces/IEspecialidad';
import { IUser } from '../../Interfaces/IUser';
import { io, Socket } from 'socket.io-client';
import { fetchPacientes } from '../../apis/pacientesAPI';
import { AppThunk } from '../store';
import { IPaciente } from '../../Interfaces/IPaciente';

let initialState: IUser = {
    // nombre: '',
    // apellido: '',
    // email: '',
    // rol: null,
    // isUserLogin: false,
    // warningSarsCov2: false,
    // sesionLogeo: '',
    // log: false,
    NOMBRE: '',
    APELLIDO: '',
    email: '',
    rol: null,
    isUserLogin: false,
    warningSarsCov2: false,
    sesionLogeo: '',
    log: false,
    NRO_DOCUMENTO: '',
    pacientesByCuil: {},
    cuilsDePacientes: [],
    cuilPacienteSeleccionado: -1,
    error: null,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, { payload }: PayloadAction<IUser | null>) {
     
            if (payload) {
                state.NOMBRE = payload.NOMBRE
                state.APELLIDO = payload.APELLIDO
                state.email = payload.email
                state.rol = payload.rol
                state.isUserLogin = payload.isUserLogin
                state.NRO_DOCUMENTO = payload.NRO_DOCUMENTO;
                state.ID_GRUPO_FAMILIAR = payload.ID_GRUPO_FAMILIAR;
                state.especialidades = payload.especialidades;
                state.matricula = payload.matricula;
                state.NRO_CREDENCIAL = payload.NRO_CREDENCIAL;
                state.plan = payload.plan;
                state.warningSarsCov2 = payload.warningSarsCov2;
                state.sesionLogeo = payload.sesionLogeo;
            } else {
                state.NOMBRE = initialState.NOMBRE
                state.APELLIDO = initialState.APELLIDO
                state.rol = initialState.rol
                state.isUserLogin = initialState.isUserLogin
                state.warningSarsCov2 = false
            }

        },
        setWarningSarsCov2(state, { payload }: PayloadAction<boolean>) {
            state.warningSarsCov2 = payload;
        },
        setRol(state, { payload }: PayloadAction<"PACIENTE" | "MEDICO" | "ADMINISTRADOR">) {
            state.rol = payload
        },
        setSesionLogeo(state, { payload }: PayloadAction<string>) {
            if (payload === "" || payload === undefined) {
                console.log("borrando sesion logeo")
            } else {
                console.log("estableciendo sesion logeo: " + payload)
            }
            state.sesionLogeo = payload;
        },
        setSesionLogeoInstancia(state, { payload }: PayloadAction<any>) {
            state.sesionLogeoInstancia = payload;
        },
        setLog(state, { payload }: PayloadAction<boolean>) {
            state.log = payload;
        },
        setSaque(state, { payload }: PayloadAction<boolean>) {
            state.saque = payload;
        },
        getPacientesSuccess(state, action: PayloadAction<IPaciente[]>) {
            state.cuilsDePacientes = []
            action.payload.forEach(paciente => {
                state.pacientesByCuil[paciente.CUIL] = paciente;
                state.cuilsDePacientes.push(paciente.CUIL);
            })
            state.error = null
        },
        clearPacientes(state, action: PayloadAction) {
            state.cuilsDePacientes = [];
            state.pacientesByCuil = {};
        },
        getPacientesFailed(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        setCuilPacienteSeleccionado(state, action: PayloadAction<number>) {
            state.cuilPacienteSeleccionado = action.payload
        }
    }
})

export const { setUser, setWarningSarsCov2, setRol, setSesionLogeo, setSesionLogeoInstancia, setLog, setSaque, getPacientesFailed, getPacientesSuccess, clearPacientes, setCuilPacienteSeleccionado } = userSlice.actions

export default userSlice.reducer;

export const getPacientes = (ID_GRUPO_FAMILIAR: number): AppThunk => async dispatch => {
    try {
        console.log("ID_GRUPO_FAMILIAR",ID_GRUPO_FAMILIAR)
        const pacientes = await fetchPacientes(ID_GRUPO_FAMILIAR)
        dispatch(getPacientesSuccess(pacientes))
    } catch (err) {
        dispatch(getPacientesFailed(err.toString()))
    }
} 

export const resetPacientes = (): AppThunk => dispatch => {
    dispatch(clearPacientes());
}