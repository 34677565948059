import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Grid, Paper, Button,
    Typography, Dialog, DialogActions, DialogContent, DialogContentText, useTheme, Divider, DialogTitle, TextField
} from '@material-ui/core';


// import { RootState } from '../../../../../store/rootReducer';
import { cargarEncuestaPaciente, registrarEgresoPaciente, borrarArchivo } from '../../../../../apis/citaInstantaneaAPI'
import CardArchivoSubido from '../../../../../components/CardArchivoSubido/CardArchivoSubido';
import { IArchivoSubido } from '../../../../../Interfaces/IArchivoSubido';
import Jitsi from '../../../../../components/jitsi/Jitsi';
import Turn from '../../../../../components/turn/Turn';
import { getFirestore } from '../../../../../db';
// import LoadingBubbles from '../../../../../components/LoadingBubbles/LoadingBubbles';
import FileUploadModule from '../../../../../components/FileUploadModule/FileUploadModule';
import Rating from '@material-ui/lab/Rating';
// import { io } from 'socket.io-client';
import { useBeforeunload } from "react-beforeunload";
import { getCurrentTime } from '../../../../../hooks/useTime';

interface OwnProps {
    cuil: string;
    sesion: string;
    nombre: string;
    apellido: string;
    onFinalizar: () => void;
}

const ConsultorioPacienteFinochietto = (props: OwnProps) => {
    const history = useHistory();
    const theme = useTheme();
    const { cuil, sesion, nombre, apellido, onFinalizar } = props;

    const [dialogFinalizarOpen, setDialogFinalizarOpen] = useState<boolean>(false);
    // const [loading, setLoading] = useState(false);
    const [listaArchivosSubidos, setListaArchivosSubidos] = useState<IArchivoSubido[]>([]);
    const [listaArchivosProfesional, setListaArchivosProfesional] = useState<IArchivoSubido[]>([]);

    const onDeleteFile = (linkArchivo: string) => {
        borrarArchivo(sesion, cuil, linkArchivo, "paciente")
            .then((datosrecibidos) => {
                var temp = [...listaArchivosSubidos];
                temp = temp.filter(x => x.linkArchivo !== linkArchivo);
                setListaArchivosSubidos(temp);
            })
            .catch(e => { console.log(e); })
    }

    useBeforeunload((event) => {
        event.preventDefault();
    })

    const db = getFirestore();

    useEffect(
        () => {
            let unsubscribeFilesProfesional: () => void = db.collection("pacientes").doc(cuil).collection("Consultas").doc(sesion).collection("SubidoProfesional").onSnapshot(
                (archivosEnLaNube) => {
                    let archivosTemp: Array<IArchivoSubido> = [];
                    archivosEnLaNube.forEach((archivo) => {
                        let nombreArchivoParts: string[] = archivo.data().file.split("/");
                        let nombreArchivo = nombreArchivoParts[nombreArchivoParts.length - 1];
                        console.log(archivo.data().origen);
                        archivosTemp.push({
                            file: nombreArchivo,
                            descripcion: archivo.data().descripcion,
                            linkArchivo: archivo.data().linkArchivo ? archivo.data().linkArchivo : "",
                            fecha: archivo.data().fecha
                        });
                    });
                    setListaArchivosProfesional(archivosTemp);
                }
            );
        }, []
    );

    return (
        <Grid container spacing={1}>
            <Grid container item xs={12} md={8} lg={9} alignItems="flex-start" justify="flex-start" direction="column" >
                <Grid item style={{width:"100%"}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '70vh' }}>
                        <Jitsi
                            roomName={sesion}
                            displayName={`${nombre} ${apellido}`}
                            showHangup
                            onFinalizarBtn={onFinalizar}
                            showChat={true}
                            showFullscreen={true}
                            finochietto={true}
                        />
                    </div>
                </Grid>
                <Grid item style={{width:"100%"}}>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={() => { setDialogFinalizarOpen(true) }}
                        style={{ marginTop: 10, marginBottom: 10 }}
                        fullWidth
                    >
                        finalizar llamada
                        </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={4} lg={3} direction="column">
                <Grid item>
                    <Paper style={{ width: "100%", marginBottom: "80px" }}>
                        <Grid container direction="column" spacing={1} >
                            <Grid container item xs={12} style={{ padding: "10px" }} justify="center">
                                <FileUploadModule
                                    usuario={cuil}
                                    sesion={sesion}
                                    onCompletedUploading={
                                        (newFile) => {
                                            let temp = [...listaArchivosSubidos];
                                            temp.push(newFile);
                                            setListaArchivosSubidos(temp)
                                        }
                                    }
                                />
                            </Grid>
                        </Grid>
                        {listaArchivosSubidos.length === 0 && listaArchivosProfesional.length === 0 ? (<></>) : (
                            <Grid>
                                <Grid item container xs={12} justify-content="flex-start" direction="column" >
                                    <Divider />
                                    {listaArchivosSubidos.length === 0 ? null : (
                                        <>
                                            <Typography variant="h6" align="center" style={{ color: theme.paletaColores.naranja.tercero, marginTop: 10 }}>Archivos del Paciente</Typography>
                                            {listaArchivosSubidos.map((archivo, index) => {
                                                let { file, descripcion, linkArchivo } = archivo;
                                                return (
                                                    <CardArchivoSubido canDelete={true} onDelete={
                                                        () => {
                                                            onDeleteFile(linkArchivo);
                                                        }
                                                    } key={index} fileName={file} description={descripcion} downloadLink={linkArchivo} />
                                                )
                                            }
                                            )}
                                            <Divider />
                                        </>
                                    )}
                                    {listaArchivosProfesional.length === 0 ? null : (
                                        <>
                                            <Typography variant="h6" align="center" style={{ color: theme.paletaColores.naranja.tercero, marginTop: 10 }}>Archivos del Profesional</Typography>
                                            {listaArchivosProfesional.map((archivo, index) => {
                                                let { file, descripcion, linkArchivo } = archivo;
                                                return (
                                                    <CardArchivoSubido key={index} fileName={file} description={descripcion} downloadLink={linkArchivo} />
                                                )
                                            }
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item container xs={12} direction="column" style={{ flexGrow: 1, flex: 1 }} />
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={dialogFinalizarOpen} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText>
                        <Typography paragraph>¿Está seguro que desea finalizar la consulta?</Typography>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disableElevation onClick={onFinalizar}>
                        Si
                    </Button>
                    <Button variant="contained" disableElevation onClick={() => { setDialogFinalizarOpen(false) }}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid >
    )
}

export default ConsultorioPacienteFinochietto
