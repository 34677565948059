import React, { useState } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Divider, WithStyles, withStyles, useTheme, Grid, Typography, Avatar, Card, CardHeader, CardContent, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { styles } from './ConfirmarTurnoStyles';
import { RootState } from '../../../../store/rootReducer';
import Volver from '../../../../components/buttons/Volver/Volver';
import { AttachFileOutlined, CloseOutlined, DoneOutlined } from '@material-ui/icons';
import PerfilMedico from '../../../../components/PerfilMedico/PerfilMedico';
import { crearCitaProgramada } from '../../../../apis/citaProgramadaAPI';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { v4 as uuidv4 } from 'uuid';
import FileUploadModule from '../../../../components/FileUploadModule/FileUploadModule';
import { IArchivoSubido } from '../../../../Interfaces/IArchivoSubido';
import CardArchivoSubido from '../../../../components/CardArchivoSubido/CardArchivoSubido';
import { convertDiaHoraToDate, convertDiaHoraToLocaleLong } from '../../../../utils/dateTimeHelper';
import { borrarArchivo } from '../../../../apis/citaInstantaneaAPI';
import { useLoggedIn } from '../../../../hooks/useLoggedIn';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';

const ConfirmarTurno: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const theme = useTheme();


    const { especialidad, idProfesional, fechaTurno, horarioTurno, motivo, nombreMedico, matriculaMedico, imagenMedicoURL } = useSelector(
        (state: RootState) => state.consultasProgramadas
    )
    const { cuilPacienteSeleccionado, pacientesByCuil, email, ID_GRUPO_FAMILIAR } = useSelector(
        (state: RootState) => state.user
    )

    const paciente = pacientesByCuil[cuilPacienteSeleccionado];
    const nombreDelPaciente = `${paciente.NOMBRE} ${paciente.APELLIDO}`;
    const title = { color: theme.paletaColores.naranja.tercero };

    const { path, url } = useRouteMatch();

    const [turnoConfirmado, setTurnoConfirmado] = useState(path.includes("confirmado"));

    const [loading, setLoading] = useState(false);
    const [sesion, setSesion] = useState("");
    const [listaArchivos, setListaArchivos] = useState<Array<IArchivoSubido>>([])
    const onDeleteFile = (linkArchivo: string) => {
        borrarArchivo(sesion, cuilPacienteSeleccionado.toString(), linkArchivo, "paciente")
            .then((datosrecibidos) => {
                var temp = [...listaArchivos];
                temp = temp.filter(x => x.linkArchivo !== linkArchivo);
                setListaArchivos(temp);
            })
            .catch(e => { console.log(e); })
    }

    const error400 = useLocalStorage("Error400", 100);

    const opciones = [
        {
            id: 'CONFIRMAR',
            primaryText: "Confirmar el turno",
            icon: DoneOutlined,
            onClick: () => {
                setLoading(true);
                const sesion = uuidv4();
                setSesion(sesion);
                crearCitaProgramada({
                    usuario: cuilPacienteSeleccionado.toString(),
                    especialidad: especialidad,
                    profesional: idProfesional,
                    motivo: motivo,
                    turno: {
                        día: fechaTurno,
                        horas: horarioTurno
                    },
                    sesion: sesion,
                    password: "",
                    tipo: "virtual",
                    emailUsuario: email,
                    ID_GRUPO_FAMILIAR: ID_GRUPO_FAMILIAR as any,
                    dni: paciente.NRO_DOCUMENTO as any
                })
                    .then(
                        (mensaje) => {
                            setTurnoConfirmado(true);
                            setLoading(false);
                            console.log(mensaje);
                        }
                    )
                    .catch(
                        (e) => {
                            console.log(e)
                            //mostrar dialogo de mensaje de error
                        }
                    )
                //mostrar un mensaje TURNO CONFIRMADO, ir a la agenda de turnos
            }
        },
        {
            id: 'CANCELAR',
            primaryText: "Cancelar el turno",
            icon: CloseOutlined,
            onClick: () => {
                console.log("APRETASTE");
                //mostrar un dialogo ESTA SEGURO DE QUE DESEA CANCELAR?
                //al apretar si volver al dashboard
                history.push("/dashboard")
            }
        }
    ]

    const opcionesList = opciones.map((opcion, index) => (
        <Box key={index} borderBottom="1px solid #efefef" >
            <ListItem style={{ paddingBottom: 15, paddingTop: 15 }} button onClick={opcion.onClick} >
                <ListItemIcon>
                    <opcion.icon />
                </ListItemIcon>
                <ListItemText
                    primary={opcion.primaryText}
                />
                <ListItemSecondaryAction onClick={opcion.onClick}>
                    <IconButton edge="end" >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </Box>
    ))

    if (loading) {
        return (
            <div style={{ marginTop: "40vh" }}>
                <LoadingBubbles />
            </div>
        )
    }
    return (
        <>
            <Typography variant="h5">Confirmación del turno</Typography>
            <Divider />
            <Grid container style={{ marginTop: 20 }}>
                <Grid item xs />
                <Grid item xs={12} sm={10} md={8} lg={6} >
                    <Typography variant="h6" style={title}>Consultorio:</Typography>
                    <Box my={2}>
                        <PerfilMedico
                            nombreMedico={nombreMedico}
                            especialidades={[especialidad]}
                            matricula={matriculaMedico}
                            direccionImagen={imagenMedicoURL}
                        />
                    </Box>
                    <Typography variant="h6" style={title}>Paciente:</Typography>
                    <Typography variant="body1">{nombreDelPaciente}</Typography>
                    <Typography variant="h6" style={title}>Turno:</Typography>
                    <Typography variant="body1">{convertDiaHoraToLocaleLong({ día: fechaTurno, horas: horarioTurno })}</Typography>
                    {
                        motivo !== "" ?
                            (
                                <>
                                    <Typography variant="h6" style={title}>Motivo:</Typography>
                                    <Typography variant="body1">{motivo}</Typography>
                                </>
                            ) :
                            (
                                null
                            )
                    }
                    {
                        turnoConfirmado ?
                            (
                                <div style={{ marginTop: 30 }}>
                                    <Card>
                                        <CardHeader
                                            title={
                                                <Typography variant="h6" style={title}>
                                                    Turno confirmado
                                                </Typography>
                                            }
                                            action={
                                                <Avatar>
                                                    <DoneOutlined />
                                                </Avatar>
                                            }
                                        />
                                    </Card>
                                    {sesion !== "" ?
                                        (
                                            <Card style={{ marginTop: 10 }}>
                                                <CardHeader avatar={<Avatar><AttachFileOutlined /></Avatar>} title="Puede adjuntar archivos a su turno" />
                                                <CardContent>
                                                    <FileUploadModule
                                                        usuario={cuilPacienteSeleccionado.toString()}
                                                        sesion={sesion}
                                                        onCompletedUploading={
                                                            (newFile) => {
                                                                let temp = [...listaArchivos];
                                                                temp.push(newFile);
                                                                setListaArchivos(temp)
                                                            }
                                                        }
                                                    />
                                                    {
                                                        listaArchivos.length > 0 ?
                                                            (
                                                                <>
                                                                    <Divider style={{ margin: "10px 0px 10px 0px" }} />
                                                                    {listaArchivos.map(
                                                                        (archivo, index) => {
                                                                            let { file, descripcion, linkArchivo } = archivo;
                                                                            return (
                                                                                <CardArchivoSubido
                                                                                    fileName={file}
                                                                                    canDelete={true}
                                                                                    onDelete={
                                                                                        () => {
                                                                                            onDeleteFile(linkArchivo);
                                                                                        }
                                                                                    }
                                                                                    description={descripcion}
                                                                                    downloadLink={linkArchivo}
                                                                                />
                                                                            )
                                                                        }
                                                                    )}
                                                                </>
                                                            )
                                                            :
                                                            (null)
                                                    }
                                                </CardContent>
                                            </Card>
                                        ) :
                                        (
                                            null
                                        )}
                                </div>
                            )
                            :
                            (
                                <List>
                                    {opcionesList}
                                </List>
                            )
                    }

                </Grid>
                <Grid item xs />
            </Grid>
            <div style={{ marginTop: 20 }} className={classes.buttonsRoot}>
                {
                    turnoConfirmado ?
                        (
                            <Button onClick={() => { history.push("/dashboard") }} style={theme.buttons.okButton}>Finalizar</Button>
                        )
                        :
                        (
                            <Volver onClickHandler={() => { history.goBack() }} />
                        )
                }
            </div>
            <Dialog open={error400 !== undefined && error400.length > 0}>
                <DialogTitle>
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error400}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            localStorage.removeItem("Error400")
                            history.push("/dashboard");
                        }}
                        variant="contained"
                        disableElevation
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withStyles(styles)(ConfirmarTurno)
