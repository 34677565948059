import React, { useState } from 'react'
import { styles } from './FileUploadModuleStyles';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Avatar, Button, Chip, TextField, useTheme } from '@material-ui/core';
import { postArchivo } from '../../apis/citaInstantaneaAPI';
import { IArchivoSubido } from '../../Interfaces/IArchivoSubido';
import { AttachFile, DoneOutlined } from '@material-ui/icons';
import LoadingBubbles from '../LoadingBubbles/LoadingBubbles';

interface OwnProps {
    usuario: string;
    sesion: string;
    onCompletedUploading?: (newListaArchivos: IArchivoSubido) => void;
    inactive?: boolean;
    origen?: "profesional" | "paciente";
}

type PublicProps = OwnProps
type Props = PublicProps & WithStyles<typeof styles>

const FileUploadModule: React.FC<Props> = (props) => {
    const { sesion, usuario, onCompletedUploading, inactive, origen } = props;

    const insertarArchivo = async (file?: File, descripcion?: string) => {
        if(inactive){
            alert("NO TIENE HABILITADA ESTA FUNCION POR EL MOMENTO");
            return;
        }
        if (file === undefined) {
            alert("NO HAY ARCHIVO");
            return;
        }
        if (descripcion === "") {
            alert("POR FAVOR INGRESE UNA DESCRIPCION");
            return;
        }
        let filename = file.name.toLowerCase();
        if (!filename.endsWith(".pdf") && !filename.endsWith(".jpg") && !filename.endsWith(".png") && !filename.endsWith(".jpeg")) {
            alert("POR FAVOR INGRESE UN TIPO DE ARCHIVO VALIDO: PDF, JPG O PNG");
            return;
        }
        if (file.size > 10000000) {
            alert("TAMAÑO MAXIMO PERMITIDO 10MB");
            return;
        }
        console.log(descripcion);
        setLoadingArchivo(true);
        let resultado = await postArchivo(file!, sesion, usuario, descripcion!, origen? origen : "paciente");
        setLoadingArchivo(false);
        if (resultado.resultado.toLowerCase() === "carga exitosa") {
            //console.log("ARCHIVO CARGADO: " + file.name);
            setArchivo(undefined);
            setDescripcionArchivo("");
            if (onCompletedUploading !== undefined) {
                onCompletedUploading(resultado.archivo);
            }
        }
    }

    const [archivo, setArchivo] = useState<File | undefined>(undefined);
    const [descripcionArchivo, setDescripcionArchivo] = useState<string>("");
    // const [listaArchivosSubidos, setListaArchivosSubidos] = useState<IArchivoSubido[]>([]);
    const [loadingArchivo, setLoadingArchivo] = useState(false);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const theme = useTheme();
    return (
        <>
            <Button
                variant="outlined"
                component="label"
                disabled={loadingArchivo}
                fullWidth
            >
                {archivo === undefined ? "Subir Archivo" : "Seleccionar Archivo"}
                <input
                    type="file"
                    accept=".jpg,.png,.pdf"
                    name="inputArchivo"
                    onChange={
                        (e) => {
                            setArchivo(e!.target!.files![0]!);
                        }
                    }
                    value={""}
                    hidden
                    ref={hiddenFileInput}
                />
            </Button>
            {
                archivo === undefined ? (<></>) : (
                    <>
                        <Chip
                            style={{ maxWidth: 'inherit', margin: 20 }}
                            variant="outlined"
                            label={archivo!.name}
                            avatar={<Avatar><AttachFile /></Avatar>}
                        />
                        <TextField required disabled={archivo === undefined} helperText="Por favor incluya una descripcion corta." label="Descripcion del archivo" name="tituloArchivo" onChange={(e) => setDescripcionArchivo(e.target.value)} fullWidth />
                        <Button
                            disabled={loadingArchivo || descripcionArchivo === ""}
                            variant="contained"
                            disableElevation
                            component="label"
                            fullWidth
                            onClick={() => { insertarArchivo(archivo, descripcionArchivo) }}
                            endIcon={<DoneOutlined />}
                            style={loadingArchivo || descripcionArchivo === "" ? theme.buttons.okDisabledButton : theme.buttons.okButton}
                        >
                            Enviar Archivo
                        </Button>
                        {loadingArchivo && <LoadingBubbles />}
                    </>
                )
            }
        </>
    )
}

export default withStyles(styles)(FileUploadModule) as React.ComponentType<PublicProps>