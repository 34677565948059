import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, WithStyles, withStyles } from '@material-ui/core';

import { styles } from './SeleccionarPacienteStyles';
import PacienteCard from '../../../../components/PacienteCard/PacienteCard';
import { setPaso } from '../../../../store/paciente/ver-medico-online/verMedicoOnlineSlice';
import { getPacientes, setCuilPacienteSeleccionado } from '../../../../store/user/userSlice';
import { RootState } from '../../../../store/rootReducer';
import Volver from '../../../../components/buttons/Volver/Volver';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { useTime } from '../../../../hooks/useTime';
import { getHorarioAtencion } from '../../../../apis/citaInstantaneaAPI';
import { convertDateToTimestring, convertTimestringToDate } from '../../../../utils/dateTimeHelper';


const SeleccionarPaciente: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const { cuilsDePacientes, pacientesByCuil } = useSelector((state: RootState) => state.user)
    const { ID_GRUPO_FAMILIAR } = useSelector((state: RootState) => state.user)

    const now = useTime();

    const [loading, setLoading] = useState(false);
    const [inicioAtencion, setInicioAtencion] = useState(convertTimestringToDate("00:00"));
    const [finAtencion, setFinAtencion] = useState(convertTimestringToDate("23:59"));

    useEffect(() => {
        setLoading(true);
        getHorarioAtencion()
            .then((datos) => {
                setInicioAtencion(convertTimestringToDate(datos.horaInicio));
                setFinAtencion(convertTimestringToDate(datos.horaFin));
                setLoading(false);
            })
            .catch(e => { console.log(e) })
        dispatch(getPacientes(ID_GRUPO_FAMILIAR!));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onPacienteClicked = (cuil: number) => {
        dispatch(setPaso(2))
        dispatch(setCuilPacienteSeleccionado(cuil))
        history.push(`/dashboard/ver-medico-online/especialidad`)
    }

    const onVolverClicked = () => {
        dispatch(setPaso(0))
        history.goBack()
    }

    if (now.getTime() < inicioAtencion.getTime() || now.getTime() > finAtencion.getTime()) {
        return (
            <Dialog open={true} fullWidth maxWidth="xs">
                <DialogContent>
                    <DialogContentText>
                        {`El horario de atención de la guardia es de ${convertDateToTimestring(inicioAtencion)}hs a ${convertDateToTimestring(finAtencion)}hs.`}
                    </DialogContentText>
                    <DialogContentText>
                        Agradecemos su comprensión y disculpe las molestias.
                    </DialogContentText>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <p>¿Quién será el paciente en la consulta?</p>
            <Divider />
            {cuilsDePacientes.length === 0 || loading ?
                (
                    <LoadingBubbles />
                )
                :
                (
                    <Box my={3}>
                        <Grid justify="center" container spacing={3}>
                            {
                                cuilsDePacientes.map((cuil: number) => (
                                    <Grid item key={cuil}>
                                        <PacienteCard
                                            paciente={pacientesByCuil[cuil]}
                                            onClickHandler={() => onPacienteClicked(cuil)} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box >
                )}
            <Divider />
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={onVolverClicked} />

            </div>
        </>
    )
}

export default withStyles(styles)(SeleccionarPaciente)
