import { createStyles } from "@material-ui/core";

export const styles = createStyles({
    paper: {
        padding: 8,
        minWidth: 310,
        maxWidth: 420,
        width:420,
        backgroundColor: "#e9e9e9",
        '&:hover': {
            cursor: "pointer",
            '& p>b': {
                color: "orange"
            }
        },
        '& p': {
            margin: '10px 0'
        }
    }
})
