import firebase from 'firebase/app';
import '@firebase/firestore';

let firebaseConfig = {};

switch (process.env.REACT_APP_ENV) {
    case "development": {
        firebaseConfig = {
            apiKey: "AIzaSyBLOzAvnv65Kj62q467OhuTLLNcOGHgjxY",
            authDomain: "medife-finochietto-dev.firebaseapp.com",
            databaseURL: "https://medife-finochietto-dev-default-rtdb.firebaseio.com",
            projectId: "medife-finochietto-dev",
            storageBucket: "medife-finochietto-dev.appspot.com",
            messagingSenderId: "459681035549",
            appId: "1:459681035549:web:22b5f609f5ee4d20541df4",
            measurementId: "G-3HTP1JRF2Y"
        };
        break;
    }
    case "test": {
        firebaseConfig = {
            apiKey: "AIzaSyCTKrFEC8KlgQABjSDtsi0ZbyD3HWV0r24",
            authDomain: "medife-telemedicina-qa.firebaseapp.com",
            databaseURL: "https://medife-telemedicina-qa.firebaseio.com",
            projectId: "medife-telemedicina-qa",
            storageBucket: "medife-telemedicina-qa.appspot.com",
            messagingSenderId: "656449355570",
            appId: "1:656449355570:web:e3009fd7796b517475a12b",
            measurementId: "G-3MFPD2MHB6"
        };
        break;
    }
    case "production": {
        firebaseConfig = {
            apiKey: "AIzaSyA_I2e4FHSGIC7y18v9aoyD0ZVMVkSN2nY",
            authDomain: "medife-telemedicina-prd.firebaseapp.com",
            databaseURL: "https://medife-telemedicina-prd.firebaseio.com",
            projectId: "medife-telemedicina-prd",
            storageBucket: "medife-telemedicina-prd.appspot.com",
            messagingSenderId: "771436801491",
            appId: "1:771436801491:web:08db40df83d2f371f49326",
            measurementId: "G-MTFZ1N0Y60"
        };
        break;
    }
    case "medife-finochietto-dev": {
        firebaseConfig = {
            apiKey: "AIzaSyBLOzAvnv65Kj62q467OhuTLLNcOGHgjxY",
            authDomain: "medife-finochietto-dev.firebaseapp.com",
            databaseURL: "https://medife-finochietto-dev-default-rtdb.firebaseio.com",
            projectId: "medife-finochietto-dev",
            storageBucket: "medife-finochietto-dev.appspot.com",
            messagingSenderId: "459681035549",
            appId: "1:459681035549:web:22b5f609f5ee4d20541df4",
            measurementId: "G-3HTP1JRF2Y"
        };
        break;
    }
    case "camdoctor-telemedicina-demo": {
        firebaseConfig = {
            apiKey: "AIzaSyDzOHc0Er6HgMUJ1K67XczpEGzZZg4B4V8",
            authDomain: "camdoctor-telemedicina-demo.firebaseapp.com",
            databaseURL: "https://camdoctor-telemedicina-demo-default-rtdb.firebaseio.com",
            projectId: "camdoctor-telemedicina-demo",
            storageBucket: "camdoctor-telemedicina-demo.appspot.com",
            messagingSenderId: "770956701499",
            appId: "1:770956701499:web:fb37050f0c904036dc511e",
            measurementId: "G-F950N58MKP"
          };
        break;
    }
    case "medife-finochietto-qa": {
        firebaseConfig = {
            apiKey: "AIzaSyCOYl3x_H6ow44GWgNF1xM-RhEsqILynWo",
            authDomain: "medife-finochietto-qa.firebaseapp.com",
            databaseURL: "https://medife-finochietto-qa-default-rtdb.firebaseio.com",
            projectId: "medife-finochietto-qa",
            storageBucket: "medife-finochietto-qa.appspot.com",
            messagingSenderId: "129082972345",
            appId: "1:129082972345:web:29cbd5d0408e265a81e368",
            measurementId: "G-9P1W9SSFGF"
        };
        break;
    }
}

const app = firebase.initializeApp(firebaseConfig);

export function getFirebase() {
    return app;
}

export function getFirestore() {
    return firebase.firestore(app);
}