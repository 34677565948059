import React, { useState, useEffect } from 'react';
import { Box, Divider, Grid, Paper, Typography, WithStyles, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { History, } from '@material-ui/icons';

import { styles } from './HistorialStyles';
import { RootState } from '../../../../store/rootReducer';
import Volver from '../../../../components/buttons/Volver/Volver';
import PerfilPaciente from '../../../../components/PerfilPaciente/PerfilPaciente';
import CardHistoriaClinica from '../../../../components/CardHistoriaClinica/CardHistoriaClinica';
import { obtenerHistorialClinico, verificarZonaRecetas } from '../../../../apis/historiaClinicaAPI';
import { IElementoHistorial } from '../../../../Interfaces/IElementoHistorial';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { Pagination } from '@material-ui/lab';
import useGeolocation from '../../../../hooks/useGeolocation';


const Historial: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const { cuilPacienteSeleccionado, pacientesByCuil } = useSelector(
        (state: RootState) => state.user
    )

    useEffect(() => {
        obtenerDatos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const location = useGeolocation();
    const [areaSupportsPharmacy, setAreaSupportsPharmacy] = useState(false);

    useEffect(() => {
        if (location.error) {
            setAreaSupportsPharmacy(false);
            return;
        }
        if (!location.loaded) {
            setAreaSupportsPharmacy(false);
            return;
        }

        verificarZonaRecetas(location.coordinates!.lat, location.coordinates!.lng)
            .then((resultado) => {
                setAreaSupportsPharmacy(resultado.ZonaPermitida);
            })
            .catch(e => { console.log(e) })
    }, [
        location.error,
        location.loaded,
        location.coordinates
    ])

    const [loading, setLoading] = useState(false);

    const obtenerDatos = async () => {
        setLoading(true);
        const data = (await obtenerHistorialClinico(cuilPacienteSeleccionado.toString()));
        setLoading(false);
        data.sort((a, b) => { return b.fecha._seconds - a.fecha._seconds; });
        setHistorialClinico(data as any);
    };

    const [historialClinico, setHistorialClinico] = useState<Array<IElementoHistorial>>([]);

    const elementosPerPag = 5;
    const [pagina, setPagina] = useState(0);



    return (
        <Grid container>
            <Grid item xs />
            <Grid container item xs={10} direction="column">

                {/* <Typography variant="h6">Confirmación de la consulta de {especialidad}</Typography> */}
                <Divider />
                <Box my={4}>
                    <PerfilPaciente
                        paciente={pacientesByCuil[cuilPacienteSeleccionado]} />
                </Box>
                <Divider />
                <p />
                <Paper style={{ paddingBottom: -50 }}>

                    <p />
                    <Grid item container xs={12} alignItems="center" justify="center" >
                        <History />
                        <Typography variant="h5">Historial de consultas</Typography>
                    </Grid>
                    <p />
                    {loading ?
                        (
                            <LoadingBubbles />
                        )
                        :
                        (
                            <>
                                <Grid item container xs={12}>
                                    <Grid item container xs={12} direction="column" >
                                        {historialClinico.length > 0 ?
                                            (
                                                <>
                                                    {
                                                        historialClinico.map(
                                                            (elementoHistorial, index) => {
                                                                const setElementoHistorial = (newElemento: IElementoHistorial) => {
                                                                    let temp = [...historialClinico];
                                                                    temp[index] = newElemento;
                                                                    setHistorialClinico(temp);
                                                                }
                                                                if (index >= pagina * elementosPerPag && index < (pagina + 1) * elementosPerPag) {
                                                                    return (
                                                                        <>
                                                                            <CardHistoriaClinica
                                                                                mostrarNotasPrivadas={false}
                                                                                key={index}
                                                                                elementoHistorial={elementoHistorial}
                                                                                setElementoHistorial={setElementoHistorial}
                                                                                popup
                                                                                showAvatar
                                                                                puedePedirFarmacia
                                                                                mostrarAgregarFavorito
                                                                                locationData={location}
                                                                                areaSupportsPharmacy={areaSupportsPharmacy}
                                                                            />
                                                                        </>
                                                                    )
                                                                }
                                                                return null;
                                                            }
                                                        )
                                                    }
                                                    <Pagination style={{ alignSelf: "center", justifySelf: "center", flexGrow: 1, margin: 30 }} count={Math.ceil(historialClinico.length / elementosPerPag)} page={pagina + 1} onChange={(event, value) => {
                                                        setLoading(true);
                                                        setTimeout(() => {
                                                            setPagina(value - 1)
                                                            setLoading(false)
                                                        }, 500)
                                                    }} />
                                                </>
                                            ) :
                                            (
                                                <div style={{ marginTop: 200 }}>
                                                    <Typography variant="h6">
                                                        No hay historial disponible para el paciente seleccionado.
                                                    </Typography>
                                                </div>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                </Paper>
                <div className={classes.buttonsRoot}>
                    <Volver onClickHandler={() => {
                        history.goBack();
                    }} />
                </div>
            </Grid>
            <Grid item xs />
        </Grid>
    )
}

export default withStyles(styles)(Historial)
