import React, { useEffect, useState } from 'react';
import {
    Box, Divider, WithStyles, withStyles, Grid, Typography, Button,
    Dialog, DialogActions, DialogContent, DialogContentText, useTheme, DialogTitle, Toolbar
} from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { styles } from './SalaEsperaStyles';
import { RootState } from '../../../../store/rootReducer';
import { cancelarCita } from '../../../../apis/citaInstantaneaAPI';
import { ArrowBackIos, CancelOutlined, MicOutlined, RecordVoiceOverOutlined, VideocamOutlined } from '@material-ui/icons';
import { getFirestore } from '../../../../db';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/animations/clockAnim.json';

import Webcam from 'react-webcam';
import { ReactMic } from 'react-mic';
import { setEnEspera } from '../../../../apis/citaProgramadaAPI';
import { convertSecondsToLocaleHMS } from '../../../../utils/dateTimeHelper';
import { useTime } from '../../../../hooks/useTime';

const SalaEspera: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const dispatch = useDispatch()

    const now = useTime(500);
    const [horaInicioEspera, setHoraInicioEspera] = useState(new Date());

    const { idSalaVideollamada } = useSelector(
        (state: RootState) => state.consultasProgramadas
    )
    const { cuilPacienteSeleccionado } = useSelector(
        (state: RootState) => state.user
    )

    const [loading, setLoading] = useState(false);

    const [tiempoEsperaEstimado, setTiempoEsperaEstimado] = useState(0);

    const onLlamadoConsultorio = () => {
        history.push(`/dashboard/consultas-programadas/llamado`)
    }
    const db = getFirestore();

    useEffect(() => {
        setLoading(true);
        setEnEspera({ usuario: cuilPacienteSeleccionado.toString(), sesion: idSalaVideollamada })
            .then(
                (data) => {
                    setTiempoEsperaEstimado(data.tiempoEsperaPromedio);
                    setLoading(false);
                }
            )
            .catch(
                e => { console.log(e) }
            )
    }, [])

    useEffect(() => {
        const unsubscribe = db.collection("pacientes").doc(cuilPacienteSeleccionado.toString()).collection("Consultas").doc(idSalaVideollamada).onSnapshot(
            (datosSesion) => {
                if (!datosSesion.exists) {
                    console.log("La sesion no existe");
                    return;
                }
                if (datosSesion!.data()!.estado.toLowerCase() === "atendido") {
                    onLlamadoConsultorio();
                } else {
                    console.log(`El estado es ${datosSesion!.data()!.estado}`);
                }
            }
        );
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const theme = useTheme();

    const [troubleshootOpen, setTroubleshootOpen] = React.useState(false);

    const troubleshootDialog = (open: boolean) => (
        <Dialog
            open={open}
            onClose={() => { setTroubleshootOpen(false) }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Posibles causas de mal funcionamiento
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ol>
                        <li>
                            La cámara web no está conectada. Verifique el cable de conexión de la misma.
                        </li>
                        <li>
                            El cable de conexión de la cámara web está en mal estado. Verifique que el mismo no esté doblado ni dañado.
                        </li>
                        <li>
                            La cámara web está deshabilitada en el Administrador de Dispositivos del sistema operativo. Por favor habilite la misma.
                        </li>
                        <li>
                            La cámara web está siendo utilizada por otra aplicación. Por favor cierre todas las otras aplicaciones que puedan hacer uso de la misma.
                        </li>
                        <li>
                            El navegador está prohibiendo el uso de la cámara web para este sitio. Otorgue los permisos desde la configuración del navegador.
                        </li>
                        <li>
                            La cámara web está rota.
                        </li>
                    </ol>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={theme.buttons.okButton} variant="contained" disableElevation onClick={() => { setTroubleshootOpen(false) }}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )

    const [testOpen, setTestOpen] = React.useState(false);
    const [recordingAudio, setRecordingAudio] = useState(false);
    const [listeningAudio, setListeningAudio] = useState(false);
    const audioRecordBtnDisabled = recordingAudio || listeningAudio;

    const testAudioVideoDialog = (open: boolean) => (
        <Dialog
            open={open}
            onClose={() => { setTestOpen(false) }}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent>
                <Webcam style={{ flexGrow: 1, width: "100%" }} />
                <Toolbar>
                    <ReactMic
                        className={classes.mic}
                        record={recordingAudio}
                        onStop={(recordedBlob) => {
                            console.log(recordedBlob);
                            const url = URL.createObjectURL(recordedBlob.blob);
                            const audio = new Audio(url);
                            audio.play();
                        }}
                        strokeColor={theme.paletaColores.naranja.tercero}
                    />
                    <Button
                        onClick={() => {
                            setRecordingAudio(true);
                            setTimeout(() => {
                                setRecordingAudio(false);
                                setListeningAudio(true);
                                setTimeout(() => {
                                    setListeningAudio(false);
                                }, 3000);
                            }, 3000);
                        }}
                        disabled={audioRecordBtnDisabled}
                        variant="contained"
                        disableElevation
                        startIcon={audioRecordBtnDisabled ? (recordingAudio ? (<RecordVoiceOverOutlined />) : (<LoadingBubbles size={"25px"} />)) : (<MicOutlined />)}
                        style={audioRecordBtnDisabled ? undefined : theme.buttons.okButton}
                        fullWidth
                    >
                        {
                            recordingAudio ?
                                ("Hablá")
                                :
                                (
                                    <>
                                        {
                                            listeningAudio ?
                                                ("Escuchá")
                                                :
                                                ("Grabar")
                                        }
                                    </>
                                )
                        }
                    </Button>
                </Toolbar>
                <DialogContentText>
                    Si podés ver tu imagen de la cámara, podés grabar tu voz y después podés escuchar la grabación, todo está funcionando bien.
                </DialogContentText>
                <DialogContentText>
                    <NavLink
                        onClick={() => {
                            setTestOpen(false);
                            setTroubleshootOpen(true);
                        }}
                        style={{ color: theme.paletaColores.naranja.segundo }}
                        to="#"
                    >
                        Clic aca
                    </NavLink>
                    {" si algo no esta funcionando correctamente."}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation style={theme.buttons.okButton} onClick={() => { setTestOpen(false) }}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )

    if (loading) {
        return (
            <LoadingBubbles />
        )
    }

    return (
        <>
            <Divider />
            <Box my={3}>
                <Grid justify="center" container spacing={3}>
                    <Lottie style={{ marginBottom: 60 }} options={{ loop: true, autoplay: true, animationData: animationData, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }} isStopped={false} isPaused={false} width={200} height={200} />
                    {/* <img src={imgClock} alt="imagen reloj" width="10%" /> */}
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <h3>Bienvenido a la sala de espera</h3>
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <Typography paragraph>El médico ha sido notificado de su presencia.</Typography>
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <Typography paragraph>Aguarde y será atendido.</Typography>
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <Typography paragraph>Llevas en espera: {convertSecondsToLocaleHMS((now.getTime() - horaInicioEspera.getTime()) / 1000)}</Typography>
                </Grid>
                <Grid justify="center" container spacing={3}>
                    <Typography paragraph>Tiempo de espera estimado: {convertSecondsToLocaleHMS(tiempoEsperaEstimado)}</Typography>
                </Grid>
            </Box>
            <Divider />
            <div className={classes.buttonsRoot}>
                <Button
                    variant="contained"
                    disableElevation
                    startIcon={<ArrowBackIos />}
                    onClick={() => { history.goBack() }}
                    style={theme.buttons.cancelButton}
                >
                    VOLVER ATRAS
                </Button>
                <Button
                    variant="contained"
                    disableElevation
                    endIcon={<VideocamOutlined />}
                    onClick={() => { setTestOpen(true) }}
                    style={theme.buttons.okButton}
                >
                    PROBAR CAMARA Y SONIDO
                </Button>
            </div>

            {testAudioVideoDialog(testOpen)}
            {troubleshootDialog(troubleshootOpen)}
        </>
    )
}

export default withStyles(styles)(SalaEspera)
