import { Button, Card, CardHeader, Collapse, CardContent, TextField, Toolbar, Typography, useTheme, CardActionArea, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, Paper, Divider, Stepper, Step, StepLabel, Select, MenuItem, FormControl, InputLabel, DialogContentText } from '@material-ui/core';
import { ArrowBackIos, ArrowRightOutlined, CloseOutlined, CloudDownloadOutlined, ExpandLess, ExpandMore, PersonOutlined, SearchOutlined } from '@material-ui/icons';
import React from 'react'
import { actualizarDireccionPedido, buscarPacientes, cambiarEstadoPedido, getListaPedidos, obtenerHistorialClinico } from '../../apis/historiaClinicaAPI';
import CardHistoriaClinica from '../CardHistoriaClinica/CardHistoriaClinica';
import LoadingBubbles from '../LoadingBubbles/LoadingBubbles';
import { IElementoHistorial } from '../../Interfaces/IElementoHistorial';
import { IResultadoBusquedaPaciente } from '../../Interfaces/IResultadoBuquedaPaciente';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Pagination } from '@material-ui/lab';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ISolicitudFarmacia } from '../../Interfaces/ISolicitudFarmacia';
import { convertDateToDatestring, convertDateToLocaleShortDateOnly, convertDateToLocaleShort } from '../../utils/dateTimeHelper';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

interface Props {
    filtros: boolean;
}

function BusquedaSolicitudesFarmacia(props: Props) {
    // const [cuilPaciente, setCuilPaciente] = React.useState("");
    // const [apellidoPaciente, setApellidoPaciente] = React.useState("");
    // const [nombrePaciente, setNombrePaciente] = React.useState("");
    const {
        filtros,
    } = props;
    const [solicitudes, setSolicitudes] = React.useState<Array<ISolicitudFarmacia>>([]);
    const [solicitudesListado, setSolicitudesListado] = React.useState<Array<ISolicitudListado>>([]);
    const [loading, setLoading] = React.useState(false);
    const theme = useTheme();

    const [fechaBusqueda, setFechaBusqueda] = React.useState<Date | null>(null);
    const [apellidoBusqueda, setApellidoBusqueda] = React.useState<string>("");
    const [idBusqueda, setIdBusqueda] = React.useState<string>("");
    const [estadoBusqueda, setEstadoBusqueda] = React.useState<string>("");

    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState<string>("");

    const [direccion, setDireccion] = React.useState("");

    const [cancelarOpen, setCancelarOpen] = React.useState(false);
    const [reactivarOpen, setReactivarOpen] = React.useState(false);
    const [avanzarOpen, setAvanzarOpen] = React.useState(false);

    const closeDetails = () => {
        setDetailsOpen(false);
        setSelectedId("");
    }

    const buscar = () => {
        setLoading(true);
        getListaPedidos(apellidoBusqueda, undefined, idBusqueda, estadoBusqueda, fechaBusqueda ? fechaBusqueda! : undefined)
            .then((resp) => {
                resp = resp.sort((a, b) => new Date(a.fechaSolicitud).getTime() - new Date(b.fechaSolicitud).getTime());
                setLoading(false);
                setSolicitudes(resp);
            })
            .catch(e => { console.log(e); })
    }

    React.useEffect(() => {
        buscar();
    }, []);

    React.useEffect(() => {
        if (solicitudes.length > 0) {
            let temp: Array<ISolicitudListado> = [];
            solicitudes.forEach((sol, iSol) => {
                let newSol: ISolicitudListado = {
                    id: sol.idReceta,
                    fecha: convertDateToLocaleShort(new Date(sol.fechaSolicitud)),
                    medicamentos: sol.medicamentos[0].f0_,
                    paciente: sol.apellido + ", " + sol.nombre,
                    celular: sol.celular,
                    direccion: "",
                    estado: sol.estado,
                }
                temp.push(newSol);
            });
            setSolicitudesListado(temp);
        }
    }, [solicitudes])

    const columns: GridColDef[] = [
        {
            field: 'fecha',
            headerName: 'Fecha',
            filterable: false,
            hide: false,
            minWidth: 130,
            width: 130,
        },
        {
            field: 'id',
            headerName: 'Nro.',
            sortable: false,
            filterable: false,
            hide: false,
            minWidth: 125,
            width: 125,
        },
        {
            field: 'medicamentos',
            headerName: 'Medicamentos',
            sortable: false,
            filterable: false,
            hide: false,
            minWidth: 150,
        },
        {
            field: 'paciente',
            headerName: 'Paciente',
            sortable: false,
            filterable: false,
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'celular',
            headerName: 'Celular',
            sortable: false,
            filterable: false,
            hide: true,
            minWidth: 100,
            width: 100,
        },
        {
            field: 'direccion',
            headerName: 'Direccion',
            sortable: false,
            filterable: false,
            hide: true,
            minWidth: 200,
        },
        {
            field: 'estado',
            headerName: 'Estado',
            sortable: false,
            filterable: false,
            minWidth: 100,
            width: 100,
        },
        {
            field: "action",
            headerName: " ",
            sortable: false,
            filterable: false,
            width: 70,
            renderCell: (params) => {
                const open = () => {
                    const id = params.getValue(params.id, 'id') as string;
                    const solicitud = solicitudes.find(x => x.idReceta === id)!;

                    setDetailsOpen(true);
                    setSelectedId(id);
                    setDireccion(solicitud.direccion ? solicitud.direccion! : "");
                    // let temp = [...dialogResendOpen];
                    // temp[params.getValue(params.id, `id`) as number] = open;
                    // setDialogResendOpen(temp);
                }

                const enviar = () => {
                    // setLoading(true);
                    // enviarEmailRecordatorio(params.getValue(params.id, `cuil`) as string, params.getValue(params.id, `sesion`) as string)
                    //     .then((datoRecibido) => {
                    //         setLoading(false);
                    //         return alert(`Reenvio exitoso`);
                    //     })
                    //     .catch(e => console.log(e))
                }

                return (
                    <>
                        <IconButton onClick={open}><ArrowRightOutlined /></IconButton>
                        {/* <ConfirmationDialog
                            open={dialogResendOpen[params.getValue(params.id, `id`) as number]}
                            setOpen={setOpen}
                            onConfirm={enviar}
                            title="Reenviar aviso"
                        >
                            <Typography>
                                Desea reenviar el aviso de cita al paciente {params.getValue(params.id, `paciente`)}?
                            </Typography>
                        </ConfirmationDialog> */}
                    </>
                );
            }
        },
    ];

    interface ISolicitudListado {
        fecha: string;
        id: string;
        medicamentos: string;
        paciente: string;
        celular: string;
        direccion: string;
        estado: string;
    }

    const solicitudSeleccionada = solicitudes.find(x => x.idReceta === selectedId);

    interface IEstado {
        descripcion: string;
        instruccion: string;
        instruccionHecha: string;
        codigoEstado: number;
        textoAvanzar: string;
        textoAvanzarLargo: string;
        puedeCancelar: boolean;
        puedeCambiarDireccion: boolean;
        avanzarDisabled: () => boolean;
    }

    const estados: Array<IEstado> = [
        {
            descripcion: "Solicitado",
            instruccion: "",
            instruccionHecha: "Solicitud realizada",
            codigoEstado: 0,
            textoAvanzar: "Solicitante contactado",
            puedeCancelar: true,
            textoAvanzarLargo: "Ya pudo contactarse con el solicitante?",
            puedeCambiarDireccion: true,
            avanzarDisabled: () => {
                if (direccion) return false;
                return true;
            },
        },
        {
            descripcion: "Contactado",
            instruccion: "Contactar al solicitante",
            instruccionHecha: "Solicitante contactado",
            codigoEstado: 1,
            textoAvanzar: "Pedido despachado",
            puedeCancelar: true,
            textoAvanzarLargo: "Ya despachó el pedido?",
            puedeCambiarDireccion: false,
            avanzarDisabled: () => {
                return false;
            },
        },
        {
            descripcion: "Despachado",
            instruccion: "Despachar el pedido",
            instruccionHecha: "Pedido despachado",
            codigoEstado: 2,
            textoAvanzar: "Pedido entregado",
            puedeCancelar: false,
            textoAvanzarLargo: "El pedido ya fue entregado?",
            puedeCambiarDireccion: false,
            avanzarDisabled: () => {
                return false;
            },
        },
        {
            descripcion: "Entregado",
            instruccion: "Confirmar la entrega",
            instruccionHecha: "Entrega confirmada",
            codigoEstado: 3,
            textoAvanzar: "",
            puedeCancelar: false,
            textoAvanzarLargo: "",
            puedeCambiarDireccion: false,
            avanzarDisabled: () => {
                return true;
            },
        },
        {
            descripcion: "Cancelado",
            instruccion: "Cancelar el pedido",
            instruccionHecha: "Pedido cancelado",
            codigoEstado: -1,
            textoAvanzar: "",
            puedeCancelar: false,
            textoAvanzarLargo: "",
            puedeCambiarDireccion: false,
            avanzarDisabled: () => {
                return true;
            },
        }
    ]

    const estadoActual = estados.find(x => x.descripcion === solicitudSeleccionada?.estado);

    const cancelarSolicitud = () => {
        setLoading(true);
        setDetailsOpen(false);
        cambiarEstadoPedido(solicitudSeleccionada!.idReceta, "Cancelado")
            .then((res) => {
                buscar();
                setSelectedId("");
            })
            .catch(e => { console.log(e); })
    }

    const reactivarSolicitud = () => {
        setLoading(true);
        setDetailsOpen(false);
        cambiarEstadoPedido(solicitudSeleccionada!.idReceta, "Solicitado")
            .then((res) => {
                buscar();
                setSelectedId("");
            })
            .catch(e => { console.log(e); })
    }

    const avanzarSolicitud = () => {
        let nuevoEstado = estados.find(x => x.codigoEstado === estadoActual!.codigoEstado + 1)!.descripcion;
        setLoading(true);
        setDetailsOpen(false);

        if (estadoActual?.codigoEstado === 0) {
            //si el estado actual es solicitado, setear la direccion Y avanzar la solicitud
            actualizarDireccionPedido(solicitudSeleccionada!.idReceta, direccion)
                .then((respuestaDireccion) => {
                    cambiarEstadoPedido(solicitudSeleccionada!.idReceta, nuevoEstado)
                        .then((respuestaAvance) => {
                            buscar();
                            setSelectedId("");
                        })
                        .catch(e => { console.log(e); })
                    setSelectedId("");
                })
                .catch(e => { console.log(e); })
        } else {
            //si no, solo avanzar la solicitud
            cambiarEstadoPedido(solicitudSeleccionada!.idReceta, nuevoEstado)
                .then((res) => {
                    buscar();
                    setSelectedId("");
                })
                .catch(e => { console.log(e); })

        }

    }

    const enviarDireccion = () => {
        setLoading(true);
        setDetailsOpen(false);
        actualizarDireccionPedido(solicitudSeleccionada!.idReceta, direccion)
            .then((res) => {
                buscar();
                setSelectedId("");
            })
            .catch(e => { console.log(e); })
    }

    // const puedeAvanzar = solicitudSeleccionada && solicitudSeleccionada!.estado !== "Cancelado" && solicitudSeleccionada!.estado !== "Entregado";

    return (
        <div>
            <Collapse in={filtros}>
                <div style={{ flexDirection: "row", display: "flex" }}>
                    {/* <TextField style={{ margin: 5, width: 200 }} size="small" variant="outlined" value={cuilPaciente} label="CUIL Paciente" onChange={(event) => {
                    setCuilPaciente(event.target.value as string);
                }} /> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            size="small"
                            format="dd/MM/yyyy"
                            label="Fecha solicitud"
                            value={fechaBusqueda}
                            onChange={(event) => {
                                setFechaBusqueda(event as Date)
                            }}
                            inputVariant="outlined"
                            style={{ margin: 5, width: 170 }}
                            defaultValue={null}
                            disableFuture
                            invalidDateMessage="Fecha inválida"
                            maxDateMessage="La fecha debe ser anterior a hoy"
                            InputAdornmentProps={{ style: { visibility: "hidden" } }}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        value={apellidoBusqueda}
                        size="small"
                        style={{ margin: 5, width: 150 }}
                        variant="outlined"
                        label="Apellido Paciente"
                        onChange={(event) => {
                            setApellidoBusqueda(event.target.value as string);
                        }} />
                    <TextField
                        value={idBusqueda}
                        size="small"
                        style={{ margin: 5, width: 150 }}
                        variant="outlined"
                        label="Nro. Receta"
                        onChange={(event) => {
                            setIdBusqueda(event.target.value as string);
                        }} />
                    <FormControl style={{ margin: 5, width: 150 }} variant="outlined" size="small">
                        <InputLabel>Estado</InputLabel>
                        <Select
                            value={estadoBusqueda}
                            variant="outlined"
                            label="Estado"
                            onChange={(event) => {
                                setEstadoBusqueda(event.target.value as string);
                            }} >
                            <MenuItem value={undefined}>(Todos los estados)</MenuItem>
                            <MenuItem value={"Solicitado"}>Solicitado</MenuItem>
                            <MenuItem value={"Contactado"}>Contactado</MenuItem>
                            <MenuItem value={"Despachado"}>Despachado</MenuItem>
                            <MenuItem value={"Entregado"}>Entregado</MenuItem>
                            <MenuItem value={"Cancelado"}>Cancelado</MenuItem>

                        </Select>
                    </FormControl>
                    <div style={{ flexGrow: 1 }} />
                    <Button onClick={buscar} color="primary" variant="contained" disableElevation startIcon={<SearchOutlined />} style={{ margin: 5 }} size="large">
                        {`Buscar`}
                    </Button>
                </div>
            </Collapse>
            {
                loading ?
                    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                        <LoadingBubbles />
                    </div>
                    :
                    <>
                        {
                            solicitudes.length === 0 ?
                                <div style={{ margin: 30 }}>
                                    <Typography>No se han encontrado pedidos.</Typography>
                                </div>
                                :
                                <div style={{ minHeight: "100px" }}>
                                    <div>
                                        <DataGrid
                                            rows={solicitudesListado}
                                            columns={columns}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            autoHeight
                                            disableColumnFilter
                                        />
                                    </div>
                                </div>
                        }
                    </>
            }
            {
                solicitudSeleccionada && detailsOpen &&
                <Dialog
                    open={detailsOpen}
                    onClose={closeDetails}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div>
                                <Typography variant="h6">
                                    {`Pedido de: ${solicitudSeleccionada!.apellido}, ${solicitudSeleccionada!.nombre}`}
                                </Typography>
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <IconButton onClick={closeDetails}>
                                <CloseOutlined />
                            </IconButton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 50 }}>
                            <Typography>
                                {"Receta Nro. " + solicitudSeleccionada!.idReceta}
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {
                            estadoActual!.codigoEstado !== -1 ?
                                <Stepper style={{ marginTop: -20 }} activeStep={estadoActual!.codigoEstado + 1}>
                                    {estados.map((objPaso, iObjPaso) => {
                                        if (objPaso.codigoEstado === -1) return null;

                                        const done = estadoActual!.codigoEstado >= objPaso.codigoEstado;
                                        return (
                                            <Step key={iObjPaso}>
                                                <StepLabel>
                                                    {done ? objPaso.instruccionHecha : objPaso.instruccion}
                                                </StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                :
                                <Typography color="error">
                                    Pedido cancelado
                                </Typography>
                        }
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                            <div>
                                <Typography>
                                    {`Email: ${solicitudSeleccionada!.email}`}
                                </Typography>
                                <Typography>
                                    {`Celular: ${solicitudSeleccionada!.celular}`}
                                </Typography>
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <Typography>
                                {convertDateToLocaleShort(new Date(solicitudSeleccionada!.fechaSolicitud))}
                            </Typography>
                        </div>
                        <Toolbar>
                            <TextField
                                label="Dirección"
                                fullWidth
                                value={direccion}
                                variant="outlined"
                                size="small"
                                onChange={(event) => {
                                    let newDom = event.target.value as string;
                                    setDireccion(newDom);
                                }}
                                disabled={!estadoActual!.puedeCambiarDireccion}
                            />
                            {/* <div style={{ width: 10 }} />
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                disabled={direccion == solicitudSeleccionada!.direccion || !direccion || !estadoActual!.puedeCambiarDireccion}
                                onClick={enviarDireccion}
                            >
                                Enviar
                            </Button> */}
                        </Toolbar>
                        <Paper style={{ margin: "0px 5px 10px 5px", padding: 10, flexGrow: 1 }} elevation={2}>
                            <Paper style={{ margin: 10, padding: 10, flexGrow: 1 }} variant="outlined">
                                <Typography>
                                    {"Diagnostico"}
                                </Typography>
                                {solicitudSeleccionada ? solicitudSeleccionada?.diagnosticoReceta?.map((diagnostico, iDiagnostico) => {
                                    return (
                                        <Typography key={iDiagnostico}>
                                            • {diagnostico.Name}
                                        </Typography>
                                    )
                                }) : (
                                    <Typography>
                                        • No hay diagnosticos
                                    </Typography>
                                )}
                            </Paper>
                            {
                                solicitudSeleccionada.medicamentos?.map(
                                    (medicamento, iMedicamento) => {
                                        return (
                                            <>
                                                <Typography >
                                                    • {medicamento.f0_}
                                                </Typography>
                                                <Typography paragraph>
                                                    {medicamento.permiteSustitucion.toLowerCase() === "s" ? (<>Permite sustitución</>) : (<>No permite sustitución</>)} / Cantidad de envases: {medicamento.cantidadEnvases}
                                                </Typography>
                                                <Typography >
                                                    • Indicaciones
                                                </Typography>
                                                <Typography paragraph>
                                                    {medicamento.indicaciones}
                                                </Typography>
                                                <Divider style={{ marginBottom: 10 }} />
                                            </>
                                        )
                                    }
                                )
                            }
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Typography>
                                    {
                                        solicitudSeleccionada.tratamientoProlongado ?
                                            "Esta receta es un tratamiento prolongado."
                                            :
                                            "Esta receta no es un tratamiento prolongado."
                                    }
                                </Typography>
                                <div style={{ flexGrow: 1 }} />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    style={{ borderRadius: 1000, maxWidth: 45, maxHeight: 45, minWidth: 45, minHeight: 45 }}
                                    onClick={() => {
                                        window.open(solicitudSeleccionada!.documentacion[0].linkArchivo);
                                    }}
                                >
                                    {/* <CloudDownloadOutlined /> */}
                                </Button>
                            </div>
                        </Paper>

                    </DialogContent>
                    <DialogActions>
                        {estadoActual?.puedeCancelar &&
                            <>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    size="small"
                                    onClick={() => { setCancelarOpen(true); }}
                                >
                                    {"cancelar pedido"}
                                </Button>
                                <ConfirmationDialog
                                    open={cancelarOpen}
                                    setOpen={(newOpen) => { setCancelarOpen(newOpen); }}
                                    title="Cancelar solicitud"
                                    onConfirm={cancelarSolicitud}
                                >
                                    <DialogContentText>
                                        {`Está seguro de que desea cancelar la solicitud?`}
                                    </DialogContentText>
                                </ConfirmationDialog>
                            </>
                        }
                        {estadoActual?.textoAvanzar &&
                            <>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    size="small"
                                    color="primary"
                                    onClick={() => { setAvanzarOpen(true); }}
                                    disabled={estadoActual!.avanzarDisabled()}
                                >
                                    {estadoActual!.textoAvanzar}
                                </Button>
                                <ConfirmationDialog
                                    open={avanzarOpen}
                                    setOpen={(newOpen) => { setAvanzarOpen(newOpen); }}
                                    title="Avanzar solicitud"
                                    onConfirm={avanzarSolicitud}
                                    confirmIsPrimary
                                >
                                    <DialogContentText>
                                        {estadoActual!.textoAvanzarLargo}
                                    </DialogContentText>
                                </ConfirmationDialog>
                            </>
                        }
                        {estadoActual?.descripcion === "Cancelado" &&
                            <>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    size="small"
                                    onClick={() => { setReactivarOpen(true); }}
                                >
                                    {"reactivar pedido"}
                                </Button>
                                <ConfirmationDialog
                                    open={reactivarOpen}
                                    setOpen={(newOpen) => { setReactivarOpen(newOpen); }}
                                    title="Reactivar solicitud"
                                    onConfirm={reactivarSolicitud}
                                    confirmIsPrimary
                                >
                                    <DialogContentText>
                                        {`Está seguro de que desea reactivar la solicitud?`}
                                    </DialogContentText>
                                </ConfirmationDialog>
                            </>
                        }
                    </DialogActions>
                </Dialog>
            }
        </div >
    )
}

export default BusquedaSolicitudesFarmacia
