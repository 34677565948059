import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Hidden, IconButton, Typography, withStyles, WithStyles, useTheme, Tooltip, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@material-ui/core';

import { styles } from './NavBarStyles';

import { ReactComponent as AmbulanciaIcon } from '../../../assets/icons/icon_ambulancia.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/icon_menu.svg';
import { ReactComponent as LogoMedife } from '../../../assets/logos/logos_svg/nuevo_logo_medife.svg';
import { ReactComponent as LogoCamDoctor } from '../../../assets/logos/logos_svg/logo_camdoctor_g.svg';
import { ReactComponent as LogoFinochietto } from '../../../assets/logos/logos_svg/logo_tm_finochietto_g.svg';
import LogoCamDoctorGoogle from '../../../assets/logos/camdoctor_developed_by_google_cloud.png';
import LogoGoogleCloud from '../../../assets/logos/google_cloud.png';
import User from './user/User';
import Sidebar from '../Sidebar/Sidebar';
import MiCuenta from '../MiCuenta/MiCuenta';
import { useDispatch, useSelector } from 'react-redux';
import rootReducer, { RootState } from '../../../store/rootReducer';
import { getFirestore } from '../../../db';
import { ITurnoAgendado } from '../../../Interfaces/ITurnoAgendado';

import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/51151-recordin-green-circle.json';
import { IProximaCita } from '../../../Interfaces/IProximaCita';
import { getPacientes, setCuilPacienteSeleccionado } from '../../../store/user/userSlice';
import { useHistory } from 'react-router-dom';
import { getCurrentTime, useTime } from '../../../hooks/useTime';
import { IDiaHora } from '../../../Interfaces/IDiaHora';
import { convertDateToDiaHora, convertDateToLocaleLong, convertDiaHoraToDate } from '../../../utils/dateTimeHelper';
import firebase from 'firebase';
import { setSesionLogeo, setUser, setSesionLogeoInstancia, setLog } from '../../../store/user/userSlice';
import { useLoggedIn } from '../../../hooks/useLoggedIn';

import { io, Socket } from 'socket.io-client';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import store from '../../../store/store';

interface Props {
    children: React.ReactElement;
}

function ElevationScroll(props: Props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 4,
    });
}

function ScrollSize(props: Props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window,
    });

    return React.cloneElement(children, {
        variant: trigger ? "dense" : undefined,
    });
}

const NavBar: React.FC<WithStyles<typeof styles> & Props> = (props) => {
    const { classes, children } = props
    const [isSidebarOpen, toggleSidebar] = useState(false);
    const [isMiCuentaOpen, toggleMiCuenta] = useState(false);
    const mainURL = process.env.REACT_APP_URL_MEDIFE;

    const [turnos, setTurnos] = useState<Array<ITurnoAgendado>>([]);

    const { rol, ID_GRUPO_FAMILIAR, NOMBRE, APELLIDO, matricula, NRO_CREDENCIAL, plan, email, especialidades, sesionLogeo, log, saque } = useSelector((state: RootState) => state.user)
    const { pacientesByCuil, cuilsDePacientes } = useSelector((state: RootState) => state.user);
    const planNom = rol === "PACIENTE" ? `PLAN ${plan!.nombre}` : undefined;

    const rolFinochietto = rol === "OPERADOR" || rol === "ADMINISTRADOR FINOCHIETTO";

    const db = getFirestore();

    const lastClick = useLocalStorage("LastItemClicked", 1000);
    const [socketConnection, setSocketConnection] = useState<Socket | undefined>(undefined);

    //esto se conectaba al socket siempre que estaba logueado en la navBavar
    /*useEffect(() => {
        let socket: Socket;
        if (rol === "MEDICO") {
            let stringEsps = "";
            especialidades!.forEach((esp) => {
                if(esp.consultaEspontanea){
                    stringEsps += `${esp.descripcion}, `
                }
            })
            stringEsps = stringEsps.slice(0, stringEsps.length - 2);
            const sesionLogeoNueva = uuidv4();
            dispatch(setSesionLogeo(sesionLogeoNueva));
            socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
                reconnectionDelayMax: 10000,
                secure: true,
                query: {
                    "tipo": "profesional",
                    "usuario": email,
                    "nombre": nombre,
                    "apellido": apellido,
                    "matricula": matricula!.toString(),
                    "especialidad": stringEsps,
                    "estado": 'Inicio sesion',
                    "ambiente": `${process.env.REACT_APP_URL_PACIENTES}`,
                    // "horaConexion": getCurrentTime().getTime().toString(),
                    "sesionLogeoID": sesionLogeoNueva,
                }
            });
            setSocketConnection(socket);
        }

        return () => {
            if (rol === "MEDICO") {
                socket.disconnect();
            }
        }
    }, []);*/

    useEffect(() => {
        if (rol === "MEDICO") {
            if (socketConnection) {
                if (lastClick) {
                    socketConnection!.send("UltimaActividad", lastClick);
                }
            }
        }
    }, [lastClick, socketConnection, rol]);

    useEffect(() => {
        function confirmExit() {
            dispatch(setUser({
                NOMBRE: '',
                APELLIDO: '',
                email: '',
                rol: null,
                NRO_DOCUMENTO: '',
                isUserLogin: false,
                warningSarsCov2: false,
                pacientesByCuil: {},
                cuilsDePacientes: [],
                cuilPacienteSeleccionado: -1,
                error: null,
            }));
            localStorage.removeItem("token");
            dispatch(setSesionLogeo(''))
            return "show warning";
        }
        if (rol === "MEDICO") {
            window.onbeforeunload = confirmExit;

        }
    }, [])

    useEffect(() => {
        let socket: Socket;
        if (rol === "MEDICO") {
            console.log('cambio')
            if (sesionLogeo === '') {
                console.log(socketConnection != undefined)
                if (socketConnection != undefined) {
                    socketConnection?.disconnect()
                    dispatch(setSesionLogeo(''))
                }
            } else {
                let stringEsps = "";
                especialidades!.forEach((esp) => {
                    if (esp.consultaEspontanea) {
                        stringEsps += `${esp.descripcion}, `
                    }
                })
                stringEsps = stringEsps.slice(0, stringEsps.length - 2);
                socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
                    reconnectionDelayMax: 10000,
                    secure: true,
                    query: {
                        "tipo": "profesional",
                        "usuario": email,
                        "nombre": NOMBRE,
                        "apellido": APELLIDO,
                        "matricula": matricula!.toString(),
                        "especialidad": stringEsps,
                        "ambiente": `${process.env.REACT_APP_URL_PACIENTES}`,
                        // "horaConexion": getCurrentTime().getTime().toString(),
                        "sesionLogeoID": sesionLogeo as string,
                    }
                });
                setSocketConnection(socket);
            }
        }
        return () => {
            if (rol === "MEDICO") {
                if (socket && saque) {
                    dispatch(setSesionLogeo(''))
                    console.log('saque navBar')
                }
            }
        }
    }, [sesionLogeo]);

    useEffect(() => {
        if (log) {
            onLogout()
        }
    }, [log]);

    useEffect(() => {
        if (rol === "PACIENTE") {
            dispatch(getPacientes(ID_GRUPO_FAMILIAR!));
        }
    }, []);

    const [proximasSesiones, setProximasSesiones] = useState<Array<IProximaCita>>([]);

    useEffect(
        () => {
            if (rol === "PACIENTE" && cuilsDePacientes.length > 0) {
                let unsubscribes: Array<() => void> = [];
                // let turnosTemp: Array<ITurnoAgendado> = [];

                cuilsDePacientes.forEach(
                    (cuilDePaciente, indexPaciente) => {

                        const paciente = pacientesByCuil[cuilDePaciente];
                        let unsubscribe = db.collection("IndexProximaCita").doc(paciente.CUIL.toString()).onSnapshot(
                            (datosProximaCita) => {

                                let tempProximasSesiones = [...proximasSesiones];

                                const copiaYaGuardada = tempProximasSesiones.find(x => x.paciente === paciente.CUIL.toString());
                                let indexCopiaYaGuardada = -1;
                                if (copiaYaGuardada !== undefined) {
                                    indexCopiaYaGuardada = tempProximasSesiones.indexOf(copiaYaGuardada!);
                                }

                                if (!datosProximaCita.exists) {
                                    if (indexCopiaYaGuardada !== -1) {
                                        tempProximasSesiones.splice(indexCopiaYaGuardada, 1);
                                    }
                                } else {
                                    let turno: IDiaHora = datosProximaCita!.data()!.turno;

                                    let dataProximaCita: IProximaCita = {
                                        Estado: datosProximaCita!.data()!.Estado,
                                        sesion: datosProximaCita!.data()!.sesion,
                                        turno: turno,
                                        paciente: paciente.CUIL.toString(),
                                        turnoFormateado: convertDiaHoraToDate(turno)
                                        // turnoFormateado:new Date(
                                        //     Number.parseInt(year),
                                        //     Number.parseInt(month) - 1,
                                        //     Number.parseInt(dayOfMonth),
                                        //     Number.parseInt(hour),
                                        //     Number.parseInt(minute)
                                        //)
                                    }

                                    if (indexCopiaYaGuardada === -1) {
                                        tempProximasSesiones.push(dataProximaCita);
                                    } else {
                                        tempProximasSesiones[indexCopiaYaGuardada] = dataProximaCita;
                                    }
                                }

                                setProximasSesiones(tempProximasSesiones);
                            }
                        )

                        unsubscribes.push(unsubscribe);
                    }
                )

                return () => {
                    unsubscribes.forEach((unsubscribe) => { unsubscribe() })
                }

            } else if (rol === "MEDICO") {
                const unsubscribe = db.collection("LogEventos").doc("estadoLoginProfesionales").collection(email).doc("estado").onSnapshot(
                    (snapshotDoc) => {
                        if (snapshotDoc.exists) {
                            if (snapshotDoc.data()!.logueado === false) {
                                console.log("sesion cerrada");
                                onLogout();
                            } else {
                                console.log("logueado");
                            }
                        }
                    }
                )

                return () => {
                    unsubscribe();
                }
            }
        }, [rol, cuilsDePacientes, pacientesByCuil]);

    const userComponent = (showData: boolean) => (
        <User
            plan={planNom}
            NOMBRE={NOMBRE}
            APELLIDO={APELLIDO}
            onClickHandler={() => toggleMiCuenta(true)}
            showData={showData} />
    );

    const menuComponent = (
        <IconButton
            aria-label="menú"
            onClick={() => toggleSidebar(true)}>
            <MenuIcon />
        </IconButton>
    );

    const theme = useTheme();

    const telefonoTextClass = { margin: "0px 5px 0px 5px" };

    const dispatch = useDispatch();
    const history = useHistory();
    const now = useTime(1000);

    const onLogout = async () => {
        store.dispatch({ type: "USER_LOGOUT" });
        if (rol === 'MEDICO' || rol === 'ADMINISTRADOR') {
            socketConnection?.disconnect()
            console.log(sesionLogeo);
            let respuesta = await db.collection('LogEventos').doc('profesionales').collection(email).where('log.sesionLogeoID', '==', sesionLogeo).get()
            if (respuesta.docs[0]) {
                let existente = respuesta.docs[0].data()
                existente.log.tipoSalida = 'SALIDA';
                existente.log.ultimaAccion = lastClick;
                await db.collection('LogEventos').doc('profesionales').collection(email).doc(respuesta.docs[0].id).set(existente, { merge: true })
            }
            await firebase.auth().signOut();
        }
        dispatch(setLog(false))
        localStorage.removeItem("token");
        dispatch(setUser({
            NOMBRE: '',
            APELLIDO: '',
            email: '',
            rol: null,
            NRO_DOCUMENTO: '',
            isUserLogin: false,
            warningSarsCov2: false,
            sesionLogeo: "",
            pacientesByCuil: {},
            cuilsDePacientes: [],
            cuilPacienteSeleccionado: -1,
            error: null,
        }));
        window.location.href = `${mainURL}user/logout`

        // window.location.href = `${mainURL}user/logout`
    }

    const stillLoggedIn = useLoggedIn(200);

    useEffect(() => {
        if (!stillLoggedIn) {
            history.push("/dashboard");
        }
    }, [stillLoggedIn])

    return (
        <>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar className={classes.appbar}>
                    <Toolbar className={classes.toolbar}>
                        {/* NOTAR QUE SE UTILIZA HIDDEN PARA RENDERIZAR EL HEADER */}
                        <Hidden xsDown>
                            <Box display="flex" alignItems="center">
                                {/* {menuComponent} */}
                                {
                                    rolFinochietto ?
                                        <LogoFinochietto style={{ width: "150px" }} />
                                        :
                                        <>
                                            {
                                                rol === "MEDICO" ?
                                                    <LogoCamDoctor style={{ width: 200, height: 70 }} />
                                                    :
                                                    <LogoMedife style={{ width: 240, height: 40 }} />
                                            }
                                        </>
                                }

                            </Box>
                            <Box display="flex" alignItems="right">
                                {
                                    proximasSesiones.map(
                                        (proximaSesion, index) => {
                                            let difference = (proximaSesion.turnoFormateado.getTime() - now.getTime()) / (1000 * 60);
                                            if (Math.abs(difference) < 20) {

                                                let minutesDiff = difference > 0 ? Math.ceil(difference) : -Math.floor(difference);
                                                return (
                                                    <Tooltip
                                                        arrow
                                                        title={
                                                            <>
                                                                <Typography>
                                                                    {`Tiene un turno a las ${proximaSesion.turno.horas}`}
                                                                </Typography>
                                                                <Typography>
                                                                    {`Clic para ver su turno en la agenda y/o ingresar a sala de espera.`}
                                                                </Typography>
                                                                <Typography>
                                                                    {difference > 0 ? (
                                                                        `(Faltan ${minutesDiff} minutos)`
                                                                    ) : (
                                                                        `(Estás demorado ${minutesDiff} minutos)`
                                                                    )}
                                                                </Typography>
                                                            </>
                                                        }
                                                    >
                                                        <IconButton onClick={() => {
                                                            dispatch(setCuilPacienteSeleccionado(Number.parseInt(proximaSesion.paciente)));
                                                            console.log(proximaSesion.paciente);
                                                            history.push("/dashboard/ver-turnos-agendados/agenda");
                                                        }}>
                                                            <Lottie options={{ loop: true, autoplay: true, animationData: animationData, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }} isStopped={false} isPaused={false} width={30} height={30} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                            return null;
                                        }
                                    )
                                }
                                {/* now.toLocaleTimeString() */}
                                {userComponent(true)}
                            </Box>
                        </Hidden>
                        <Hidden smUp>
                            <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow="1">
                                {/* {menuComponent} */}
                                {
                                    rolFinochietto ?
                                        <LogoFinochietto style={{ width: "150px" }} />
                                        :
                                        <>
                                            {
                                                rol === "MEDICO" ?
                                                    <LogoCamDoctor style={{ width: 200, height: 70 }} />
                                                    :
                                                    <LogoMedife style={{ width: 240, height: 40 }} />
                                            }
                                        </>
                                }
                                {userComponent(false)}
                            </Box>
                        </Hidden>
                    </Toolbar>
                    {
                        rolFinochietto || rol === "MEDICO"?
                            null
                            :
                            <>
                                <Toolbar variant="dense" className={classes.telefonoBar}>
                                    <div style={{ flexGrow: 1 }} />
                                    <AmbulanciaIcon style={telefonoTextClass} />
                                    <Typography style={{ ...telefonoTextClass, fontSize: "12px" }}>Para Urgencias y Emergencias</Typography>
                                    <Typography variant="h6" style={telefonoTextClass}>0800 333 0075</Typography>
                                </Toolbar>
                            </>
                    }
                </AppBar>
            </ElevationScroll>
            <Sidebar isOpen={isSidebarOpen} onClose={() => toggleSidebar(false)} />
            <MiCuenta
                NOMBRE={NOMBRE}
                APELLIDO={APELLIDO}
                plan={planNom}
                nroCredencial={NRO_CREDENCIAL}
                matricula={matricula}
                isOpen={isMiCuentaOpen}
                onClose={() => toggleMiCuenta(false)} />
            <Toolbar className={classes.minHeightToolbar} />
            <Container>
                <Box my={4} mb={7} >
                    {children}
                </Box>
            </Container>
            <AppBar position="fixed" style={{ backgroundColor: "white", bottom: 0, top: "auto" }}>
                <div style={{ color: "black", alignContent: "center", textAlign: "center", justifyContent: "center", width: "100%", flexGrow: 1 }}>
                    {rolFinochietto ?
                        <img src={LogoCamDoctorGoogle} style={{ margin: 4 }} />
                        :
                        <img src={LogoCamDoctorGoogle} style={{ margin: 4 }} />
                    }
                </div>
            </AppBar>
            <Dialog open={!stillLoggedIn}>
                <DialogTitle>
                    La sesión ha expirado.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Por favor inicie sesión nuevamente para continuar.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={onLogout}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withStyles(styles)(NavBar);
