import React, { ReactNode, useState } from 'react'
import { List, ListItem, Divider, WithStyles, withStyles, useTheme, Typography, Avatar, Card, CardHeader, CardContent, ListItemText, Button, TextField, InputAdornment, Grid, Chip, ListItemSecondaryAction, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CardActionArea, CardMedia, IconButton, DialogContent, Collapse, Toolbar } from '@material-ui/core';
import { styles } from './VerTurnosStyles';
import { SettingsOutlined, VideocamOutlined, PieChartOutlined, PlusOneOutlined, AddOutlined, FolderOutlined, PhoneOutlined, ArrowForwardIos, ArrowBackIos, MinimizeOutlined, CheckOutlined, PhoneForwardedOutlined, ReplyOutlined, DeleteOutlined, RemoveOutlined, KeyboardArrowUpOutlined, KeyboardArrowDownOutlined, RedoOutlined, RefreshOutlined, SearchOutlined } from '@material-ui/icons';
import Volver from '../../../../components/buttons/Volver/Volver';
import { useHistory } from 'react-router';
import { IServicio } from '../../../../Interfaces/IServicio';
import { buscarCitasFinochietto, enviarEmailRecordatorio } from '../../../../apis/finochiettoAPI';
import { IResultadoBusquedaCitaFinochietto } from '../../../../Interfaces/IResultadoBusquedaCitaFinochietto';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { DataGrid, GridApi, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { convertDateToDatestring } from '../../../../utils/dateTimeHelper';
import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import { cancelarCita } from '../../../../apis/citaProgramadaAPI';

interface ICitaListado {
    id: number;
    fecha: string;
    despachador: string;
    cuil: string;
    paciente: string;
    email: string;
    celular: string;
    motivo: string;
    especialidad: string;
    fechaTurno: string;
    profesional: string;
    sesion: string;
}

const VerTurnos: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const history = useHistory();
    const [cuilPaciente, setCuilPaciente] = React.useState("");
    const [nombrePaciente, setNombrePaciente] = React.useState("");
    const [apellidoPaciente, setApellidoPaciente] = React.useState("");
    const [operador, setOperador] = React.useState("");
    const [especialidad, setEspecialidad] = React.useState("");
    const [profesional, setProfesional] = React.useState("");
    const [datos, setDatos] = useState<Array<IResultadoBusquedaCitaFinochietto>>([]);
    const [citas, setCitas] = useState<Array<ICitaListado>>([]);
    const [filtros, setFiltros] = useState(false);

    const [fechaDesde, setFechaDesde] = useState<Date | null>(null);
    const [fechaHasta, setFechaHasta] = useState<Date | null>(null);

    const [dialogResendOpen, setDialogResendOpen] = useState<Array<boolean>>([]);
    const [dialogCancelOpen, setDialogCancelOpen] = useState<Array<boolean>>([]);

    //const [updateState, setUpdateState] = useState(false);

    const onItemClicked = (servicio: IServicio) => {
        history.push(servicio.to)
    }

    React.useEffect(() => {
        onBuscar(true);
    }, [])

    React.useEffect(() => {
        if (datos.length > 0) {
            let listaCitas: Array<ICitaListado> = []
            let listaResend: Array<boolean> = []
            let listaCancel: Array<boolean> = []

            datos.forEach((dato, iDato) => {
                const fecha = dato.datosCita.fecha ? dato.datosCita.fecha : dato.datosCita.fechaCreacion;

                let fechaTurnoSeparada = dato.datosCita.turno.día.split("/");
                fechaTurnoSeparada.reverse();
                const fechaTurno = `${fechaTurnoSeparada[0]}/${fechaTurnoSeparada[1]}/${fechaTurnoSeparada[2]}`;
                let fechaCreacionSeparada = convertDateToDatestring(new Date(fecha!._seconds * 1000)).split("/");
                fechaCreacionSeparada.reverse();
                const fechaCreacion = `${fechaCreacionSeparada[0]}/${fechaCreacionSeparada[1]}/${fechaCreacionSeparada[2]}`;
                listaCitas.push({
                    id: iDato,
                    fecha: fechaCreacion,
                    despachador: dato.datosCita.apellidoOperador + ", " + dato.datosCita.nombreOperador,
                    cuil: dato.paciente.paciente.cuil!,
                    paciente: dato.paciente.paciente.apellido + ", " + dato.paciente.paciente.nombre,
                    email: dato.paciente.paciente.email,
                    celular: dato.paciente.paciente.celular,
                    motivo: dato.datosCita.motivo,
                    especialidad: dato.datosCita.especialidad,
                    fechaTurno: fechaTurno + " " + dato.datosCita.turno.horas,
                    profesional: dato.datosCita.apellidoProfesional + ", " + dato.datosCita.nombreProfesional,
                    sesion: dato.datosCita.sesion,
                })

                listaResend.push(false);
                listaCancel.push(false);
            })

            setCitas(listaCitas);
            setDialogResendOpen(listaResend);
            setDialogCancelOpen(listaCancel);
        }
    }, [datos])

    const onBuscar = (showLoading: boolean) => {
        if (showLoading) {
            setLoading(true);
        }
        let hasta = new Date();
        let desde = new Date();
        desde.setDate(hasta.getDate() - 1);
        if (fechaDesde && fechaHasta) {
            desde = fechaDesde;
            hasta = fechaHasta;
        }
        buscarCitasFinochietto(especialidad, profesional, desde, hasta, nombrePaciente, apellidoPaciente, operador, cuilPaciente)
            .then((result) => {
                setDatos(result);
                console.log(result);
                setLoading(false);
            })
            .catch(e => console.log(e))
    }

    const columns: GridColDef[] = [
        //     id: number;
        // fecha: string;
        // despachador: string;
        // cuil: string;
        // paciente: string;
        // email: string;
        // celular: string;
        // motivo: string;
        // especialidad: string;
        // fechaTurno: string;
        // profesional: string;
        {
            field: 'sesion',
            headerName: " ",
            sortable: false,
            filterable: false,
            width: 0,
            minWidth: 0,
            hideSortIcons: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <></>
            },
            renderHeader: (params) => {
                return <></>
            }
        },
        {
            field: 'fecha',
            headerName: 'Creada',
            filterable: false,
            hide: true,
            minWidth: 120,
        },
        {
            field: 'despachador',
            headerName: 'Operador',
            sortable: false,
            filterable: false,
            hide: true,
            minWidth: 200,
        },
        {
            field: 'cuil',
            headerName: 'Cuil',
            sortable: false,
            filterable: false,
            hide: true,
            minWidth: 150,
        },
        {
            field: 'paciente',
            headerName: 'Paciente',
            sortable: false,
            filterable: false,
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: false,
            filterable: false,
            hide: true,
            minWidth: 200,
        },
        {
            field: 'celular',
            headerName: 'Celular',
            sortable: false,
            filterable: false,
            hide: true,
            minWidth: 150,
        },
        {
            field: 'motivo',
            headerName: 'Motivo',
            sortable: false,
            filterable: false,
            hide: true,
            minWidth: 200,
        },
        {
            field: 'especialidad',
            headerName: 'Especialidad',
            sortable: false,
            filterable: false,
            minWidth: 200,
        },
        {
            field: 'fechaTurno',
            headerName: 'Turno',
            filterable: false,
            minWidth: 150,
        },
        {
            field: 'profesional',
            headerName: 'Profesional',
            sortable: false,
            filterable: false,
            minWidth: 200,
        },
        {
            field: "resend",
            headerName: " ",
            sortable: false,
            filterable: false,
            width: 70,
            renderCell: (params) => {
                const setOpen = (open: boolean) => {
                    let temp = [...dialogResendOpen];
                    temp[params.getValue(params.id, `id`) as number] = open;
                    setDialogResendOpen(temp);
                }

                const enviar = () => {
                    setLoading(true);
                    enviarEmailRecordatorio(params.getValue(params.id, `cuil`) as string, params.getValue(params.id, `sesion`) as string)
                        .then((datoRecibido) => {
                            setLoading(false);
                            return alert(`Reenvio exitoso`);
                        })
                        .catch(e => console.log(e))
                }

                return (
                    <>
                        <IconButton onClick={() => { setOpen(true) }}><RedoOutlined /></IconButton>
                        <ConfirmationDialog
                            open={dialogResendOpen[params.getValue(params.id, `id`) as number]}
                            setOpen={setOpen}
                            onConfirm={enviar}
                            title="Reenviar aviso"
                        >
                            <Typography>
                                Desea reenviar el aviso de cita al paciente {params.getValue(params.id, `paciente`)}?
                            </Typography>
                        </ConfirmationDialog>
                    </>
                );
            }
        },
        {
            field: "cancel",
            headerName: " ",
            sortable: false,
            filterable: false,
            width: 70,
            renderCell: (params) => {
                const setOpen = (open: boolean) => {
                    let temp = [...dialogCancelOpen];
                    temp[params.getValue(params.id, `id`) as number] = open;
                    setDialogCancelOpen(temp);
                }

                const enviar = () => {
                    setLoading(true);
                    cancelarCita({ usuario: params.getValue(params.id, `cuil`) as string, sesion: params.getValue(params.id, `sesion`) as string })
                        .then((datoRecibido) => {
                            setLoading(false);
                            onBuscar(true);
                            return alert(`Cancelacion exitosa`);
                        })
                        .catch(e => console.log(e))
                }

                return (
                    <>
                        <IconButton onClick={() => { setOpen(true) }}><DeleteOutlined /></IconButton>
                        <ConfirmationDialog
                            open={dialogCancelOpen[params.getValue(params.id, `id`) as number]}
                            setOpen={setOpen}
                            onConfirm={enviar}
                            title="Cancelar cita"
                        >
                            <Typography>
                                Desea cancelar la cita del paciente {params.getValue(params.id, `paciente`)}?
                            </Typography>
                        </ConfirmationDialog>
                    </>
                );
            }
        },
        {
            field: 'id',
            headerName: " ",
            sortable: false,
            filterable: false,
            width: 0,
            minWidth: 0,
            hideSortIcons: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <></>
            },
            renderHeader: (params) => {
                return <></>
            }
        },
    ];

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                    <CardHeader
                        avatar={<Avatar><FolderOutlined /></Avatar>}
                        title={<Typography variant="h6">Turnos</Typography>}
                        action={
                            <Toolbar>

                                <Button
                                    style={{ margin: 5 }}
                                    variant="contained"
                                    disableElevation
                                    onClick={() => { onBuscar(true) }}
                                    endIcon={<RefreshOutlined />}
                                >
                                    Actualizar
                                </Button>
                                <Button
                                    style={{ margin: 5 }}
                                    variant="contained"
                                    disableElevation
                                    onClick={() => { setFiltros(!filtros) }}
                                    endIcon={filtros ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                                >
                                    Filtros
                                </Button>
                            </Toolbar>
                        }
                    />
                    <Divider />
                    <Collapse in={filtros} timeout="auto" unmountOnExit>
                        <CardContent style={{ backgroundColor: theme.paletaColores.fondo.primero }}>
                            <Toolbar variant="dense">
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    label="Desde"
                                    value={fechaDesde}
                                    onChange={(event) => {
                                        setFechaDesde(event as Date)
                                    }}
                                    required
                                    defaultValue={null}
                                    disableFuture
                                    invalidDateMessage="Fecha inválida"
                                    maxDateMessage="La fecha debe ser anterior a hoy"
                                    InputAdornmentProps={{ style: { visibility: "hidden" } }}
                                    inputVariant="outlined"
                                    style={{ margin: 5, maxWidth: 150 }}
                                    size="small"
                                />
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    label="Hasta"
                                    value={fechaHasta}
                                    onChange={(event) => {
                                        setFechaHasta(event as Date)
                                    }}
                                    required
                                    defaultValue={null}
                                    disableFuture
                                    invalidDateMessage="Fecha inválida"
                                    maxDateMessage="La fecha debe ser anterior a hoy"
                                    InputAdornmentProps={{ style: { visibility: "hidden" } }}
                                    inputVariant="outlined"
                                    style={{ margin: 5, maxWidth: 150 }}
                                    size="small"
                                />

                                <TextField
                                    value={especialidad}
                                    label="Especialidad"
                                    onChange={(event) => {
                                        setEspecialidad(event.target.value as string);
                                    }}
                                    variant="outlined"
                                    style={{ margin: 5, maxWidth: 300 }}
                                    size="small"
                                />
                                <TextField
                                    value={operador}
                                    label="Operador"
                                    onChange={(event) => {
                                        setOperador(event.target.value as string);
                                    }}
                                    variant="outlined"
                                    style={{ margin: 5, maxWidth: 300 }}
                                    size="small"
                                />
                                <div style={{ flexGrow: 1 }} />
                            </Toolbar>
                            <Toolbar variant="dense">
                                <TextField
                                    value={cuilPaciente}
                                    label="CUIL Paciente"
                                    onChange={(event) => {
                                        setCuilPaciente(event.target.value as string);
                                    }}
                                    variant="outlined"
                                    style={{ margin: 5, maxWidth: 150 }}
                                    size="small"
                                />
                                <TextField
                                    value={apellidoPaciente}
                                    label="Apellido Paciente"
                                    onChange={(event) => {
                                        setApellidoPaciente(event.target.value as string);
                                    }}
                                    variant="outlined"
                                    style={{ margin: 5, maxWidth: 350 }}
                                    size="small"
                                />
                                <TextField
                                    value={nombrePaciente}
                                    label="Nombre Paciente"
                                    onChange={(event) => {
                                        setNombrePaciente(event.target.value as string);
                                    }}
                                    variant="outlined"
                                    style={{ margin: 5, maxWidth: 350 }}
                                    size="small"
                                />
                                <div style={{ flexGrow: 1 }} />

                                <Button
                                    disabled={loading}
                                    onClick={() => { onBuscar(true) }}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<SearchOutlined />}
                                >
                                    BUSCAR
                                </Button>
                            </Toolbar>
                        </CardContent>
                    </Collapse>
                    <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                        {
                            loading ?
                                (
                                    <div style={{ margin: "50px 0px 50px 0px" }}>
                                        <LoadingBubbles />
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <DataGrid
                                            style={{ backgroundColor: theme.paletaColores.fondo.primero }}
                                            rows={citas}
                                            columns={columns}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            autoHeight
                                            disableColumnFilter
                                        />
                                    </div>
                                )
                        }
                    </CardContent>
                </Card>
                <div className={classes.buttonsRoot}>
                    <Volver onClickHandler={() => { history.goBack() }} />
                </div>
            </MuiPickersUtilsProvider>
        </>
    )
}

export default withStyles(styles)(VerTurnos)
