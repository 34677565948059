import React, { useEffect, useState } from 'react'
import useQuery from '../../../hooks/useQuery'
import { getIdGF, obtenerDatosUsuarioCs } from '../../../apis/pacientesAPI'
import { setUser } from '../../../store/user/userSlice';
import { useDispatch } from 'react-redux';
import LoadingBubbles from '../../../components/LoadingBubbles/LoadingBubbles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import jwt_decode from "jwt-decode";
import { IDecodedToken } from '../../../Interfaces/IDecodedToken';
import { resetPacientes } from '../../../store/user/userSlice';
import { resetPacientes as resetPacientesAtender } from '../../../store/profesional-medico/atender-consultorio/atenderConsultorioSlice';
import { login } from '../../../login/login';
import { decode } from 'jsonwebtoken';

type Color = 'success' | 'info' | 'warning' | 'error';

const IngresoAsociado: React.FC<any> = () => {
    const query = useQuery();

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);
    const [loadTitle, setLoadTitle] = useState('Cargando...');
    const [loadDesc, setLoadDesc] = useState('.');
    const [severity, setSeverity] = useState('error' as Color);

    const dispatch = useDispatch();

    const resetDatosPacientes = () => {
        resetPacientes();
        resetPacientesAtender();
    }

    useEffect(() => {
        console.log(query.get('cs')!);

        resetDatosPacientes();

        setLoading(true);
        setLoadTitle('Ingresando');
        setLoadDesc('Obteniendo tus datos para ingresar a Cam Doctor, por favor aguarda unos segundos');
        setSeverity('info');
        obtenerDatosUsuarioCs(query.get("cs")!)
            .then(
                (datosDevueltos) => {
                    const nombreSeparado = datosDevueltos.datos_medife.a.nombre.split(", ");
                    const dni = datosDevueltos.datos_medife.a.vi;
                    console.log(datosDevueltos);

                    const bearerToken = datosDevueltos.token;
                    localStorage.setItem('token', bearerToken);
                    const decodedToken: IDecodedToken = jwt_decode(bearerToken);

                    datosDevueltos.datos_medife.a.plan.nombre = decodedToken.claims.plan;
                    setLoadTitle('Ingresando');
                    setLoadDesc('Ya tenemos todo, estas por ingresar.');
                    setSeverity('success');

                    getIdGF(dni)
                        .then((idGfRecibido) => {
                            setLoading(false);
                            login(nombreSeparado[1], nombreSeparado[0], datosDevueltos.email, "PACIENTE", [], undefined, datosDevueltos.datos_medife.a.plan, idGfRecibido.idGF, datosDevueltos.datos_medife.a.nro_credencial, dni);
                        })
                        .catch(e => {
                            console.log(e);
                            setErr(true);
                            setLoading(false);
                        })

                }
            )
            .catch(e => {
                console.log(e);
                setErr(true);
                setLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ margin: "45vh 0vh 45vh" }}>
            {loading && <LoadingBubbles />}
            <Container>
                {
                    !loading &&
                    err &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                    Hubo un error al loguear, verifique el codigo proporcionado e intente nuevamente.
                    <div style={{ margin: "10px 0px 10px 0px" }}>
                            <Button variant="contained" href="https://medife.com.ar" color="primary">
                                Volver
                        </Button>
                        </div>
                    </Alert>
                }
                {
                    loading &&
                    !err &&
                    <Alert severity={severity}>
                        <AlertTitle>{loadTitle}</AlertTitle>
                        {loadDesc}
                    </Alert>
                }
            </Container>
        </div>
    )
}



export default IngresoAsociado as React.ComponentType;
