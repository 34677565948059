import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { IPaciente } from '../../../Interfaces/IPaciente';
import { IEspecialidad } from '../../../Interfaces/IEspecialidad';

interface IadministrarSintomasState {
    especialidades: Array<IEspecialidad>;
    paso: number;
    error: string | null;
}

let initialState: IadministrarSintomasState = {
    especialidades: [],
    paso: 0,
    error: null,
}

const administrarSintomasSlice = createSlice({
    name: "administrarSintomas",
    initialState,
    reducers: {
        setPaso(state, action: PayloadAction<number>) {
            state.paso = action.payload
        },
        setEspecialidades(state, action: PayloadAction<Array<IEspecialidad> | undefined>) {
            if (action.payload) {
                state.especialidades = action.payload;
            } else {
                state.especialidades = [];
            }
        }
    }
});


export const {
    setPaso,
    setEspecialidades
} = administrarSintomasSlice.actions;

export default administrarSintomasSlice.reducer;