import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import EstadisticasGuardia from '../../../pages/Administrador/AdministrarGuardia/EstadisticasGuardia/EstadisticasGuardia';
import EstadoGuardia from '../../../pages/Administrador/AdministrarGuardia/EstadoGuardia/EstadoGuardia';
import MenuGestionTurnos from '../../../pages/OperadorFinochietto/GestionarTurnos/MenuGestionTurnos/MenuGestionTurnos';
import NuevoTurno from '../../../pages/OperadorFinochietto/GestionarTurnos/NuevoTurno/NuevoTurno';
import VerTurnos from '../../../pages/OperadorFinochietto/GestionarTurnos/VerTurnos/VerTurnos';
import { createThemeFinochietto } from '../../../theme/AppTheme';


const GestionarTurnos = () => {
    const { path, url } = useRouteMatch();
    const themeFinochietto = createThemeFinochietto();
    return (
        <ThemeProvider theme={themeFinochietto}>
            <Switch>
                <PrivateRoute component={MenuGestionTurnos} path={path} exact />
                <PrivateRoute component={NuevoTurno} path={`${path}/nuevo`} />
                <PrivateRoute component={VerTurnos} path={`${path}/turnos`} />
                {/* <PrivateRoute component={EstadisticasGuardia} path={`${path}/estadisticas`} /> */}
                {/* <PrivateRoute component={ListadoPacientesEspera} path={`${path}/listado-consultas`} /> */}
            </Switch>
        </ThemeProvider>
    )
}

export default GestionarTurnos