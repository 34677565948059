import axios from "axios";
import { IUsuarioSesion } from "../Interfaces/IUsuarioSesion";
import firebase from "firebase";
import { IProfesionalPromedio } from "../Interfaces/IProfesionalPromedio";
import { IProfesionalValoraciones } from "../Interfaces/IProfesionalValoraciones";

const valoracionApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    "Authorization": '',
  },
});

valoracionApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem('token');
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers['Authorization'] = 'bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);
valoracionApi.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.setItem("LoggedIn", "false");
  }
  return error;
});

export async function cargarEncuestaPaciente(usuarioSesion: IUsuarioSesion, califMedico: number, califPlatf: number, comentMedico: string, comentPlatf: string) {
  const obj = {
    usuario: usuarioSesion.usuario,
    sesion: usuarioSesion.sesion,
    preguntas: [
      {
        pregunta: "Calificá el profesional",
        nota: comentMedico,
        calificacion: califMedico
      },
      {
        pregunta: "Por favor, calificá la plataforma",
        nota: comentPlatf,
        calificacion: califPlatf
      }
    ]
  }
  const { data } = await valoracionApi.post(`cargarEncuesta`, obj);
  return data;
}

export async function obtenerValoracionProfProm20(apellidoProfesional?: string, valMin?: number, valMax?: number): Promise<Array<IProfesionalPromedio>> {

  let request = `obtenerValoracionProfProm20`;
  let ampersand = false;

  if (apellidoProfesional) {
    request += `?apellidoProfesional=${apellidoProfesional!}`;
    ampersand = true;
  }
  if (valMin) {
    request += `${ampersand ? `&` : `?`}valMin=${valMin!}`
    ampersand = true;
  }
  if (valMax) {
    request += `${ampersand ? `&` : `?`}valMax=${valMax!}`
    ampersand = true;
  }

  const { data } = await valoracionApi.get(request);
  return data;
}

export async function viewOfCommentsByProf(emailProfesional: string): Promise<IProfesionalValoraciones> {

  let request = `viewOfCommentsByProf?email=${emailProfesional}`;
  const { data } = await valoracionApi.get(request);
  return data;
}

export async function aplicarValoracion(idSesion: string, newEstado: boolean): Promise<string> {

  let request = `aplicarValoracion`;
  const { data } = await valoracionApi.post(request, {sesion: idSesion, estado: newEstado});
  return data;
}

export async function cargarEncuestaMedico(emailMedico: string, sesion: string, califPaciente: number, califPlatf: number, comentPaciente: string, comentPlatf: string) {
  const obj = {
    usuario: emailMedico,
    sesion: sesion,
    preguntas: [
      {
        pregunta: "Califique al paciente",
        nota: comentPaciente,
        calificacion: califPaciente
      },
      {
        pregunta: "Por favor, calificá la plataforma",
        nota: comentPlatf,
        calificacion: califPlatf
      }
    ]
  }
  const { data } = await valoracionApi.post(`cargarEncuesta`, obj);
  return data;
}

//export default pacientesApi;
