import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid, Paper, Button,
    Typography, Dialog, DialogActions, DialogContent, DialogContentText, useTheme, Divider, DialogTitle, TextField
} from '@material-ui/core';
import { cargarEncuestaPaciente, registrarEgresoPaciente } from '../../../../../apis/citaInstantaneaAPI'
import { getFirestore } from '../../../../../db';
import Rating from '@material-ui/lab/Rating';
import LoadingBubbles from '../../../../../components/LoadingBubbles/LoadingBubbles';

interface OwnProps {
    cuil: string;
    sesion: string;
    nombre: string;
    apellido: string;
}

const EncuestaPacienteFinochietto = (props: OwnProps) => {
    const history = useHistory();
    const theme = useTheme();
    const { cuil, sesion, nombre, apellido } = props;
    const [loading, setLoading] = useState(false);
    const [enviado, setEnviado] = useState(false);

    const [valueRatingProf, setValueRatingProf] = useState(0);
    const [valueRatingPlat, setValueRatingPlat] = useState(0);
    const [commentRatingProf, setCommentRatingProf] = useState("");
    const [commentRatingPlat, setCommentRatingPlat] = useState("");

    const db = getFirestore();

    const enviarEncuesta = () => {
        setLoading(true);
        setEnviado(true);
        cargarEncuestaPaciente({ usuario: cuil, sesion: sesion }, valueRatingProf, valueRatingPlat, commentRatingProf, commentRatingPlat)
            .then(
                (recibido) => {
                    registrarEgresoPaciente({ usuario: cuil, sesion: sesion })
                        .then((rec) => {
                            setLoading(false);
                        })
                        .catch(e => { console.log(e); })
                }
            )
            .catch(e => { console.log(e) })
    }

    if (enviado) return (
        <Dialog open={true}>
            <DialogContent>
                {loading ?
                    (
                        <LoadingBubbles />
                    )
                    :
                    (
                        <DialogContentText>
                            <Typography>
                                Consulta finalizada
                            </Typography>
                            <Typography>
                                Cierre la pestaña para salir
                            </Typography>
                        </DialogContentText>
                    )}
            </DialogContent>
        </Dialog >
    )

    return (
        <Dialog open={true}>
            <DialogTitle>
                El profesional ha finalizado su consulta.
                </DialogTitle>
            <DialogContent>
                <Typography paragraph>
                    En breves instantes recibirás en tu casilla de correo las indicaciones del médico. Muchas gracias.
                    </Typography>
                <Typography paragraph>
                    Por favor calificá tu experiencia en la plataforma
                    </Typography>
                <Paper style={{ padding: 10, margin: 10 }}>
                    <Typography>
                        Calificacion del Profesional:
                        </Typography>
                    <Rating
                        value={valueRatingProf}
                        size="large"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setValueRatingProf(newValue!);
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Comentarios"
                        value={commentRatingProf}
                        multiline
                        onChange={(event) => { setCommentRatingProf(event.target.value as string) }}
                    />
                </Paper>
                <Paper style={{ padding: 10, margin: 10 }}>

                    <Typography>
                        Calificacion de la Plataforma:
                        </Typography>
                    <Rating
                        value={valueRatingPlat}
                        size="large"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setValueRatingPlat(newValue!);
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Comentarios"
                        multiline
                        value={commentRatingPlat}
                        onChange={(event) => { setCommentRatingPlat(event.target.value as string) }}
                    />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    disabled={valueRatingPlat === 0 || valueRatingProf === 0 || valueRatingPlat === undefined || valueRatingProf === undefined}
                    onClick={enviarEncuesta}
                >
                    Enviar
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EncuestaPacienteFinochietto
