import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import ContainerAdministrarCalificacionProf from '../../../pages/Administrador/AdministrarCalificacionesProfesionales/ContainerAdministrarCalificacionProf/ContainerAdministrarCalificacionProf';

const AdministrarCalificacionesProfesionales = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Redirect from={path} to={`${url}/administrar-calificaciones-profesionales`} exact />
            <PrivateRoute component={ContainerAdministrarCalificacionProf} path={`${path}/administrar-calificaciones-profesionales`} />
        </Switch>
    )
}

export default AdministrarCalificacionesProfesionales