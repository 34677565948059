import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Grid, Paper, Button,
    Typography, Dialog, DialogActions, DialogContent, DialogContentText, useTheme, Divider, DialogTitle, TextField
} from '@material-ui/core';


import { RootState } from '../../../../store/rootReducer';
import { cargarEncuestaPaciente, registrarEgresoPaciente, borrarArchivo } from '../../../../apis/citaInstantaneaAPI'
import CardArchivoSubido from '../../../../components/CardArchivoSubido/CardArchivoSubido';
import { IArchivoSubido } from '../../../../Interfaces/IArchivoSubido';
import Jitsi from '../../../../components/jitsi/Jitsi';
import Turn from '../../../../components/turn/Turn';
import { getFirestore } from '../../../../db';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import FileUploadModule from '../../../../components/FileUploadModule/FileUploadModule';
import Rating from '@material-ui/lab/Rating';
import { io } from 'socket.io-client';
import { useBeforeunload } from "react-beforeunload";
import { getCurrentTime } from '../../../../hooks/useTime';


const Consultorio: React.FC = (props) => {
    const history = useHistory();
    const { idSalaVideollamada, especialidad } = useSelector(
        (state: RootState) => state.verMedicoOnline
    )
    const { pacientesByCuil, cuilPacienteSeleccionado } = useSelector(
        (state: RootState) => state.user
    )
    const paciente = pacientesByCuil[cuilPacienteSeleccionado];
    const [dialogFinalizarOpen, setDialogFinalizarOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [listaArchivosSubidos, setListaArchivosSubidos] = useState<IArchivoSubido[]>([]);
    const [listaArchivosProfesional, setListaArchivosProfesional] = useState<IArchivoSubido[]>([]);
    const [finalizada, setFinalizada] = useState(false);

    const [valueRatingProf, setValueRatingProf] = useState(0);
    const [valueRatingPlat, setValueRatingPlat] = useState(0);
    const [commentRatingProf, setCommentRatingProf] = useState("");
    const [commentRatingPlat, setCommentRatingPlat] = useState("");

    const onDeleteFile = (linkArchivo: string) => {
        borrarArchivo(idSalaVideollamada, cuilPacienteSeleccionado.toString(), linkArchivo, "paciente")
            .then((datosrecibidos) => {
                var temp = [...listaArchivosSubidos];
                temp = temp.filter(x => x.linkArchivo !== linkArchivo);
                setListaArchivosSubidos(temp);
            })
            .catch(e => { console.log(e); })
    }

    useBeforeunload((event) => {
        event.preventDefault();
    })

    const db = getFirestore();

    useEffect(() => {
        let unsubscribeFilesProfesional: () => void = db.collection("pacientes").doc(cuilPacienteSeleccionado!.toString()).collection("Consultas").doc(idSalaVideollamada).collection("SubidoProfesional").onSnapshot(
            (archivosEnLaNube) => {
                let archivosTemp: Array<IArchivoSubido> = [];
                archivosEnLaNube.forEach((archivo) => {
                    let nombreArchivoParts: string[] = archivo.data().file.split("/");
                    let nombreArchivo = nombreArchivoParts[nombreArchivoParts.length - 1];
                    console.log(archivo.data().origen);
                    archivosTemp.push({
                        file: nombreArchivo,
                        descripcion: archivo.data().descripcion,
                        linkArchivo: archivo.data().linkArchivo ? archivo.data().linkArchivo : "",
                        fecha: archivo.data().fecha
                    });
                });
                setListaArchivosProfesional(archivosTemp);
            }
        );

        const unsubscribeEvent = db
            .collection("CitasInstantaneas")
            .doc(especialidad)
            .collection("sesiones")
            .doc(idSalaVideollamada)
            .onSnapshot(
                (datosSesion) => {
                    if (!datosSesion.exists) {
                        console.log("La sesion no existe");
                        return;
                    }
                    if (datosSesion!.data()!.finBtn) {
                        onFinalizarLlamada();
                        return;
                    }
                    if (datosSesion!.data()!.estado === "C") {
                        setLoading(true);
                        alert("Su cita ha sido cancelada.\nSerá redirigido a la página principal.");
                        registrarEgresoPaciente({ usuario: cuilPacienteSeleccionado.toString(), sesion: idSalaVideollamada })
                            .then(
                                (data) => {
                                    console.log(data);
                                    setLoading(false);
                                    history.push("/dashboard");
                                }
                            )
                            .catch(e => { console.log(e) })
                    } else if (datosSesion!.data()!.estado === "P") {
                        setLoading(true);
                        alert("El profesional lo derivó.\nSerá redirigido a la sala de espera.");
                        registrarEgresoPaciente({ usuario: cuilPacienteSeleccionado.toString(), sesion: idSalaVideollamada })
                            .then(
                                (data) => {
                                    console.log(data);
                                    setLoading(false);
                                    history.push("/dashboard/ver-medico-online/espera");
                                }
                            )
                            .catch(e => { console.log(e) })
                    } else if (datosSesion!.data()!.estado === "T") {
                        setLoading(true);
                        registrarEgresoPaciente({ usuario: cuilPacienteSeleccionado.toString(), sesion: idSalaVideollamada })
                            .then(
                                (data) => {
                                    console.log(data);
                                    setLoading(false);
                                    setFinalizada(true);
                                }
                            )
                            .catch(e => { console.log(e) })
                    } else {
                        console.log(`El estado es ${datosSesion!.data()!.estado}`);
                    }
                }
            )

        const socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
            reconnectionDelayMax: 10000,
            secure: true,
            query: {
                "tipo": "paciente",
                "usuario": cuilPacienteSeleccionado.toString(),
                "sesion": idSalaVideollamada,
                "nombre": pacientesByCuil[cuilPacienteSeleccionado].NOMBRE,
                "apellido": pacientesByCuil[cuilPacienteSeleccionado].APELLIDO,
                "especialidad": especialidad,
                "credencial": pacientesByCuil[cuilPacienteSeleccionado].NRO_CREDENCIAL,
                "estado": `Atendido`,
                "ambiente": `${process.env.REACT_APP_URL_PACIENTES}`,
                // "horaConexion": getCurrentTime().getTime().toString()
            }
        });

        return () => {
            unsubscribeEvent();
            unsubscribeFilesProfesional();
            socket.disconnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFinalizarLlamada = async () => {
        if (!finalizada) {
            setFinalizada(true);
            setLoading(true);
            const usuarioSesion = { usuario: cuilPacienteSeleccionado.toString(), sesion: idSalaVideollamada };
            let data = await registrarEgresoPaciente(usuarioSesion);
            console.log(data);
            setLoading(false);
        }
    }

    const theme = useTheme();

    if (loading) {
        return (
            <LoadingBubbles />
        )
    }

    const enviarEncuesta = () => {
        setLoading(true);
        cargarEncuestaPaciente({ usuario: cuilPacienteSeleccionado.toString(), sesion: idSalaVideollamada }, valueRatingProf, valueRatingPlat, commentRatingProf, commentRatingPlat)
            .then(
                (recibido) => {
                    setLoading(false);
                    history.push("/dashboard");
                }
            )
            .catch(e => { console.log(e) })
    }

    if (finalizada) {
        return (
            <Dialog open={finalizada}>
                <DialogTitle>
                    Su consulta ha finalizado.
                </DialogTitle>
                <DialogContent>
                    <Typography paragraph>
                        Gracias por utilizar CamDoctor, en breves instantes recibirás en tu casilla de correo las indicaciones del médico.
                    </Typography>
                    <Typography paragraph>
                        Por favor calificá tu experiencia en la plataforma
                    </Typography>
                    <Paper style={{ padding: 10, margin: 10 }}>
                        <Typography>
                            Calificacion del Profesional:
                        </Typography>
                        <Rating
                            value={valueRatingProf}
                            size="large"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setValueRatingProf(newValue!);
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Comentarios"
                            value={commentRatingProf}
                            multiline
                            onChange={(event) => { setCommentRatingProf(event.target.value as string) }}
                        />
                    </Paper>
                    <Paper style={{ padding: 10, margin: 10 }}>

                        <Typography>
                            Calificacion de la Plataforma:
                        </Typography>
                        <Rating
                            value={valueRatingPlat}
                            size="large"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setValueRatingPlat(newValue!);
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Comentarios"
                            multiline
                            value={commentRatingPlat}
                            onChange={(event) => { setCommentRatingPlat(event.target.value as string) }}
                        />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        disabled={valueRatingPlat === 0 || valueRatingProf === 0 || valueRatingPlat === undefined || valueRatingProf === undefined}
                        onClick={enviarEncuesta}
                    >
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid container item xs={12} md={8} lg={9} alignItems="flex-start" justify="flex-start" direction="column" >
                <Grid item style={{ width: "100%" }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '75vh' }}>
                        <Jitsi
                            roomName={idSalaVideollamada}
                            displayName={`${paciente.NOMBRE} ${paciente.APELLIDO}`}
                            showHangup
                            showChat={true}
                            showFullscreen={true}
                            onFinalizarBtn={onFinalizarLlamada}
                        />
                    </div>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    <Button
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={() => { setDialogFinalizarOpen(true) }}
                        style={theme.buttons.cancelButton}
                        fullWidth
                    >
                        Finalizar llamada
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={4} lg={3} direction="column">
                <Paper style={{ flex: 1, flexGrow: 1, maxWidth: 238 }}>
                    <Grid container direction="column" spacing={1} >
                        <Grid container item xs={12} style={{ padding: "10px" }} justify="center">
                            <FileUploadModule
                                usuario={cuilPacienteSeleccionado.toString()}
                                sesion={idSalaVideollamada}
                                onCompletedUploading={
                                    (newFile) => {
                                        let temp = [...listaArchivosSubidos];
                                        temp.push(newFile);
                                        setListaArchivosSubidos(temp)
                                    }
                                }
                            />
                        </Grid>
                    </Grid>
                    {listaArchivosSubidos.length === 0 && listaArchivosProfesional.length === 0 ? (<></>) : (
                        <Grid>
                            <Grid item container xs={12} justify-content="flex-start" direction="column" >
                                <Divider />
                                {listaArchivosSubidos.length === 0 ? null : (
                                    <>
                                        <Typography variant="h6" align="center" style={{ color: theme.paletaColores.naranja.tercero, marginTop: 10 }}>Archivos del Paciente</Typography>
                                        {listaArchivosSubidos.map((archivo, index) => {
                                            let { file, descripcion, linkArchivo } = archivo;
                                            return (
                                                <CardArchivoSubido canDelete={true} onDelete={
                                                    () => {
                                                        onDeleteFile(linkArchivo);
                                                    }
                                                } key={index} fileName={file} description={descripcion} downloadLink={linkArchivo} />
                                            )
                                        }
                                        )}
                                        <Divider />
                                    </>
                                )}
                                {listaArchivosProfesional.length === 0 ? null : (
                                    <>
                                        <Typography variant="h6" align="center" style={{ color: theme.paletaColores.naranja.tercero, marginTop: 10 }}>Archivos del Profesional</Typography>
                                        {listaArchivosProfesional.map((archivo, index) => {
                                            let { file, descripcion, linkArchivo } = archivo;
                                            return (
                                                <CardArchivoSubido key={index} fileName={file} description={descripcion} downloadLink={linkArchivo} />
                                            )
                                        }
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container xs={12} direction="column" style={{ flexGrow: 1, flex: 1 }} />

                </Paper>
            </Grid>
            <Dialog open={dialogFinalizarOpen} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText>
                        <Typography paragraph>¿Está seguro que desea finalizar la consulta?</Typography>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disableElevation onClick={onFinalizarLlamada}>
                        Si
                    </Button>
                    <Button variant="contained" disableElevation onClick={() => { setDialogFinalizarOpen(false) }}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default Consultorio
