import React, { useEffect, useState } from 'react';
import { Toolbar, List, ListItem, Divider, WithStyles, withStyles, useTheme, Typography, Avatar, Card, CardHeader, CardContent, ListItemText, Button, TextField, InputAdornment, Grid, Chip, ListItemSecondaryAction, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { styles } from './EstadoGuardiaStyles';
import Volver from '../../../../components/buttons/Volver/Volver';
import { SettingsOutlined, SearchOutlined, CancelOutlined, MenuOutlined } from '@material-ui/icons';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { useDispatch } from 'react-redux';
import { IConsultaEnEspera } from '../../../../Interfaces/IConsultaEnEspera';
import { cancelarCita, obtenerCitasEspontaneasEnEspera } from '../../../../apis/citaInstantaneaAPI';
import { convertDateToLocaleLong, convertSecondsToLocaleHMS } from '../../../../utils/dateTimeHelper';
import { useTime } from '../../../../hooks/useTime';
import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import { getFirestore } from '../../../../db';
import { IProfesionalAtendiendo } from '../../../../Interfaces/IProfesionalAtendiendo';
import { IPacienteEnEspera } from '../../../../Interfaces/IPacienteEnEspera';
import { finalizarSesionProfesional } from '../../../../apis/adminAPI';


function ComponenteMedico(props: { prof: IProfesionalAtendiendo, horaConexion?: Date, iProf: number, pacienteAtendiendo?: IPacienteEnEspera, onFinalizarSesion: () => void }) {
    const { prof, horaConexion, iProf, pacienteAtendiendo, onFinalizarSesion } = props;
    const theme = useTheme();
    const [finalizarSesionOpen, setFinalizarSesionOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const now = useTime(1000);


    const msEspera = now.getTime() - (horaConexion ? horaConexion!.getTime() : prof.horaConexion.getTime());
    const secondsEspera = Math.ceil(msEspera / (1000));
    const tiempoEspera = convertSecondsToLocaleHMS(secondsEspera);

    const atendiendo = prof.estado && prof.estado.toLowerCase() === "atendiendo";

    if (loading) {
        return (
            <ListItem style={{ backgroundColor: iProf % 2 === 0 ? 'white' : theme.paletaColores.fondo.segundo }}>
                <ListItemText primary={
                    <LoadingBubbles />
                }
                />
            </ListItem>
        )
    }
    return (
        <ListItem style={{ backgroundColor: iProf % 2 === 0 ? 'white' : theme.paletaColores.fondo.segundo }}>
            <ListItemText
                primary={
                    <>
                        <Toolbar style={{ padding: 0 }} variant="dense">
                            <Typography variant="body1" style={{ fontWeight: "bold", color: theme.paletaColores.naranja.tercero }}>
                                {`${prof.nombre} ${prof.apellido}`}
                            </Typography>
                            <span style={{ flexGrow: 1 }} />
                            {
                                !atendiendo &&
                                (
                                    <>
                                        <Button size="small" variant="contained" disableElevation onClick={() => { setFinalizarSesionOpen(true) }}>
                                            Cerrar sesión
                                        </Button>
                                        <ConfirmationDialog
                                            open={finalizarSesionOpen}
                                            setOpen={setFinalizarSesionOpen}
                                            title="Finalizar sesión"
                                            onConfirm={() => {
                                                onFinalizarSesion();
                                                //setLoading(true);
                                            }}
                                        >
                                            <>
                                                <Typography>
                                                    Esta acción cerrará la sesión del profesional {prof.nombre} {prof.apellido}.
                                                </Typography>
                                                <Typography>
                                                    Está seguro que desea continuar?
                                                </Typography>
                                            </>
                                        </ConfirmationDialog>
                                    </>
                                )
                            }
                        </Toolbar>

                        <div style={{ color: theme.paletaColores.negro.tercero, margin: "3px 0px 3px 0px" }}>
                            <Typography>{`Matricula: ${prof.matricula}`}</Typography>
                            <Typography>Especialidades:
                                <span>
                                    {
                                        prof.especialidades.map((esp, iEsp) => {
                                            const atendiendo = (prof.especialidadAtendiendo !== undefined && esp.toLowerCase() === prof.especialidadAtendiendo.toLowerCase());
                                            return (
                                                <Chip variant={atendiendo ? "default" : "outlined"} size="small" key={iEsp} label={esp} style={{ marginLeft: 5 }} />
                                            )
                                        })
                                    }
                                </span>
                            </Typography>
                        </div>
                        <Typography variant="body1" style={{ color: theme.paletaColores.negro.primero }}>
                            Estado: <span style={{ fontWeight: "bold" }}>{` ${prof.estado}`}</span>
                        </Typography>
                        {
                            pacienteAtendiendo &&
                            (
                                <Typography variant="body1" >
                                    Paciente: <span style={{ color: theme.paletaColores.naranja.tercero }}>{`${pacienteAtendiendo!.nombre} ${pacienteAtendiendo!.apellido}`}</span>
                                </Typography>
                            )
                        }
                        <Typography>
                            Tiempo conectado en la guardia: {tiempoEspera}
                        </Typography>


                    </>
                }
            />
        </ListItem>
    )
}


const EstadoGuardia: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const theme = useTheme();

    const [loading, setLoading] = useState(false);

    // const [consultasEspera, setConsultasEspera] = useState<Array<IConsultaEnEspera>>([]);
    // const [consultasEsperaFiltered, setConsultasEsperaFiltered] = useState<Array<IConsultaEnEspera>>([]);

    const [profesionales, setProfesionales] = useState<Array<IProfesionalAtendiendo>>([]);
    const [totalesGuardia, setTotalesGuardia] = useState<Array<IProfesionalAtendiendo>>([]);
    const [pacientes, setPacientes] = useState<Array<IPacienteEnEspera>>([]);

    const [dialogConsultaAccionOpen, setDialogConsultaAccionOpen] = useState<Array<Array<boolean>>>([]);
    function resetDialogConsultaAccionOpen(listaConsultas: IConsultaEnEspera[]) {
        let temp = new Array<Array<boolean>>(listaConsultas.length);
        listaConsultas.forEach((consulta, iConsulta) => {
            temp[iConsulta] = new Array<boolean>(acciones.length);
            acciones.forEach((accion, iAccion) => {
                temp[iConsulta][iAccion] = false;
            });
        });
        setDialogConsultaAccionOpen(temp);
    }

    const dispatch = useDispatch();

    const db = getFirestore();
    useEffect(() => {
        const unsubProfesionales = db.collection("UsuariosConectados").doc("profesional").collection("Lista").onSnapshot(
            (profesionalesSnapshot) => {
                let tempProfesionales: Array<IProfesionalAtendiendo> = [];
                let tempTotalesGuardia: Array<IProfesionalAtendiendo> = [];
                profesionalesSnapshot.docs.forEach((prof) => {
                    const profData = prof.data();
                    console.log(profData);
                    const esp = profData.especialidad.split(", ").filter((x: string) => x !== "");
                    const isTiempoTotalGuardia = prof.id.split("_")[0] === "tiempototalguardia";
                    const obj = {
                        usuario: prof.id,
                        especialidades: esp,
                        estado: profData.estado,
                        apellido: profData.apellido,
                        nombre: profData.nombre,
                        matricula: profData.matricula,
                        pacienteAtendiendo: profData.pacienteAtendiendo,
                        especialidadAtendiendo: profData.especialidadAtendiendo,
                        horaConexion: new Date(Number.parseInt(profData.horaConexion)),
                        horaLogin: new Date(Number.parseInt(profData.horaLogin)),
                    };
                    if (isTiempoTotalGuardia) {
                        obj.usuario = obj.usuario.slice(19);
                        tempTotalesGuardia.push(obj);
                    } else {
                        tempProfesionales.push(obj);
                    }
                })
                setProfesionales(tempProfesionales);
                setTotalesGuardia(tempTotalesGuardia);
            }
        )
        const unsubPacientes = db.collection("UsuariosConectados").doc("paciente").collection("Lista").onSnapshot(
            (pacientesSnapshot) => {
                let temp: Array<IPacienteEnEspera> = [];
                pacientesSnapshot.docs.forEach((paciente) => {
                    const pacienteData = paciente.data();
                    temp.push({
                        usuario: paciente.id,
                        sesion: pacienteData.sesion,
                        especialidad: pacienteData.especialidad,
                        estado: pacienteData.estado,
                        apellido: pacienteData.apellido,
                        nombre: pacienteData.nombre,
                        credencial: pacienteData.credencial,
                        horaConexion: new Date(Number.parseInt(pacienteData.horaConexion))
                    });
                })
                setPacientes(temp);
            }
        )
        return () => {
            unsubProfesionales();
            unsubPacientes();
        }
    }, [])

    const acciones = [
        {
            enabled: true,
            tag: "Cancelar Cita",
            icon: <CancelOutlined />,
            onClick: (consulta: IConsultaEnEspera) => {
                setLoading(true);
                cancelarCita({ usuario: consulta.usuario, sesion: consulta.id_consulta })
                    .then((dataRecibida) => {
                        setLoading(false);
                    })
                    .catch(e => { console.log(e); })
            }
        }
    ]
    const now = useTime(1000);

    if (loading) {
        return (
            <div style={{ marginTop: '40vh' }}>
                <LoadingBubbles />
            </div>
        )
    }

    let especialidadesPosibles: Array<string> = [];
    pacientes.forEach((paciente, iPaciente) => {
        if (especialidadesPosibles.indexOf(paciente.especialidad) === -1) {
            especialidadesPosibles.push(paciente.especialidad);
        }
    })
    profesionales.forEach((medico, iMedico) => {
        medico.especialidades.forEach((espMedico, iEspMedico) => {
            if (especialidadesPosibles.indexOf(espMedico) === -1) {
                especialidadesPosibles.push(espMedico);
            }
        })
    })
    return (
        <>
            <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                <CardHeader
                    avatar={<Avatar><SettingsOutlined /></Avatar>}
                    title={<Typography variant="h6">Estado de la Guardia</Typography>}
                />
                <Divider />
                <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                    <Card style={{ backgroundColor: theme.paletaColores.fondo.tercero }}>
                        <CardHeader
                            title={<Typography variant="h6" style={{ color: "white" }}>Dashboard</Typography>}
                        />
                        <Divider />
                        <CardContent
                            style={{ backgroundColor: theme.paletaColores.fondo.primero }}
                        >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow >
                                            <TableCell style={{ fontSize: 15, fontWeight: "bold" }}>Especialidad</TableCell>
                                            <TableCell style={{ fontSize: 15, fontWeight: "bold" }}>Profesionales de Guardia</TableCell>
                                            <TableCell style={{ fontSize: 15, fontWeight: "bold" }}>Pacientes en Espera</TableCell>
                                            <TableCell style={{ fontSize: 15, fontWeight: "bold" }}>Demora</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {especialidadesPosibles.map((espPosible, iEspPosible) => {
                                            const medicosEspecialidad = profesionales.filter(x => x.especialidades.indexOf(espPosible) !== -1);
                                            const pacientesEspecialidad = pacientes.filter(x => x.especialidad === espPosible && x.estado === "Sala de Espera");

                                            let maxSecondsEspera = -1;
                                            pacientesEspecialidad.forEach((pacEsp, iPacEsp) => {
                                                const msEspera = now.getTime() - pacEsp.horaConexion.getTime();
                                                const secondsEspera = Math.ceil(msEspera / (1000));
                                                if (secondsEspera > maxSecondsEspera) {
                                                    maxSecondsEspera = secondsEspera;
                                                }
                                            })
                                            const tiempoEspera = convertSecondsToLocaleHMS(maxSecondsEspera);

                                            return (
                                                <TableRow key={iEspPosible}>
                                                    <TableCell component="th" scope="row">
                                                        {espPosible}
                                                    </TableCell>
                                                    <TableCell>{medicosEspecialidad.length}</TableCell>
                                                    <TableCell>{pacientesEspecialidad.length}</TableCell>
                                                    <TableCell>{maxSecondsEspera !== -1 && tiempoEspera}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                    <div style={{ height: 15 }} />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                                <CardHeader
                                    avatar={
                                        <Avatar>
                                            {pacientes.length}
                                        </Avatar>
                                    }
                                    title={
                                        <Typography variant="h6">
                                            Pacientes
                                        </Typography>
                                    }
                                />
                                <Divider />
                                <CardContent>
                                    {pacientes.length === 0 ?
                                        (<Typography variant="h6" align="center" style={{ flexGrow: 1, margin: "10vh 0px 10vh 0px" }}>
                                            No hay pacientes en espera.
                                        </Typography>)
                                        :
                                        (
                                            <List style={{ margin: "-20px 0px -10px 0px" }}>
                                                {pacientes.map(
                                                    (paciente, iPaciente) => {
                                                        const onCancelar = () => {
                                                            cancelarCita({ usuario: paciente.usuario, sesion: paciente.sesion! });
                                                        }

                                                        const msEspera = now.getTime() - paciente.horaConexion.getTime();
                                                        const secondsEspera = Math.ceil(msEspera / (1000));
                                                        const tiempoEspera = convertSecondsToLocaleHMS(secondsEspera);
                                                        return (
                                                            <ListItem key={iPaciente} style={{ backgroundColor: iPaciente % 2 === 0 ? 'white' : theme.paletaColores.fondo.segundo }}>
                                                                <ListItemText
                                                                    primary={
                                                                        <>
                                                                            <Typography variant="body1" style={{ fontWeight: "bold", color: theme.paletaColores.naranja.tercero }}>
                                                                                {`${paciente.nombre} ${paciente.apellido}`}
                                                                            </Typography>
                                                                            <Typography variant="body1" style={{ color: theme.paletaColores.negro.primero }}>
                                                                                {`Estado: ${paciente.estado}`}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    secondary={
                                                                        <>
                                                                            <Typography>{`Credencial: ${paciente.credencial}`}</Typography>
                                                                            <Typography>{`${paciente.especialidad}`}</Typography>
                                                                        </>
                                                                    }
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <Typography>{`${tiempoEspera}`}</Typography>
                                                                    {
                                                                        paciente.sesion &&
                                                                        <Button
                                                                            variant="contained"
                                                                            disableElevation
                                                                            onClick={onCancelar}

                                                                        >
                                                                            Cancelar Cita
                                                                                </Button>
                                                                    }
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        )
                                                    }
                                                )}
                                            </List>
                                        )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar>
                                            {profesionales.length}
                                        </Avatar>
                                    }
                                    title={
                                        <Typography variant="h6">
                                            Profesionales
                                        </Typography>
                                    }
                                />
                                <Divider />
                                <CardContent>
                                    {profesionales.length === 0 ?
                                        (<Typography variant="h6" align="center" style={{ flexGrow: 1, margin: "10vh 0px 10vh 0px" }}>
                                            No hay profesionales atendiendo.
                                        </Typography>)
                                        :
                                        (
                                            <List style={{ margin: "-20px 0px -10px 0px" }}>
                                                {profesionales.map((prof, iProf) => {
                                                    const pacienteAtendiendo = pacientes.find(x => x.usuario === prof.pacienteAtendiendo);
                                                    const finalizarSesion = () => {
                                                        finalizarSesionProfesional(prof.usuario)
                                                            .then(() => { })
                                                            .catch(e => { console.log(e); })
                                                    }
                                                    const objetoGuardia = totalesGuardia.find(x => x.usuario === prof.usuario);

                                                    return (
                                                        <ComponenteMedico prof={prof} horaConexion={objetoGuardia?.horaConexion} iProf={iProf} onFinalizarSesion={finalizarSesion} pacienteAtendiendo={pacienteAtendiendo} key={iProf} />
                                                    )
                                                })}
                                            </List>
                                        )
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={() => { history.goBack() }} />
            </div>
        </>
    )
}

export default withStyles(styles)(EstadoGuardia)
