import React, { useState } from 'react'
import { List, ListItem, Divider, WithStyles, withStyles, useTheme, Typography, Avatar, Card, CardHeader, CardContent, ListItemText, Button, TextField, InputAdornment, Grid, Chip, ListItemSecondaryAction, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CardActionArea, CardMedia } from '@material-ui/core';
import { styles } from './MenuGestionTurnosStyles';
import { SettingsOutlined, VideocamOutlined, PieChartOutlined, PlusOneOutlined, AddOutlined, FolderOutlined, PhoneOutlined } from '@material-ui/icons';
import Volver from '../../../../components/buttons/Volver/Volver';
import { useHistory } from 'react-router';
import { IServicio } from '../../../../Interfaces/IServicio';


const servicios: Array<IServicio> = [
    {
        id: 'NUEVO_TURNO',
        primaryText: "Nuevo",
        secondaryText: "Crear nuevo turno médico",
        icon: AddOutlined,
        to: "/dashboard/operador/gestionar-turnos/nuevo"
    },
    {
        id: 'VER_TURNOS',
        primaryText: "Turnos",
        secondaryText: "Ver los turnos de un paciente",
        icon: FolderOutlined,
        to: "/dashboard/operador/gestionar-turnos/turnos"
    },
]

const MenuGestionTurnos: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const history = useHistory();

    const onItemClicked = (servicio: IServicio) => {
        history.push(servicio.to)
    }

    return (
        <>
            <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                <CardHeader
                    avatar={<Avatar><PhoneOutlined /></Avatar>}
                    title={<Typography variant="h6">Gestionar Turnos</Typography>}
                />
                <Divider />
                <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                    <Grid container spacing={2}>
                        {
                            servicios.map((servicio, iServicio) => {
                                return (
                                    <Grid item xs={12} lg={6} key={iServicio}>
                                        <Card variant="outlined">
                                            <CardActionArea onClick={() => { onItemClicked(servicio) }}>
                                                <CardHeader
                                                    title={
                                                        <Typography style={{ fontWeight: "bold", color: theme.paletaColores.naranja.tercero }}>
                                                            {servicio.primaryText}
                                                        </Typography>
                                                    }
                                                    avatar={
                                                        <Avatar>
                                                            <servicio.icon />
                                                        </Avatar>
                                                    }
                                                    subheader={servicio.secondaryText}
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </CardContent>
            </Card>
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={() => { history.goBack() }} />
            </div>
        </>
    )
}

export default withStyles(styles)(MenuGestionTurnos)
