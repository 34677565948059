import { Button, Dialog, DialogActions, DialogTitle, Divider, Grid, Typography } from "@material-ui/core";
import firebase from "firebase";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import LoadingBubbles from "../../components/LoadingBubbles/LoadingBubbles";
import loginMedico from "../../../src/assets/images/img-login.png";
import { InfoLoginGoogle } from '../../apis/profesionalAPI';
import { IEspecialidad } from '../../Interfaces/IEspecialidad';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactComponent as LogoCamdoctorGrey } from "../../assets/logos/logos_svg/logo_camdoctor_g.svg"



import { resetPacientes } from '../../store/user/userSlice';
import { resetPacientes as resetPacientesAtender } from '../../store/profesional-medico/atender-consultorio/atenderConsultorioSlice';
import { login } from "../../login/login";
import { setUserClaims } from "../../apis/adminAPI";


type Color = 'success' | 'info' | 'warning' | 'error';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  container: {
    height: "100vh",
  },
  leftSide: {
    background: `url(${loginMedico}) center top no-repeat`,
    backgroundSize: `cover`,
  },
});


const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [titleD, setTitleD] = useState('');
  const [severity, setSeverity] = useState('error' as Color);
  const history = useHistory();

  const [datosLoginTardio, setDatosLoginTardio] = useState<{
    NOMBRE: string;
    APELLIDO: string;
    email: string;
    especialidades: Array<IEspecialidad>;
    matricula: number;
  } | undefined>(undefined);

  const loginTardio = async (rol: 'PACIENTE' | 'MEDICO' | 'OPERADOR' | 'ADMINISTRADOR' | 'ADMINISTRADOR FINOCHIETTO') => {
    await setUserClaims(datosLoginTardio!.email, rol);
    await login(datosLoginTardio!.NOMBRE, datosLoginTardio!.APELLIDO, datosLoginTardio!.email, rol, datosLoginTardio!.especialidades, datosLoginTardio!.matricula);
  }

  const [dialogSeleccionRolOpen, setDialogSeleccionRolOpen] = useState(false);
  //const [userAuthData, setUserAuthData] = useState<IUserAuthData | undefined>(undefined);
  /*
  const onPacienteClicked = async () => {
    setLoading(true);
    const dni = '31048190'; // otro dni de prueba: santiago balsano 31048190 veronica del valle lopez 28863795
    const objetoRecibidoIdGF = await getIdGF(dni);

    dispatch(setUser({
      nombre: 'Santiago',
      apellido: 'Balsano',
      email: 'test@zentricx.com',
      rol: 'PACIENTE',
      dni: dni,
      idGrupoFamiliar: objetoRecibidoIdGF.idGF,
      isUserLogin: true
    }));
    setLoading(false);
  };

  const onAdminClicked = () => {
    dispatch(setUser({
      nombre: 'Administrador',
      apellido: 'Clinica',
      email: 'admin@zentricx.com',
      rol: 'ADMINISTRADOR',
      isUserLogin: true
    }));
  };
  */
  const onGoogleAuthClicked = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    setOpenD(false);
    setLoading(true);
    firebase.auth()
      .signInWithPopup(provider)
      .then(async (result) => {

        var user = result.user;

        let info = await InfoLoginGoogle(user!.email!, user!.photoURL!);
        setLoading(false);
        if (info.empty) {
          setTitleD('Usuario no registrado');
          setOpenD(true)
          setSeverity('error' as Color)
          console.log('no registrado');
          return;
        }

        //let bearerToken = await result.user?.getIdToken();
        //localStorage.setItem('token', bearerToken!);

        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential as firebase.auth.OAuthCredential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential!.accessToken;
        console.log('token: ', token);
        // The signed-in user info.

        const datos = {
          email: user!.email!,
          displayName: user!.displayName!,
          oAuthToken: token!
        }

        const loginData = info;

        console.log(loginData);
        console.log(localStorage.getItem('token'));
        let matricula: number = parseInt(info.matricula);

        const especialidades: Array<IEspecialidad> = loginData.especialidad;
        let isMedico = false;
        let isAdmin = false;
        let isOperador = false;
        let isAdminFinochietto = false;
        let isFarmacia = false;
        console.log("0. por hacer")
        especialidades.forEach((especialidad) => {
          let esp = especialidad.descripcion.toLowerCase();

          if (esp === "administrador" || esp === "administrador medife" || esp === "administrador camdoctor") {
            isAdmin = true;
          } else if (esp === "administrador finochietto") {
            isAdminFinochietto = true;
          } else if (esp === "administrativo turnos") {
            isOperador = true;
          } else if (esp === "farmaceutico") {
            isFarmacia = true;
          } else {
            isMedico = true;
          }
        })
        const nombre = loginData.nombre;
        const apellido = loginData.apellido;
        //setUserAuthData(datos);

        console.log(datos);

        //resetear datos de pacientes de sesiones anteriores
        resetPacientes();
        resetPacientesAtender();


        //aca llamaria al endpoint de mandarle estos datos al back
        //el back deberia devolverme minimamente: nombre, apellido, email, cuil, y rol
        //(DE LA PERSONA LOGEADA)
        // var datosDelUsuario = await enviarLogeadoConGoogle(datos);
        let rol: 'PACIENTE' | 'MEDICO' | 'OPERADOR' | 'ADMINISTRADOR' | 'ADMINISTRADOR FINOCHIETTO' | 'FARMACEUTICO';

        setDatosLoginTardio({
          NOMBRE: nombre,
          APELLIDO: apellido,
          email: datos.email,
          especialidades: especialidades,
          matricula: matricula,
        })

        let loginTardio = false;
        if (isMedico && isAdmin) {
          setDialogSeleccionRolOpen(true);
          loginTardio = true;
        } else if (isAdmin) {
          rol = "ADMINISTRADOR";
        } else if (isAdminFinochietto) {
          rol = "ADMINISTRADOR FINOCHIETTO";
        } else if (isOperador) {
          rol = "OPERADOR";
        } else if (isFarmacia) {
          rol = "FARMACEUTICO";
        } else if (isMedico) {
          rol = "MEDICO";
        } else {
          console.log("La cuenta no es de medico ni administrador");
        }

        localStorage.setItem("LoggedIn", "true");

        if (!loginTardio) {
          login(nombre, apellido, datos.email, rol!, especialidades, matricula);
        }

        //console.log(token);
        // ...
      }).catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
        setTitleD('Ocurrio un error al loguear'.toString());
        setOpenD(true)
        setSeverity('error' as Color)
        console.log(error);
        setLoading(false);
      });
  };

  const classes = useStyles();

  /*  if (loading) {
      return (
        <div style={{ marginTop: "40vh" }}>
          <LoadingBubbles />
        </div>
      );
    } */

  const windowSize = useWindowDimensions();


  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item container xs={12} md={6} lg={7} className={classes.leftSide} style={{ height: windowSize.width > 768 ? "100vh" : "50vh" }}>
          {/* <img
            src={loginMedico}
            alt="imagen login medico"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          /> */}
          <Grid item xs={1} md={2} />

          <Grid
            item
            xs
            style={{ paddingTop: "10vh" }}
          >

            {/* <Typography variant="h3"style={{ color: "#FFFF",fontFamily: "Syntax LT Std Bold" }}>Cam Doctor</Typography> */}
            <LogoCamdoctorGrey style={{ width: 350, marginTop: -50 }} />

            {/* <hr
              style={{
                boxSizing: "border-box",
                height: "1px",
                width: "100%",
                border: "2px solid #FFFFFF",
              }}
            /> */}
          </Grid>
          <Grid item xs={1} md={2} />
        </Grid>
        <Grid
          item
          container
          xs={12} md={6} lg={5}
          justify="center"
          alignItems="center"
          style={{ background: "#FFFF" }}
        >
          <Grid item>
            <Typography variant="h5">
              Login Usuarios
            </Typography>
            <Divider style={{ margin: "20px 0px 20px 0px" }} />
            <Collapse in={loading}>
              <LoadingBubbles />
            </Collapse>
            <Collapse in={openD}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenD(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity={severity}
              >
                {titleD}
              </Alert>
              <Divider style={{ margin: "20px 0px 20px 0px" }} />
            </Collapse>
            {!loading && (
              <>
                <Grid
                  item
                  style={{
                    boxSizing: "border-box",
                    height: "45px",
                    width: "339px",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    variant="outlined"
                    disableElevation
                    fullWidth
                    color="primary"
                    href={process.env.REACT_APP_URL_LOGIN_ASOCIADOS}
                  >
                    Asociado
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{
                    boxSizing: "border-box",
                    height: "45px",
                    width: "339px",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    variant="outlined"
                    disableElevation
                    fullWidth
                    color="primary"
                    onClick={onGoogleAuthClicked}
                  >
                    Prestador
                  </Button>
                </Grid>
              </>
            )}

            <p
              style={{
                height: "141px",
                width: "339px",
                color: "#58575C",
                fontFamily: "Syntax LT Std",
                fontSize: "14px",
                letterSpacing: "0",
                lineHeight: "18px",
                marginTop: "60px",
              }}
            >
              <a href="#!" style={{ color: "#EF7F41" }}>
                Política de Privacidad
              </a>
              &nbsp;|
              <a href="#!" style={{ color: "#EF7F41" }}>
                &nbsp;DNPDP
              </a>
              &nbsp;|&nbsp;
              <a href="#!" style={{ color: "#EF7F41" }}>
                Defensa y Protección al Consumidor
              </a>
              Superintendencia de Servicios de Salud Órgano de Control de Obras
              Sociales y Entidades de Medicina Prepaga 0800 222 SALUD (72583) |{" "}
              <a href="#!" style={{ color: "#EF7F41" }}>
                www.sssalud.gob.ar
              </a>{" "}
              | R.N.E.M.P: Nro. 1199/15
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={dialogSeleccionRolOpen} >
        <DialogTitle>
          Por favor seleccione un rol para ingresar
        </DialogTitle>
        <DialogActions>
          <Collapse in={loading}>
              <LoadingBubbles />
            </Collapse>
          { !loading && (<><Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={async () => {
              setLoading(true)
              await loginTardio("ADMINISTRADOR");
              setLoading(false)
            }}
          >
            COORDINADOR
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={async () => {
              setLoading(true)
              await loginTardio("MEDICO");
              setLoading(false)
            }}
          >
            MEDICO
          </Button></>) }
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SignIn as React.ComponentType;
