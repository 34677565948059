import React from 'react'

import { Grid, IconButton, Paper, WithStyles, withStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import usuario from '../../../src/assets/images/usuario.png';
import { styles } from './PacienteCardStyles';
import { IPaciente } from '../../Interfaces/IPaciente';

interface OwnProps {
    paciente: IPaciente;
    onClickHandler?: () => void;
}

type PublicProps = OwnProps
type Props = PublicProps & WithStyles<typeof styles>
const PacienteCard: React.FC<Props> = (props) => {
    const { classes, paciente, onClickHandler } = props

    return (
        <Paper elevation={3} className={classes.paper} onClick={onClickHandler}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={3}>
                    <img src={usuario} alt="paciente" width="100px" />
                </Grid>
                <Grid item xs={7}>
                    <div><b>{paciente.NOMBRE + " " + paciente.APELLIDO}</b></div>
                    <div>{paciente.EDAD}</div>
                    <div>CREDENCIAL {paciente.NRO_CREDENCIAL}</div>
                    <div>CUIL {paciente.CUIL}</div>
                </Grid>
                <Grid item xs={1}>
                    <IconButton>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default withStyles(styles)(PacienteCard) as React.ComponentType<PublicProps>