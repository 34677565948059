import { AnyAction, combineReducers } from '@reduxjs/toolkit'

import verMedicoOnlineReducer from './paciente/ver-medico-online/verMedicoOnlineSlice';
import consultasProgramadasReducer from './paciente/consultas-programadas/consultasProgramadasSlice';
import userReducer from './user/userSlice'
import guardiaMedicoReducer from './profesional-medico/guardia-medico/guardiaMedicoSlice'
import verConsultasAnterioresReducer from './paciente/ver-consultas-anteriores/verConsultasAnterioresSlice';
import atenderConsultorioReducer from './profesional-medico/atender-consultorio/atenderConsultorioSlice';
import verTurnosAgendadosReducer from './paciente/ver-turnos-agendados/verTurnosAgendadosSlice';
import administrarConsultoriosMedicosReducer from './administrador/administrar-consultorios-medicos/administrarConsultoriosMedicosSlice';
import administrarGuardiaReducer from './administrador/administrar-guardia/administrarGuardiaSlice';
import administrarSintomasReducer from './administrador/administrar-sintomas/administrarSintomasSlice';
import administrarHistorialOfflineReducer from './administrador/administrar-historial-offline/administrarHistorialOfflineSlice';
import gestionarTurnosReducer from './operador-finochietto/gestionar-turnos/gestionarTurnosSlice';
import administrarSolicitudesFarmaciaReducer from './farmaceutico/administrar-solicitudes-farmacia/administrarSolicitudesFarmaciaSlice';

const appReducer = combineReducers({
    verMedicoOnline: verMedicoOnlineReducer,
    user: userReducer,
    guardiaMedico: guardiaMedicoReducer,
    verConsultasAnteriores: verConsultasAnterioresReducer,
    consultasProgramadas: consultasProgramadasReducer,
    atenderConsultorio: atenderConsultorioReducer,
    verTurnosAgendados: verTurnosAgendadosReducer,
    administrarConsultoriosMedicos: administrarConsultoriosMedicosReducer,
    administrarGuardia: administrarGuardiaReducer,
    administrarSintomas: administrarSintomasReducer,
    gestionarTurnos: gestionarTurnosReducer,
    administrarHistorialOffline: administrarHistorialOfflineReducer,
    administrarSolicitudesFarmacia: administrarSolicitudesFarmaciaReducer,
})

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export type RootState = ReturnType<typeof appReducer>

export default rootReducer