import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import ConfiguracionConsultasProgramadas from '../../../pages/ProfesionalMedico/AdministrarConsultorio/ConfiguracionConsultasProgramadas/ConfiguracionConsultasProgramadas';
import FechasEspecificas from '../../../pages/ProfesionalMedico/AdministrarConsultorio/FechasEspecificas/FechasEspecificas';
import PanelDeControl from '../../../pages/ProfesionalMedico/AdministrarConsultorio/PanelDeControl/PanelDeControl';


const AdministrarConsultorio = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Redirect from={path} to={`${url}/panel-control`} exact />
            <PrivateRoute component={PanelDeControl} path={`${path}/panel-control`} />
            <PrivateRoute component={ConfiguracionConsultasProgramadas} path={`${path}/configuracion-consultas-programadas`} />
            <PrivateRoute component={FechasEspecificas} path={`${path}/fechas-especificas`} />
        </Switch>
    )
}

export default AdministrarConsultorio