import React from 'react'

import { Box, Grid, Typography, useTheme } from '@material-ui/core';

import usuario from '../../../src/assets/images/usuario.png';

interface PublicProps {
    nombreMedico: string;
    matricula: string | number;
    especialidades: Array<string>;
    direccionImagen?: string;
}

const PerfilMedico: React.FC<PublicProps> = (props) => {
    const { nombreMedico, matricula, especialidades, direccionImagen } = props

    const theme = useTheme();
    return (
        <Grid container alignItems="center" >
            <Grid item >
                <Box mx={3}>
                    <img src={direccionImagen ? direccionImagen! : usuario} alt="medico" width="130px" style={{ width: 130, height: 130, borderRadius: 30 }} />
                </Box>
            </Grid>
            <Grid item >
                <Typography variant="h6" style={{ color: theme.paletaColores.naranja.tercero }}>{nombreMedico}</Typography>
                <Typography paragraph>MP: {matricula}</Typography>
                {especialidades.map((especialidad, index) => {
                    return (
                        <Typography key={index}>{especialidad}</Typography>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default PerfilMedico as React.ComponentType<PublicProps>