import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface OwnProps {
    title: string;
    children?: React.ReactElement;
    open: boolean;
    setOpen: (open: boolean) => void;
    onConfirm: () => void;
    confirmIsPrimary?: boolean;
}
type PublicProps = OwnProps
type Props = PublicProps

const ConfirmationDialog = (props: Props) => {
    const { title, children, open, setOpen, onConfirm, confirmIsPrimary } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={() => setOpen(false)}
                    color={confirmIsPrimary ? "default" : "primary"}
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color={confirmIsPrimary ? "primary" : "default"}
                >
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmationDialog;