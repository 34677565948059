import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => createStyles({
  container: {
    maxWidth: 375,
    width: 375
  },
  exitIcon: {
    padding: 0
  },
  datos:{
    color: theme.paletaColores.negro.primero,
    fontSize: '1rem'
  },
  asociado:{
    fontSize: '0.75rem',
    color: theme.paletaColores.naranja.segundo
  },
  nombre:{
    fontSize: '1.5rem'
  },
  divider: {
    height: 2,
    border: '1px solid #979797',
    marginBottom: 16
  },
  listItem: {
    width: '100%',
    height: 40,
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.paletaColores.negro.segundo,
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  }
});
