import React, { useEffect, useState } from 'react'
import useQuery from '../../../hooks/useQuery'
import LoadingBubbles from '../../../components/LoadingBubbles/LoadingBubbles';
import { cancelarCitaCodigo, obtenerEstadoCC } from '../../../apis/citaProgramadaAPI';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { ReactComponent as LogoMedife } from '../../../assets/logos/logo-medife.svg'
import LogoFinochietto from '../../../assets/logos/logo-telemedicina-finochietto.png';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

const CancelarCitaMail: React.FC<any> = () => {
    const query = useQuery();

    const [loading, setLoading] = useState(false);
    const [estado, setEstado] = useState<"activo" | "canceladoAhora" | "canceladoPrevio" | "descartado">("activo");
    const [finochietto, setFinochietto] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const cs = query.get("cs")!;

    useEffect(() => {
        setLoading(true)
        obtenerEstadoCC(cs)
            .then(
                (resultado) => {
                    setFinochietto(resultado.esFinochietto);
                    setLoading(false);
                    if (resultado.estado.toLowerCase() === "cancelada" || resultado.estado.toLowerCase() === "cancelado") {
                        setEstado("canceladoPrevio");
                    }
                }
            )
            .catch(e => { console.log(e) })
    }, [])

    return (
        <div style={{ marginTop: "45vh" }}>
            {
                loading ?
                    (
                        <LoadingBubbles />
                    )
                    :
                    (
                        <>
                            {
                                estado === "canceladoPrevio" &&
                                (
                                    <Dialog open={true}>
                                        <DialogTitle>
                                            <Box display="flex" alignItems="center">
                                                {
                                                    finochietto ?
                                                        <img src={LogoFinochietto} style={{ width: "150px" }} />
                                                        :
                                                        <LogoMedife />
                                                }
                                            </Box>
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                <Typography>
                                                    La cita que intentas cancelar ha sido cancelada previamente.
                                                </Typography>
                                                <Typography>
                                                    Por favor, cierre la pestaña para salir.
                                                </Typography>
                                            </DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                )
                            }
                            {
                                estado === "canceladoAhora" &&
                                (
                                    <Dialog open={true}>
                                        <DialogTitle>
                                            <Box display="flex" alignItems="center">
                                                {
                                                    finochietto ?
                                                        <img src={LogoFinochietto} style={{ width: "150px" }} />
                                                        :
                                                        <LogoMedife />
                                                }
                                            </Box>
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                <Typography>
                                                    {mensaje !== "" ? mensaje : "La cita fue cancelada con éxito"}
                                                </Typography>
                                                <Typography>
                                                    Muchas gracias.
                                                </Typography>
                                            </DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                )
                            }
                            {
                                estado === "descartado" &&
                                (
                                    <Dialog open={true}>
                                        <DialogTitle>
                                            <Box display="flex" alignItems="center">
                                                {
                                                    finochietto ?
                                                        <img src={LogoFinochietto} style={{ width: "150px" }} />
                                                        :
                                                        <LogoMedife />
                                                }
                                            </Box>
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                <Typography>
                                                    La operación de cancelar cita ha sido descartada.
                                                </Typography>
                                                <Typography>
                                                    Por favor, cierre la pestaña para salir.
                                                </Typography>
                                            </DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                )
                            }
                            {
                                estado === "activo" && (
                                    (
                                        <Dialog open={true}>
                                            <DialogTitle>
                                                <Box display="flex" alignItems="center">
                                                    {
                                                        finochietto ?
                                                            <img src={LogoFinochietto} style={{ width: "150px" }} />
                                                            :
                                                            <LogoMedife />
                                                    }
                                                </Box>
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    <Typography>
                                                        Está seguro de que desea cancelar la cita?
                                                    </Typography>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        setLoading(true);
                                                        cancelarCitaCodigo(query.get("cs")!)
                                                            .then(
                                                                (datosDevueltos) => {
                                                                    setLoading(false);
                                                                    setEstado("canceladoAhora");
                                                                    setMensaje(datosDevueltos);
                                                                }
                                                            )
                                                            .catch(e => { console.log(e) })
                                                    }}
                                                    variant="contained"
                                                    disableElevation
                                                >
                                                    Si
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setEstado("descartado")
                                                    }}
                                                    variant="contained"
                                                    disableElevation
                                                    color="primary"
                                                >
                                                    No
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    )
                                )
                            }
                        </>

                    )
            }
        </div>
    )
}

export default CancelarCitaMail as React.ComponentType;
