import React from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//MATERIAL-UI
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FolderOpenOutlined from '@material-ui/icons/FolderOpenOutlined';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';

//REDUX
import { setPaso } from '../../../store/paciente/ver-medico-online/verMedicoOnlineSlice'
//
import { IServicio } from '../../../Interfaces/IServicio';
import imgCamDoctor1 from '../../../../src/assets/images/imgCamDoctor1.png'
import { RootState } from '../../../store/rootReducer';
import { setWarningSarsCov2 } from '../../../store/user/userSlice';
import { ReactComponent as LogoMedife } from '../../../assets/logos/logo-medife.svg'

const servicios: ReadonlyArray<IServicio> = [
    {
        id: 'VER_MEDICO_ONLINE',
        primaryText: "Ver un médico ahora",
        secondaryText: "Videollamada...",
        icon: VideocamOutlinedIcon,
        to: "/dashboard/ver-medico-online"
    },
    {
        id: 'AGENDAR_TURNO',
        primaryText: "Agendar un turno",
        secondaryText: "Consultas programadas en...",
        icon: EventAvailableOutlinedIcon,
        to: "/dashboard/consultas-programadas"
    },
    {
        id: 'MI_AGENDA_TURNOS',
        primaryText: "Mi agenda de turnos",
        secondaryText: "Gestiona los turnos agendados",
        icon: EventOutlinedIcon,
        to: "/dashboard/ver-turnos-agendados"
    },
    {
        id: 'VER_CONSULTAS_ANTERIORES',
        primaryText: "Ver consultas anteriores",
        secondaryText: "Accede a tu historial de consultas",
        icon: FolderOpenOutlined,
        to: "/dashboard/ver-consultas-anteriores"
    },

]

const Welcome = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { warningSarsCov2 } = useSelector(
        (state: RootState) => state.user
    )

    const onItemClicked = (servicio: IServicio) => {
        if (servicio.id === 'VER_MEDICO_ONLINE') {
            dispatch(setPaso(1))
        }
        history.push(servicio.to)
    }

    const serviciosList = servicios.map((servicio, index) => (
        <Box key={index} borderBottom="1px solid #efefef" >
            <ListItem button style={{ paddingBottom: 20, paddingTop: 20 }} onClick={() => onItemClicked(servicio)} >
                <ListItemIcon>
                    <servicio.icon fontSize="large" />
                </ListItemIcon>
                <ListItemText
                    primary={servicio.primaryText}
                    secondary={servicio.secondaryText}
                />
                <ListItemSecondaryAction onClick={() => onItemClicked(servicio)} >
                    <IconButton edge="end" >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </Box>
    ))

    return (
        <>
            <Grid container justify="center" spacing={3}>
                <Grid item xs={12} md={7} lg={7}>
                    <List>
                        {
                            serviciosList
                        }
                    </List>
                </Grid>
                <Grid item xs={10} md={5} lg={5}>
                    <img src={imgCamDoctor1} alt="imagen app mobile" width="100%" />
                </Grid>
            </Grid>
            <Dialog fullWidth maxWidth="sm" open={warningSarsCov2}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <LogoMedife />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>
                            Si ya fuiste atendido en CAM DOCTOR por sospecha de Covid 19, fuiste hisopado, esperas el resultado, necesitas el alta o el certificado, en las próximas 48hs nuestro equipo médico de seguimiento telefónico te estará contactando.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => {
                            dispatch(setWarningSarsCov2(false));
                        }}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Welcome