import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, useTheme } from '@material-ui/core';
import { Grid, Button, Card, Typography, CardMedia, CardActions, Paper } from '@material-ui/core'
import { HistoryRounded, Person, PlayArrowRounded } from '@material-ui/icons'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import UserImg from '../../../../assets/images/usuario.png'
import CardHistoriaClinica from '../../../../components/CardHistoriaClinica/CardHistoriaClinica';
import LoadingBubbles from '../../../../components/LoadingBubbles/LoadingBubbles';
import { Pagination } from '@material-ui/lab';
import { RootState } from '../../../../store/rootReducer';
import { setCitaAtendido, verDatosCita } from '../../../../apis/citaProgramadaAPI';
import { IDatosCitaProgramada } from '../../../../Interfaces/IDatosCitaProgramada';
import { setPassSalaVideollamada } from '../../../../store/profesional-medico/atender-consultorio/atenderConsultorioSlice';
import { _calculateAgeDetailed } from '../../../../utils/dateTimeHelper';

const DatosPacienteProgramado: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [cita, setCita] = useState<IDatosCitaProgramada | undefined>(undefined);
    const [loading, setLoading] = useState(false);



    const { cuilPacienteSeleccionado, idSalaVideollamada } = useSelector(
        (state: RootState) => state.atenderConsultorio
    )
    const usuario = cuilPacienteSeleccionado.toString();

    useEffect(() => {
        setLoading(true);

        verDatosCita({ usuario: usuario, sesion: idSalaVideollamada })
            .then(
                (data) => {
                    data.historialClinico?.sort((a, b) => { return b.fecha._seconds - a.fecha._seconds; });
                    setCita(data);
                    setLoading(false)
                }
            )
            .catch(e => { console.log(e); })
    }, [usuario, idSalaVideollamada]);

    const onClickAtenderPaciente = () => {
        dispatch(setPassSalaVideollamada(cita!.password));
        let usuarioSesion = { usuario: usuario, sesion: idSalaVideollamada };
        setLoading(true);
        setCitaAtendido(usuarioSesion)
            .then(
                () => {
                    setLoading(false);
                    history.push(`/dashboard/atender-consultorio/consultorio-medico`);
                }
            )
            .catch(e => { console.log(e) })
    }

    const offsetHours = -(new Date().getTimezoneOffset() / 60);
    let offset = offsetHours < 0 ? "-" : "+";
    offset += Math.abs(offsetHours) < 10 ? "0" : "";
    offset += Math.floor(Math.abs(offsetHours));
    const offsetHourDecimal = Math.abs(offsetHours) - Math.floor(Math.abs(offsetHours));
    const offsetMinutes = offsetHourDecimal * 60;
    offset += offsetMinutes < 10 ? "0" : "";
    offset += offsetMinutes;

    const edad = cita ? _calculateAgeDetailed(new Date(cita!.fechaNacimiento.replace("+0000", offset))) : "";
    const dni = cita ? usuario.substring(2, usuario.length - 1) : "";

    const theme = useTheme();

    const elementosPerPag = 5;
    const [pagina, setPagina] = useState(0);

    if (loading) {
        return (
            <LoadingBubbles />
        )
    }

    return (
        <>
            <Grid container>
                <Grid item container direction="column" xs>
                    <Grid item>
                        <Box>
                            <Box>
                                <h1>
                                    <Person />
                                    Próximo Paciente
                            </h1>
                            </Box>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item container>
                        <Grid item container xs>
                            <Grid item container xs={12} lg={3}>
                                <Grid item container xs={12}>
                                    <Card elevation={0} style={{ width: '100%' }}>
                                        <CardMedia image={UserImg} style={{ margin: '10px', height: '256px' }} />

                                        <CardActions>
                                            <Grid direction="column" container spacing={1}>
                                                <Grid item>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        disableElevation
                                                        fullWidth
                                                        disabled={loading}
                                                        onClick={onClickAtenderPaciente}
                                                        startIcon={<PlayArrowRounded />}
                                                        style={loading ? theme.buttons.okDisabledButton : theme.buttons.okButton}
                                                    >
                                                        <Typography style={{ flex: 1 }}>
                                                            Atender Paciente
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        disableElevation
                                                        fullWidth
                                                        onClick={() => { history.goBack() }}
                                                        startIcon={<ArrowBackIosIcon />}
                                                        style={theme.buttons.cancelButton}
                                                    >

                                                        <Typography style={{ flex: 1 }}>
                                                            Volver
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item container xs direction="column">
                                <Grid item>
                                    <Box m={1}>
                                        <Typography variant="h5">
                                            {cita ? cita!.nombre : ""}
                                        </Typography>
                                        <Typography variant="h6">
                                            {edad}, DNI: {dni}
                                        </Typography>
                                        <Divider />
                                        <Typography variant="body1">
                                            Motivo de consulta:  {cita ? cita!.motivo : ""}
                                        </Typography>
                                        <Divider />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box m={1}>
                                        <Paper elevation={2}>
                                            <Box m={1}>
                                                <Box>
                                                    <Grid container item alignItems="center">
                                                        <HistoryRounded style={{ margin: "7px" }} />
                                                        <Typography variant="h5">
                                                            Historial
                                                        </Typography>
                                                    </Grid>
                                                </Box>
                                                <h4>
                                                    Consultas anteriores: {cita ? cita!.historialClinico.length : "0"}
                                                </h4>
                                                {cita ? cita!.historialClinico.map((elementoHistorial, index) => {
                                                    if (index >= pagina * elementosPerPag && index < (pagina + 1) * elementosPerPag) {
                                                        return (
                                                            <>
                                                                <Divider key={index + "divider"} />
                                                                <CardHistoriaClinica mostrarNotasPrivadas={true} key={index + "card"} elementoHistorial={elementoHistorial} elevation={0} />

                                                            </>
                                                        )
                                                    }
                                                    return null;
                                                }) : null}
                                            </Box>
                                            {cita ?
                                                (
                                                    <Grid container justify="center">
                                                        <Pagination style={{ margin: 10 }} count={Math.ceil(cita!.historialClinico.length / elementosPerPag)} page={pagina + 1} onChange={(event, value) => {
                                                            setLoading(true);
                                                            setTimeout(() => {
                                                                setPagina(value - 1)
                                                                setLoading(false)
                                                            }, 500)
                                                        }} />
                                                    </Grid>
                                                ) :
                                                (null)}
                                        </Paper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DatosPacienteProgramado
