import axios from "axios";
import firebase from "firebase";
import { ICargarSchedule } from "../Interfaces/ICargarSchedule";
import { ICrearCitaProgramada } from "../Interfaces/ICrearCitaProgramada";
import { IDatosCitaProgramada } from "../Interfaces/IDatosCitaProgramada";
import { IProfesionalTurnos } from "../Interfaces/IProfesionalHorario";
import { IResultadoObtenerTurnosDisponibles } from "../Interfaces/IResultadoObtenerTurnosDisponibles";
import { ITurnoAgendado } from "../Interfaces/ITurnoAgendado";
import { IUsuarioSesion } from "../Interfaces/IUsuarioSesion";
import { convertDateToDatestring, convertDateToDiaHora, convertTimestringToDate } from "../utils/dateTimeHelper";

const citaProgramadaApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    "Authorization": '',
  },
});

citaProgramadaApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem('token');
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers['Authorization'] = 'bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

citaProgramadaApi.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.setItem("LoggedIn", "false");
  } else if (error.response.status === 400) {
    localStorage.setItem("Error400", error.response.data);
  }
  return error;
});

export async function getInfoDia(especialidad: string, fecha: Date, usuario?: string): Promise<Array<IProfesionalTurnos>> {
  const fechaFormateada = convertDateToDatestring(fecha);
  let request = `/getInfoDia?Especialidad=${especialidad}&fecha=${fechaFormateada}`;

  if(usuario){
    request = `${request}&usuario=${usuario!}`
  }
  const { data } = await citaProgramadaApi.get<Array<IProfesionalTurnos>>(request);
  
  data.forEach((profTurno, iProfTurno) => {
    profTurno.sobreturnos.forEach((sobreturno, iSobreturno) => {
      profTurno.turnos.push(sobreturno + "s");
    })
    profTurno.turnos.sort((a, b) => {
      a = a.replace("s", "");
      a = a.replace("s", "");
      const aDate = convertTimestringToDate(a);
      const bDate = convertTimestringToDate(b);
      return aDate.getTime() - bDate.getTime();
    })
  })
  return data;
}

export async function getDiasLibres(especialidad: string): Promise<Array<string>> {
  const { data } = await citaProgramadaApi.get<Array<string>>(
    `/getDiasLibres?Especialidad=${especialidad}`
  );
  return data;
}

export async function cargarSchedule(objetoCarga: ICargarSchedule): Promise<{ status: number, data: string }> {
  const { status, data } = await citaProgramadaApi.post<string>(
    `/cargarSchedule`, objetoCarga
  );
  return { status, data };
}

export async function getSchedule(idProfesional: string): Promise<Array<ICargarSchedule>> {
  const { data } = await citaProgramadaApi.get(
    `/getSchedule?profesional=${idProfesional}`
  );
  return data;
}

export async function crearCitaProgramada(cita: ICrearCitaProgramada): Promise<string> {
  const { data } = await citaProgramadaApi.post(
    `/crearCitaProgramada`, cita
  );
  return data;
}

export async function obtenerTurnos(usuario: string): Promise<Array<ITurnoAgendado>> {
  const { data } = await citaProgramadaApi.get(
    `/obtenerTurnos?paciente=${usuario}`
  );
  return data;
}

export async function cancelarCita(usuarioSesion: IUsuarioSesion): Promise<string> {
  const { data } = await citaProgramadaApi.post(`/CancelarCita`, usuarioSesion);
  return data;
}

export async function cancelarCitaCodigo(codigo: string): Promise<string> {
  const { data } = await citaProgramadaApi.post(`/CancelarCitaCC`, {
    codigoCancelacion: codigo
  });
  return data;
}
export async function obtenerEstadoCC(codigo: string): Promise<{ estado: string; esFinochietto: boolean }> {
  const { data } = await citaProgramadaApi.get(`/ObtenerEstadoCC?codigoCancelacion=${codigo}`);
  return data;
}

export async function setEnEspera(usuarioSesion: IUsuarioSesion): Promise<{ estado: string, tiempoEsperaPromedio: number }> {
  const { data } = await citaProgramadaApi.post(`/SetEnEspera`, usuarioSesion);
  return data;
}

export async function setCitaAtendido(usuarioSesion: IUsuarioSesion): Promise<string> {
  const { data } = await citaProgramadaApi.post(`/SetCitaAtendido`, usuarioSesion);
  return data;
}

export async function obtenerTurnosMedicoDia(profesional: string, fecha: Date): Promise<IResultadoObtenerTurnosDisponibles> {
  const fechaFormateada = convertDateToDatestring(fecha);
  const { data } = await citaProgramadaApi.get<IResultadoObtenerTurnosDisponibles>(`obtenerTurnosDisponibles?medico=${profesional}&fecha=${fechaFormateada}`);
  data.sobreturnos?.forEach((sob, iSob)=>{
    data.disponibles.push(sob);
  });
  return data;
}

export async function verDatosCita(datos: IUsuarioSesion): Promise<IDatosCitaProgramada> {
  const { usuario, sesion } = datos;
  const { data } = await citaProgramadaApi.get(`/VerDatosCita?usuario=${usuario}&sesion=${sesion}`);
  return data;
}

export async function cargarSobreturno(profesional: string, horario: Date, fecha: Date): Promise<string> {
  console.log("horario",horario)
  const { horas } = convertDateToDiaHora(horario);
  const body = {
    profesional: profesional,
    sobreturnos: [
      {
        dia: convertDateToDatestring(fecha),
        horas: [
          horas,
        ]
      }
    ]
  }
  const { data } = await citaProgramadaApi.post(`/CargarSobreTurno`, body);
  return data;
}
 /**
    * Generate an agenda report per professional / Genera un informe de la agenda por profesional
    * @param   {array Listprofessional}       required
    * @param   {int year}                     optional
    * @param   {int month}                    optional
    * @param   {int day}                      optional
    * @return  {array turnosTomados}  
    * @author  Julio Toloza <juliotoloza@zentricx.com>  
    */
export async function setFinalizarBtn(cita: Object, cuilPaciente: String ): Promise<boolean> {
  const body = {
    tipo: 'Programada',
    cita: cita,
    paciente: cuilPaciente
  }
  const { data } = await citaProgramadaApi.post(`/setFinalizarBtn`, body);
  return data
 
}

//export default pacientesApi;
