import React from 'react';
import { List, ListItem, Divider, WithStyles, withStyles, useTheme, Typography, Avatar, Card, CardHeader, CardContent, ListItemText, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { styles } from './PanelDeControlStyles';
import Volver from '../../../../components/buttons/Volver/Volver';
import { CalendarTodayOutlined, SettingsOutlined, VideocamOutlined } from '@material-ui/icons';

const PanelDeControl: React.FC<WithStyles<typeof styles>> = (props) => {
    const { classes } = props
    const history = useHistory()
    const theme = useTheme();

    const secciones = [
        {
            tag: "Configuración de Agenda",
            icon: <VideocamOutlined />,
            acciones: [
                {
                    enabled: true,
                    tag: "Agenda Actual",
                    icon: <CalendarTodayOutlined />,
                    to: "/dashboard/administrar-consultorio/configuracion-consultas-programadas?agenda=presente"
                },
                {
                    enabled: true,
                    tag: "Agenda Futura",
                    icon: <CalendarTodayOutlined />,
                    to: "/dashboard/administrar-consultorio/configuracion-consultas-programadas?agenda=futura"
                },
            ]
        },
        {
            tag: "Agenda de turnos y Sobreturnos",
            icon: <CalendarTodayOutlined />,
            acciones: [
                {
                    enabled: true,
                    tag: "Ver agenda",
                    icon: <CalendarTodayOutlined />,
                    to: "/dashboard/administrar-consultorio/fechas-especificas"
                }
            ]
        },
    ]
    return (
        <>
            <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                <CardHeader
                    avatar={<Avatar><SettingsOutlined /></Avatar>}
                    title={<Typography variant="h6">Panel de Control</Typography>}
                />
                <Divider />
                <CardContent style={{ backgroundColor: theme.paletaColores.fondo.segundo }}>
                    <Card style={{ flexGrow: 1, marginBottom: 20 }}>
                        <CardContent>
                            <List>
                                {secciones.map(
                                    (seccion, index) => {
                                        return (
                                            <>
                                                <ListItem key={index}>
                                                    <ListItemText primary={<Typography variant="h6" style={{ color: theme.paletaColores.naranja.tercero }}>{seccion.tag}</Typography>} />

                                                    {
                                                        seccion.acciones.map(
                                                            (accion, indexAccion) => {
                                                                return (
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        disableElevation
                                                                        style={{ backgroundColor: theme.paletaColores.naranja.tercero, color: "white", margin: 5, minWidth: "190px" }}
                                                                        onClick={() => { history.push(accion.to!) }}
                                                                        startIcon={accion.icon}
                                                                    >
                                                                        {accion.tag}
                                                                    </Button>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </ListItem>
                                                {index < secciones.length - 1 && <Divider />}
                                            </>
                                        )
                                    }
                                )}
                            </List>

                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
            <div className={classes.buttonsRoot}>
                <Volver onClickHandler={() => { history.goBack() }} />
            </div>
        </>
    )
}

export default withStyles(styles)(PanelDeControl)
