import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import EstadisticasGuardia from '../../../pages/Administrador/AdministrarGuardia/EstadisticasGuardia/EstadisticasGuardia';
import EstadoGuardia from '../../../pages/Administrador/AdministrarGuardia/EstadoGuardia/EstadoGuardia';
import ListadoPacientesEspera from '../../../pages/Administrador/AdministrarGuardia/ListadoPacientesEspera/ListadoPacientesEspera';
import MenuAdministrarGuardia from '../../../pages/Administrador/AdministrarGuardia/MenuAdministrarGuardia/MenuAdministrarGuardia';


const AdministrarGuardia = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute component={MenuAdministrarGuardia} path={path} exact />
            <PrivateRoute component={EstadoGuardia} path={`${path}/estado`} />
            <PrivateRoute component={EstadisticasGuardia} path={`${path}/estadisticas`} />
            {/* <PrivateRoute component={ListadoPacientesEspera} path={`${path}/listado-consultas`} /> */}
        </Switch>
    )
}

export default AdministrarGuardia